import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc'

import { getPrefix } from '../../../core/utils/locale.js'

dayjs.extend(utc)
dayjs.extend(customParseFormat)

const get_data = null
let not_session_data = null
const version_data = 14

/**
 * This function makes a GET request to the specified URL and retries up to a specified number of times if the request fails.
 * It ensures that only one request is in progress at a time. If there is already a request in progress, it stores the callbacks
 * and executes them when the request is successful.
 *
 * @param {function} callback - The callback function to be executed when the request is successful.
 * @param {number} maxAttempts - The maximum number of attempts to make the request.
 */
let isFastbookingDataRequestInProgress = false
let fastbookingDataCallbacks = []

function get(callback, maxAttempts = 10) {
  const market_id = $('html').attr('data-market-id')
  const url = `${getPrefix()}/ajax_contents/fastbooking_data/?v=${version_data}&market_id=${market_id}`

  // If a request is already in progress, store the callback and return
  if (isFastbookingDataRequestInProgress) {
    fastbookingDataCallbacks.push(callback)
    return
  }

  let attempts = 0

  // Function to make the request
  function makeRequest() {
    // If the maximum number of attempts has been reached, stop making requests
    if (attempts >= maxAttempts) {
      isFastbookingDataRequestInProgress = false
      return
    }

    // Make the request
    fetch(url)
      .then(response => {
        // If the response is not ok, throw an error
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        // If the response is ok, return the data
        return response.json()
      })
      .then(data => {
        // If the request is successful, execute the callback and all stored callbacks
        isFastbookingDataRequestInProgress = false
        saveData(data)
        callback(data)
        fastbookingDataCallbacks.forEach(cb => cb(data))
        // Clear the array of callbacks
        fastbookingDataCallbacks = []
      })
      .catch(error => {
        // If the request fails, increment the attempts counter and retry the request after 1 second
        attempts++
        setTimeout(makeRequest, 1000)
      })
  }

  // Start the request
  const fbData = getData()

  if (fbData) return

  isFastbookingDataRequestInProgress = true
  makeRequest()
}

function notSessionData() {
  return not_session_data
}

function saveData(res) {
  const d = res
  d.creation_date = dayjs().utc().valueOf()
  d.version = version_data
  if (checkSessionStorage()) {
    sessionStorage.setItem('fastbooking_data', JSON.stringify(d))
  } else {
    not_session_data = JSON.stringify(d)
  }
}

function isHotelsDataAvailable() {
  return checkSessionStorage() ? Boolean(sessionStorage.getItem('fastbooking_data')) : Boolean(not_session_data)
}

function asyncGetData() {
  return new Promise((resolve, reject) => {
    get(hotelsData => {
      resolve(hotelsData)
    })
  })
}

export function getData() {
  let hotels_data
  if (checkSessionStorage()) {
    hotels_data = JSON.parse(sessionStorage.getItem('fastbooking_data'))
  } else {
    hotels_data = JSON.parse(notSessionData())
  }
  return hotels_data
}

function getHotelById(hotelId) {
  if (!hotelId) return

  const fbData = getData()

  if (!fbData) return

  const hotelsData = fbData.hotels[1]
  return hotelsData.find(hotel => hotel.id === hotelId)
}

function checkSessionStorage() {
  try {
    const uid = new Date()
    sessionStorage.setItem(uid, uid)
    sessionStorage.removeItem(uid)
    return true
  } catch (exception) {
    return false
  }
}

function checkDateAndVersion() {
  let data = sessionStorage.getItem('fastbooking_data')
  if (!data) return false

  data = JSON.parse(data)

  if (data.creation_date === undefined) {
    sessionStorage.removeItem('fastbooking_data')
    return false
  }

  let response = dayjs(dayjs()).diff(dayjs(data.creation_date).format(), 'hours') < 24
  if (response) response = data.version === version_data
  if (!response) sessionStorage.removeItem('fastbooking_data')

  return response
}

const HotelsData = {
  get,
  getData,
  asyncGetData,
  getHotelById,
  check: checkDateAndVersion,
  isHotelsDataAvailable,
}

window.IB.hotels_data = HotelsData

export default HotelsData
