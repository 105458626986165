import { setCookie } from '../../utils/cookie_manager'

const check_selector = 'input#mi-iberostar'
const discount_cookie_name = 'my_iberostar_discount'
const unchecked_by_user_cookie_name = 'my_iberostar_discount_unchecked_by_user'
const tooltips_selector = '.b-discounts-tooltip .my-iberostar'
const tooltips_selector_com = '.b-discounts-tooltip .iberostar-com'
let _logged_in = false

function updateLoginStatus() {
  const agencyUserName = $.cookie('agency_user_web_name')

  if (typeof agencyUserName !== 'undefined' && agencyUserName.length > 0) {
    _logged_in = true
  } else if (IB.browsing_sessions.isUserLogged() && typeof $.cookie('logged_messages') !== 'undefined') {
    // var userName = $.cookie('user_web_name');
    // if (typeof userName !== 'undefined' && userName.length > 0) {
    _logged_in = true
  }
}

function loggedIn() {
  return _logged_in
}

function updateDiscountStatus(active) {
  if (active) {
    if ($.cookie(discount_cookie_name) != 'true') {
      setCookie(discount_cookie_name, true)
    }
  } else if ($.cookie(discount_cookie_name) == 'true') {
    setCookie(discount_cookie_name, false, '-1')
  }

  if (!loggedIn()) {
    // Tenemos que cambiar esta cookie también para que se tenga en cuenta en Varnish. Lo siento por el acoplamiento y los valores fijos
    active ? setCookie('logged_messages', 'false:2') : setCookie('logged_messages', 'false:1')

    if (active) {
      if ($.cookie('logged_messages') != 'false:2') {
        setCookie('logged_messages', 'false:2')
      }
    } else if ($.cookie('logged_messages') != 'false:1') {
      setCookie('logged_messages', 'false:1')
    }
  }

  if (loggedIn() && !active) {
    setCookie(unchecked_by_user_cookie_name, true)
  }
}

function hideTooltips() {
  $(tooltips_selector).addClass('hidden')
}

function isDiscountCookiePresent() {
  const my_iberostar_discount = $.cookie(discount_cookie_name)
  return typeof my_iberostar_discount !== 'undefined' && my_iberostar_discount.length > 0
}

function isUncheckedByUserCookiePresent() {
  const unchecked_by_user = $.cookie(unchecked_by_user_cookie_name)
  return typeof unchecked_by_user !== 'undefined' && unchecked_by_user.length > 0
}

function isDiscountActive() {
  return isDiscountCookiePresent() || (loggedIn() && !isUncheckedByUserCookiePresent())
}

function init($check) {
  updateLoginStatus()
  if (isDiscountActive()) {
    $check.prop('checked', true)
    $check.closest('.discount').addClass('active')
    if (loggedIn() && !isUncheckedByUserCookiePresent()) {
      $check.closest('.discount').addClass('checked')
      IB.fastbooking.checkAnimation()
      $check.attr('disabled', 'disabled')
      if ($.cookie(discount_cookie_name) != 'true') {
        setCookie(discount_cookie_name, true)
      }
    }
  } else {
    hideTooltips()
  }

  $check.on('change', function (e) {
    const c = $(this).is(':checked')
    updateDiscountStatus(c)
    IB.fastbooking.checkAnimation()
  })
}

const FastbookingDiscountMyIberostar = {
  init,
  isDiscountActive,
  hideTooltips,
}

$(function () {
  const $el = $(check_selector)
  if ($el.length) {
    init($el)
  } else {
    hideTooltips()
    $(tooltips_selector_com).addClass('hidden')
  }
  $(document).on('change', check_selector, function (e) {
    const $this = $(this)
    const this_checked = $this.is(':checked')

    if (this_checked) {
      $this.closest('.discount').addClass('active')
    } else {
      $this.closest('.discount').removeClass('active')
    }
    IB.fastbooking.updateDiscountCounter()
  })
})

if (typeof IB === 'undefined') {
  window.IB = {
    fastbooking: {},
  }
} else if (IB.fastbooking === undefined) {
  window.IB.fastbooking = {}
}

window.IB.fastbooking.discount_myiberostar = FastbookingDiscountMyIberostar

export default FastbookingDiscountMyIberostar
