import Fuse from 'fuse.js'
import { createApp } from 'vue'
import WordHighlighter from 'vue-word-highlighter'

import { sendUtagEvent } from '../../core/analytics/utag_events'
import { currentDevice } from '../../core/utils/currentDevice'
import { getPrefix } from '../../core/utils/locale'
import { Airport } from '../../domain/Airport.js'
import loaderSpinner from '../../shared/loader_spinner/loader_spinner'
import { fromObjectToParams } from '../../utils/from_object_to_params'
import { getLazyData } from '../../utils/get_lazy_data'
import { removeDiacritics } from '../../utils/remove_diacritics'
import { getCleanedString } from '../../utils/utilities'

// Vue parent component
let componentOptions
const BASE_ID_SELECTOR = '#fastbooking-departure-airport'

// Configuration
const TIME_UNTIL_VALIDATION = 2000 // time for user to finish typing the code
const MINIMUM_SEARCH_LENGTH = 3
const PROVIDERS_WITH_PRELOAD_ENABLED = ['HLX']

// Fastbooking
const $fastbooking = currentDevice.isMobile ? $('.mobile-fastbooking-cnt') : $('.fastbooking')

// Events
const FASTBOOKING_DEPARTURE_AIRPORT_SELECT_SET_INPUT_TEXT = 'fastbooking-departure-airport:set-input-text'
const FASTBOOKING_DEPARTURE_AIRPORT_SELECT_RESET_INPUT_TEXT = 'fastbooking-departure-airport:reset-input-text'
const FASTBOOKING_DEPARTURE_AIRPORT_SELECT_RESET_SEARCH = 'fastbooking-departure-airport:reset-search'

export const initializeVueFastbookingDepartureAirport = () => {
  if (!componentOptions) {
    componentOptions = {
      components: {
        WordHighlighter,
      },
      data() {
        return {
          search: '',
          default_text: '',
          input_text: '',
          airport_data: [],
          isSearching: false,
          typeTiming: null, // setTimeout variable,
          provider: '',
        }
      },
      computed: {
        filteredAirports() {
          if (this.search === '') return this.airport_data

          return this.airport_data.filter(airport => {
            return this.findSearchInTitleContent(airport.title, this.search)
          })
        },
      },
      mounted() {
        this.default_text = document.querySelector('.js-destinia-select').dataset.placeholder
        this.input_text = this.default_text
        document.querySelector('.js-destinia-select').value = ''

        const provider = document.querySelector('.js-flight-and-hotels-tab').dataset.provider
        document.querySelector('.js-departure-airport .js-provider').value = provider
        this.provider = provider

        document.querySelector('.js-input-text').classList.remove('hidden')
        document.querySelector('.js-input-user-text-destinia').classList.add('hide')
        this.changePlaceholder()

        if (currentDevice.isMobile) {
          // Avoids logic from line 2250 when this input is focused and the page scrolls natively
          document.querySelector('.chosen-search-destinia input').addEventListener('focus', function () {
            sessionStorage.setItem('userHasScrolled', 'false')
          })
        }

        this.preloadAirports()
        this.listenForInputText()
        this.listenForResetInputText()
        this.listenForResetSearch()
        this.listenForFastbookingTabs()
        this.loadProviderType()
      },
      methods: {
        listenForInputText() {
          document.addEventListener(FASTBOOKING_DEPARTURE_AIRPORT_SELECT_SET_INPUT_TEXT, e => {
            this.input_text = e.detail
          })
        },
        listenForFastbookingTabs() {
          document.querySelectorAll('.js-fastbooking-tabs li.content-tab').forEach(tab => {
            tab.addEventListener('click', () => {
              this.loadProviderType()
            })
          })
        },
        loadProviderType() {
          const provider_active = document.querySelector('.js-flight-and-hotels-tab').classList.contains('active')
          const provider = provider_active ? document.querySelector('.js-flight-and-hotels-tab').dataset.provider : ''
          document.querySelector('.js-departure-airport .js-provider').value = provider
          this.provider = provider
        },

        listenForResetInputText() {
          document.addEventListener(FASTBOOKING_DEPARTURE_AIRPORT_SELECT_RESET_INPUT_TEXT, e => {
            this.input_text = this.default_text
          })
        },

        listenForResetSearch() {
          document.addEventListener(FASTBOOKING_DEPARTURE_AIRPORT_SELECT_RESET_SEARCH, e => {
            this.search = ''
          })
        },
        sendUtagEvent(params) {
          const data = params.data
          sendUtagEvent({
            data: {
              ...data,
              event_lbl: getCleanedString(data.event_lbl),
            },
          })
          // sendUtagEvent({ ...data })
        },
        /**
         * Generally speaking, fuzzy searching (more formally known as approximate
         * string matching) is the technique of finding strings that are approximately
         * equal to a given pattern (rather than exactly).
         *
         * @param {string} title
         * @param {string} word
         * @returns {boolean}
         */
        fuzzySearch(title, word) {
          if (word.length < MINIMUM_SEARCH_LENGTH) return true

          const cleanedWord = removeDiacritics(word.toLowerCase())
          const cleanedTitle = removeDiacritics(title.toLowerCase())

          const opt = {
            threshold: 0.2,
            tokenize: true,
            tokenSeparator: /\s+/g,
            matchAllTokens: true,
            location: 0,
            distance: 200,
            maxPatternLength: 32,
            minMatchCharLength: 1,
          }

          const fuse = new Fuse([cleanedTitle], opt)
          const result = fuse.search(cleanedWord)
          return !!result.length
        },

        findSearchInTitleContent(title, search) {
          let result = true // assumes all results are true

          const cleanedWord = removeDiacritics(title.toLowerCase())
          const cleanedSearch = removeDiacritics(search.toLowerCase())

          const words = cleanedSearch.split(' ')
          words.forEach(word => {
            if (!this.fuzzySearch(cleanedWord, word)) {
              result = false // Removes item from selector if it doesn't have this word
            }
          })

          return result
        },
        preloadAirports() {
          if (PROVIDERS_WITH_PRELOAD_ENABLED.includes(this.provider)) {
            this.getAirportData()
          }
        },
        getAirportData() {
          const showError = document.querySelector('.js-show-error')
          const showHint = document.querySelector('.js-show-hint')
          this.loadProviderType()
          if (!currentDevice.isMobile) {
            document.querySelector('.js-destinia-select').style.display = 'none'
          }

          if (this.search.length < MINIMUM_SEARCH_LENGTH) {
            showError.classList.add('hidden')
          }

          const params = fromObjectToParams({
            '?q': this.search,
            provider: this.provider,
          })

          const url = `${getPrefix()}/ajax_contents/search_airport/${params}`

          getLazyData(
            url,
            (res, responseOk) => {
              this.isSearching = false
              this.airport_data = Airport.mapAirportsBasedOnProvider(res, this.provider)

              showHint.classList.add('hidden')
              if (responseOk) {
                if (!res.length) {
                  showError.classList.remove('hidden')
                } else {
                  showError.classList.add('hidden')
                }
                loaderSpinner.hide({
                  container: document.querySelector('.chosen-results-destinia'),
                })
              } else {
                showError.classList.remove('hidden')
                this.isSearching = false
                loaderSpinner.hide({
                  container: document.querySelector('.chosen-results-destinia'),
                })
              }
            },
            'json'
          )
        },
        changeDateOutput(dateString) {
          if (dateString === undefined) return ''
          const datearray = dateString.split('-')
          return `${datearray[2]}/${datearray[1]}/${datearray[0]}`
        },
        highlightHoverElement(event) {
          const currentHighlightElement = document.querySelector('.active-result.highlighted')
          if (currentHighlightElement !== null) currentHighlightElement.classList.remove('highlighted')
          event.target.classList.toggle('highlighted')
        },
        /* eslint-disable */
        handleKeyDown(evt) {
          let stroke;
          let _ref;
          stroke = (_ref = evt.which) != null ? _ref : evt.keyCode;
          if (evt.shiftKey && stroke === 9) {
            // Tab + Shift (ir hacia atrás)
            evt.preventDefault();
            tabMovement('previousElementSibling', evt.target);
            return;
          }
          switch (stroke) {
            case 9:
              // Tab
              evt.preventDefault();
              tabMovement('nextElementSibling', evt.target);
              break;
            case 13:
              // Enter
              evt.preventDefault();
              if (currentDevice.isMobile) return false;
              var currentHighlightElement = document.querySelectorAll('.active-result.highlighted-keyboard')[
                document.querySelectorAll('.active-result.highlighted-keyboard').length - 1
              ];
              if (currentHighlightElement !== null) $(currentHighlightElement).trigger('mousedown');
              break;
            case 38:
              // Up arrow
              evt.preventDefault();
              var findMe = document.querySelectorAll('.active-result.highlighted-keyboard')[0];
              if (findMe !== null) {
                if (findMe.previousElementSibling !== null && findMe.previousElementSibling !== undefined && findMe.previousElementSibling.classList.contains('active-result')) {
                  scrollContainerWhenKeyboardInteraction(findMe.previousElementSibling);
                  setTimeout(function () {
                    findMe.classList.remove('highlighted-keyboard');
                    findMe.previousElementSibling.classList.add('highlighted-keyboard');
                  }, 50);
                }
              }
              break;
            case 40:
              // Down arrow
              evt.preventDefault();
              var findMe = document.querySelectorAll('.active-result.highlighted-keyboard')[document.querySelectorAll('.active-result.highlighted-keyboard').length - 1];
              if (findMe !== null && findMe !== undefined) {
                if (findMe.nextElementSibling !== null && findMe.nextElementSibling.classList.contains('active-result')) {
                  scrollContainerWhenKeyboardInteraction(findMe.nextElementSibling);
                  setTimeout(function () {
                    findMe.classList.remove('highlighted-keyboard');
                    findMe.nextElementSibling.classList.add('highlighted-keyboard');
                  }, 50);
                } else {
                  findMe = document.querySelector('.first-column .active-result');
                  findMe.classList.add('highlighted-keyboard');
                  document.querySelector('.first-column').focus();
                }
              }
              break;
          }
        },
        /* eslint-enable */
        handleSearchInput(evt) {
          this.search = evt.target.value

          const searchLength = this.search.length
          const loaderTextTitle = document.querySelector('.js-loader-text-title').innerHTML
          const loaderTextSubtitle = document.querySelector('.js-loader-text-subtitle').innerHTML
          const showHint = document.querySelector('.js-show-hint').innerHTML
          const showHintContainer = document.querySelector('.js-show-hint')

          if (!this.isSearching && searchLength < MINIMUM_SEARCH_LENGTH) {
            showHintContainer.classList.add('hidden')
            loaderSpinner.show({
              container: document.querySelector('.js-results-destinia'),
              title: showHint,
              spinnerSize: 150,
              spinnerColor: 'grey',
            })
            this.isSearching = true
          } else if (!this.isSearching && searchLength >= MINIMUM_SEARCH_LENGTH) {
            showHintContainer.classList.add('hidden')

            loaderSpinner.show({
              container: document.querySelector('.js-results-destinia'),
              title: loaderTextTitle,
              subtitle: loaderTextSubtitle,
              spinnerSize: 150,
              spinnerColor: 'grey',
            })
            this.isSearching = true
          }

          // call the function when the user stop typing
          if (searchLength >= 3) {
            clearTimeout(this.typeTiming)
            this.typeTiming = setTimeout(() => {
              this.getAirportData()
            }, TIME_UNTIL_VALIDATION)
          }
        },
        changePlaceholder() {
          const inputTextByUser = document.querySelector('.js-input-user-text-destinia')
          const showHint = document.querySelector('.js-show-hint')
          // Change of the placeholder according to the initialisation parameter of the searches in FastBooking ->MINIMUM_SEARCH_LENGTH
          const inputTextByUserPlaceholder = inputTextByUser.placeholder.replace(
            '%{number_letter}',
            MINIMUM_SEARCH_LENGTH
          )
          inputTextByUser.placeholder = inputTextByUserPlaceholder
          showHint.innerHTML = inputTextByUserPlaceholder
        },
      },
    }

    const app = createApp(componentOptions)
    app.mount(BASE_ID_SELECTOR)
  }
}

const scrollContainerWhenKeyboardInteraction = element => {
  var maxHeight = parseInt($('.js-results-destinia').first().css('maxHeight'), 10)
  var $columnParent = $(element).closest('div[class*="-column"]')
  var visible_top = $columnParent.scrollTop()
  var visible_bottom = maxHeight + visible_top
  var high_top = $(element).position().top + $columnParent.scrollTop()
  var high_bottom = high_top + $(element).outerHeight()
  if (high_bottom >= visible_bottom) {
    return $columnParent.scrollTop(high_bottom - maxHeight > 0 ? high_bottom - maxHeight + 50 : 50)
  }
  if (high_top < visible_top) {
    return $columnParent.scrollTop(high_top - 90)
  }
}

function IsOwnChosenClicked(evt, selfObj) {
  if (selfObj !== undefined) {
    return (
      selfObj.contains(evt.target) ||
      evt.target === selfObj ||
      (selfObj.childNodes !== undefined && $.inArray(evt.target, selfObj.childNodes) >= 0)
    )
  }
}

/* eslint-disable */

$(document)
  .on('click', 'body', function (evt) {
    const $destinia_selectors = $fastbooking.find('.js-destinia-select');

    // Ignore this event if keep chosen container opened is set
    if ($destinia_selectors.data('keep-opened-once')) {
      $destinia_selectors.removeData('keep-opened-once');
      return;
    }

    // Close fastbooking chosen container when click in other page element
    let $destinia_element = currentDevice.isMobile ? $('#mobile-origin')[0] : $('#desktop-origin')[0];

    if (!IsOwnChosenClicked(evt, $destinia_element) && evt.target.className !== 'fake-select') {
      if ($('.js-container-destinia').hasClass('chosen-with-drop')) closePlaceToGoDestinia();
    }
  })
  .on('focusout', '.mobile-fastbooking-form .chosen-search-destinia input', function (evt) {
    evt.preventDefault();
    closePlaceToGoDestinia();
    setTimeout(IB.fastbooking.switchClearButtonDestinia(), 500);
  })
  .on(
    'mousedown',
    '.input.js-place-origin .group-option-destinia, .js-label-origin-mobile .group-option-destinia, #airport-mobile-selector .active-result ,#vo_booking_to_destinia .active-result',
    function (e) {
      e.stopPropagation();
      let $fastbooking = currentDevice.isMobile ? $('#fastbooking-mobile') : $('.fastbooking');
      let name = this.dataset.name;
      let airport_input = currentDevice.isMobile ? document.querySelector('.js-place-origin-mobile .js-airport') : document.querySelector('.js-place-origin .js-airport');
      airport_input.value = this.dataset.iata;
      let airport_name_input = currentDevice.isMobile ? document.querySelector('.js-place-origin-mobile .js-airport-name') : document.querySelector('.js-place-origin .js-airport-name');
      airport_name_input.value = this.dataset.name;

      let $chosen_selectors = $fastbooking.find('.js-destinia-select');
      setFastbookingDepartureAirport($chosen_selectors, name);
      $(this).addClass('result-selected');

      closePlaceToGoDestinia();
    }
  );

/* eslint-enable */
export const openFastbookingDepartureAirport = () => {
  const chosenDestiniaContainer = document.querySelector('.js-container-destinia')
  const chosenDrop = document.querySelector('.chosen-drop-destinia')
  const destiniaSelect = document.querySelector('.js-destinia-select')
  IB.fastbooking.showingDropdownDestinia()
  chosenDestiniaContainer.classList.add('chosen-with-drop')
  chosenDestiniaContainer.querySelector('.js-results-destinia').classList.remove('is-hidden')

  if (currentDevice.isMobile) {
    chosenDrop.style.opacity = '1'
    destiniaSelect.classList.remove('is-hidden')
    $('.js-container-destinia')
      .find('.js-destinia-select')
      .first()
      .val($('.js-container-destinia').find('.js-destinia-select').first().val())
    $('.js-container-destinia').find('.js-destinia-select').first().trigger('focus')
  } else {
    $('.js-container-destinia').find('input').first().val($('.js-container-destinia').find('input').first().val())
    $('.js-container-destinia').find('input').first().trigger('focus')
  }
}

export const setFastbookingDepartureAirport = ($selector, value) => {
  IB.fastbooking.changesBeforeHideChosenDestinia()
  const current_selection = document.querySelector('.result-selected')
  const inputText = document.querySelector('.js-input-text')
  const destiniaSelect = document.querySelector('.js-destinia-select')
  if (current_selection !== null) current_selection.classList.remove('result-selected')

  $selector.val(value)
  document.dispatchEvent(new CustomEvent(FASTBOOKING_DEPARTURE_AIRPORT_SELECT_SET_INPUT_TEXT, { detail: value }))

  if (currentDevice.isMobile) {
    inputText.style.opacity = '1'
    destiniaSelect.classList.add('is-hidden')
  }

  setTimeout(IB.fastbooking.switchClearButtonDestinia(), 500)
}

export const closePlaceToGoDestinia = () => {
  const chosenDestiniaContainer = document.querySelector('.js-container-destinia')

  const chosenDrop = document.querySelector('.chosen-drop-destinia')
  chosenDrop.style.opacity = '0'
  chosenDestiniaContainer.querySelector('.first-column').scrollTop = 0
  chosenDestiniaContainer.classList.remove('chosen-with-drop')
  chosenDestiniaContainer.querySelector('.js-results-destinia').classList.add('is-hidden')

  if (document.querySelector('#book-now.mouse-down') !== null)
    document.querySelector('#book-now').classList.remove('mouse-down')
  document.querySelector('.js-input-user-text-destinia').value = ''
  document.dispatchEvent(new Event(FASTBOOKING_DEPARTURE_AIRPORT_SELECT_RESET_SEARCH))
  const highlightedKeyboardElement = document.querySelector('.active-result.highlighted-keyboard')
  if (highlightedKeyboardElement !== null && highlightedKeyboardElement !== undefined)
    highlightedKeyboardElement.classList.remove('highlighted-keyboard')
}

export const resetFastbookingDepartureAirport = () => {
  document.dispatchEvent(new Event(FASTBOOKING_DEPARTURE_AIRPORT_SELECT_RESET_INPUT_TEXT))
  document.querySelector('.js-airport').value = ''
  document.querySelector('.js-destinia-select').value = ''
  document.querySelector('.chosen-single-destinia').classList.add('chosen-default')
  document.querySelector('.js-input-user-text-destinia').value = ''
  document.dispatchEvent(new Event(FASTBOOKING_DEPARTURE_AIRPORT_SELECT_RESET_SEARCH))
}
