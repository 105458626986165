/* eslint-disable */
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import HotelsData from '../../core/iberapi/hotels_data/hotels_data';
import { initializeUtagEvents, initializeFastbookingTabUtagEvent } from './fastbooking.analytics';

dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);

import _debounce from 'lodash/debounce';
import { renameEventCategory } from '../../core/analytics/dataLayerFunctions';
import { FB_DROPDOWN_CLOSE_EVENT } from '../../core/iberapi/ready/ready';
import { currentDevice } from '../../core/utils/currentDevice';
import CustomInputNumber from '../../elements/custom_input_number/custom_input_number';
import Tooltip from '../../elements/tooltip/tooltip';
import { setCookie } from '../../utils/cookie_manager';
import flightPlusHotelDetection from '../../utils/flight_plus_hotel_detection';
import inViewport from '../../utils/inViewport';
import isFocused from '../../utils/isFocused';
import { setOnCachedPage } from '../../utils/prevoius_page_cache';
import { initDiscountTooltips } from '../tooltip_for_discounts/tooltip_for_discounts';
import './fastbooking.booking_dates';
import ChildrenControl from './fastbooking.childrenControl';
import FastbookingDiscountMyIberostar from './fastbooking.discount_myiberostar';
import FastbookingDiscountMyIB from './fastbooking.discount_my_IB';
import FastbookingDiscountResident from './fastbooking.discount_resident';
import FastbookingMobileBar from './fastbooking.mobile_bar';
import FastbookingPackages from './fastbooking.packages';
import FastbookingPromocode from './fastbooking.promocode';
import FastbookingDateRangePicker from './fastbooking.rangedatepicker';
import FastbookingSelect from './fastbooking.select';
import FastbookingState from './fastbooking.state';
import FastbookingSticky from './fastbooking.sticky';
import FastbookingVoiceSearch from './fastbooking.voice_search';
import { showWarningModal, initWarningModal } from './fastbooking.flight_and_hotel_modal';

import { initializeVuePlaceToGoBox, openPlaceToGoBox, resetPlaceToGoBox, setPlaceToGo } from './place_to_go_box';
import { initializeVueFastbookingDepartureAirport, openFastbookingDepartureAirport, resetFastbookingDepartureAirport, setFastbookingDepartureAirport } from './fastbooking.departure_airport';
import { FASTBOOKING_FLIGHT_TAB_CREATED_EVENT } from './constants';

import { closeLoader, openLoader } from '../../elements/wait_loading/wait_loading';

// PY05355, Header REB - Ficha de hotel 2023, ?test_reb_header=true
import MainHeader from '../../layout/header/reb/header';
import { initTestRebHeader } from '../../utils/test_reb_header';

let $doc = $(document);
let $fastbooking;
let $room_template;
let $place_to_go;
let fastbooking_data;
let TYPE_HOTEL = 'h';
let TYPE_DESTINATION = 'd';
let TYPE_SPECIALITY = 's';
let option_active_result;
let MAX_ROOMS_EMEA = 8; // Para Iberostar PRO
let MAX_ROOMS_AME = 9; // Para Iberostar PRO
let $voice_search;
let $voice_search_destinia;
let $clear_btn;
let $clear_btn_destinia;
let default_max_rooms;
let select_initialized = false;
let isMobile = IB.currentDevice === 'mobile';
let occupancyChanged = false;
let fastbooking_top = $('.fastbooking').length ? $('.fastbooking').offset().top + $('.fastbooking').outerHeight() : 0;
let $list_header_no_sticky = $('.hotel-header-container, .js-fb-ignore-sticky'); // Cabeceras con las que no debe aplicarse fastbooking sticky
let modeWasChecked = '';
let bodyScrollAnimationIsFinished = true;
let $initializedSelect = null;
let discountCounter = 0;
const isFhiPage = document.querySelector('html').dataset.fhiTest !== undefined;
const $staticHotel = $('.js-selected-fhi-hotel-name .js-fhi-hotel-code');
let hotelTabIsActive = true
let airportTabIsActive = false

// Automatic offers
const AUTO_OFFER_LIST_PRESENT = document.querySelector('.automatic-offer-list');
let auto_offer_search_ready = false;

if (!isMobile && ($('.fastbooking').length || IB.currentPageType.isYouDoYou())) {
  window.addEventListener('scroll', () => checkFastbookingSticky());
}

if (isMobile) {
  showDatesAlert();
}

/*
  On Safari browsers, previous pages are cached.
  setOnCachedPage restore the page by default .
*/

setOnCachedPage(function () {
  if (document.querySelector('#wait-loading').classList.contains('is-open')) {
    // Removes loading state
    closeLoader()
  }

  // Loads last search
  let last_searches = JSON.parse(localStorage.getItem('last_searches'));
  let last_search = last_searches.pop();

  setTimeout(() => {
    loadLastSearch(last_search);
    // Removes all focused states
    focusoutChosen();
    $fastbooking.removeClass('focused');
    if (document.querySelector('[data-focus-bg]')) document.querySelector('[data-focus-bg]').remove();
  }, 1000); // Runs after one second to allow proper autocomplete of fastbooking
});

function checkFastbookingSticky() {
  // PY05355, Header REB - Ficha de hotel 2023, ?test_reb_header=true
  $fastbooking = (initTestRebHeader && $('.js-reb-header')) || $('.fastbooking');

  if (!ScrollLock.isLock()) {
    let width_scroll = $(window).scrollTop();
    if (width_scroll > fastbooking_top && !$list_header_no_sticky.length) {
      if (!$fastbooking.hasClass('sticky')) {
        $fastbooking.addClass('sticky');
        FastbookingSticky.toggleFastbookingPlaceholder(true);

        // PY05355, Header REB - Ficha de hotel 2023, ?test_reb_header=true
        initTestRebHeader && MainHeader.showContent();
      } else {
        FastbookingSticky.fixCalendarPosition();
      }
    } else {
      if ($fastbooking.hasClass('sticky')) {
        // If fasbooking has the sticky-force class, don't close it until another action is done
        if (!$fastbooking.hasClass('sticky-force')) {
          FastbookingSticky.close();
          FastbookingSticky.removeFixedCalendar();

          if (!IB.currentPageType.isHotel()) {
            $fastbooking.removeClass('sticky');
          }
          if(!initTestRebHeader){
            $fastbooking.removeClass('sticky');
          }

          FastbookingSticky.toggleFastbookingPlaceholder(false);

          // PY05355, Header REB - Ficha de hotel 2023, ?test_reb_header=true
          initTestRebHeader && MainHeader.hideContent();
        }
      }
    }
  }
}

function stickCalendar(sticky) {
  let calendar = document.querySelector('.fb-datepicker');
  let fastbooking = document.querySelector('.fastbooking.home');

  // For ultrawide screens
  if (!calendar) return;

  if (!calendar_setted && !sticky) {
    calendar_style.top = calendar.style.top;
    calendar_style.left = calendar.style.left;
    Object.freeze(calendar_style);
    calendar_setted = true;
  }

  if (sticky) {
    calendar.style.cssText = 'position:fixed; display:block; width:100%; top: ' + fastbooking.clientHeight + 'px; left: ' + calendar_style.left;
  } else {
    calendar.style.cssText = 'position:absolute; display:block; width:100%; top: ' + calendar_style.top + '; left: ' + calendar_style.left;
  }
}

function init($el) {
  $fastbooking = $el;
  if ($fastbooking.length === 0) return;
  $fastbooking.addClass('initialized');
  $room_template = $fastbooking.find('.rooms-template');
  default_max_rooms = $fastbooking.attr('data-max-rooms');
  $place_to_go = $fastbooking.find('.chosen-select');
  if (IB.is_in_mice) return;
  // El state debe inicializarse antes que el VuePlaceToGoBox
  FastbookingState.init();

  initializeVuePlaceToGoBox();
  initWarningModal()
  ChildrenControl.initVars($fastbooking);
  ChildrenControl.initSelectListeners();

  FastbookingPromocode.init();

  let $check_in = $fastbooking.find('.check-in');
  let $check_out = $fastbooking.find('.check-out');
  let $chosen_selectors = $fastbooking.find('.chosen-select');

  /*
   * 1. Seleccionar el select de aeropuertos (destinia)
   */
  let $origin_selectors = $fastbooking.find('.js-destinia-select');
  let lang_is_ru = document.documentElement.getAttribute('data-js-lang') === 'ru';
  let date_format = $('html').attr('data-date-format').toLowerCase();
  let lang = document.documentElement.getAttribute('data-js-lang');
  let format_for_lang_date;

  if (IB.currentDevice === 'mobile') {
    format_for_lang_date = lang_date_format(date_format, lang);
    $check_in.closest('.dates-container').find('.date-span').text(format_for_lang_date).attr('data-placeholder', format_for_lang_date);
  }

  showDatesAlert();

  if (!$check_in.val() && !$check_out.val()) {
    if (!lang_is_ru) {
      hideShowButton('search');
    }
  } else {
    hideShowButton('book-now');
    $fastbooking.find('.input.place-to-go').addClass('book-now-show');
    let selected_day = $('.day.first-date-selected').data('id');
    $fastbooking.find('.dates').data('first-day', selected_day);
  }

  $doc.on('click', '.fake-select', function (event) {
    event.preventDefault();
    event.stopPropagation();
    let $this = $(this);
    if (IB.currentDevice !== 'mobile') {
      FastbookingSelect.init($chosen_selectors, function () {
        initSelect($chosen_selectors, $this);
        $fastbooking.find('.fastbooking-adults').html($fastbooking.find('.fastbooking-adults-text'));
        openPlaceToGoBox(event);
      });
    } else {
      initSelect($chosen_selectors, $this);
      openPlaceToGoBox(event);
    }
  });

  /*
   * 2. On fake select click (destinia) init select and open airport dropdown (openPlaceToDestinia)
   */
  $doc.on('click', '.fake-select-destinia', function (event) {
    event.preventDefault();
    event.stopPropagation();

    let $this = $(this);
    initSelect($origin_selectors, $this);
    openFastbookingDepartureAirport();
  });

  if (IB.currentDevice === 'mobile') {
    CustomInputNumber.init($('.js-input-number'));
  }
  window.Parsley.on('field:error', function () {
    let $el = this.$element;
    if ($el.hasClass('age')) {
      if (IB.currentDevice === 'mobile') $('.people-mobile-resume').trigger('click');
      $el.closest('.ages').find('.error').show();
    }
  });
  window.Parsley.on('field:success', function () {
    let $el = this.$element;
    if ($el.hasClass('age')) {
      let $box = $el.closest('.ages');
      if (!$box.find('.parsley-error').length) $box.find('.error').hide();
    }
  });

  initDiscountTooltips();

  // Check rooms and in everthing is ok, proceed
  if (IB.currentDevice != 'mobile') {
    const fb_people_btn = document.querySelector('.fastbooking-people-button');
    if (fb_people_btn) {
      fb_people_btn.addEventListener('click', function () {
        setRoomEditingState();
        closePopover($('.fastbooking.home #people-popover'), false, () => {
          ScrollLock.off();
        });
      });
    }
  }

  // Si estoy en modo accesible, muestro el texto "campo obligatorio" del destino
  let accessibility_mode = IB.a11y.isAdaActivated();
  if (accessibility_mode === true && !IB.is_in_microsite) {
    $('.obligatory-field-fastbooking').removeClass('hidden');
    $('.bottom-side').removeClass('hidden');
  }
  initializeUtagEvents()

}

/*
 * IberostarPRO: Calculate number of rooms can selected
 * No return
 * example: calAgencyMaxRoom(getSelectedPlace('h11'), 'h')
 */
function calAgencyMaxRoom(place, type) {
  let microsite = $.cookie('logged_in_microsites');

  if (microsite !== 'iberostar_pro') return;

  let is_hotels = type === TYPE_HOTEL;
  let is_destinations = type === TYPE_DESTINATION;
  let is_speciality = type === TYPE_SPECIALITY;
  let $selector_rooms = IB.currentDevice === 'mobile' ? $fastbooking.find('.rooms-select') : $fastbooking.find('.rooms-selector');

  switch (true) {
    case is_hotels || is_destinations:
      // Modificamos el número máximo de habitaciones a seleccionar para hoteles AME (9) y EMEA (8)
      if (place.data.headquarter_slug.toLowerCase() === 'america') {
        IB.currentDevice === 'mobile' ? adjustMaxInput(MAX_ROOMS_AME, $selector_rooms) : adjustOptionsSelector(MAX_ROOMS_AME, $selector_rooms);
      } else if (place.data.headquarter_slug.toLowerCase() === 'emea') {
        IB.currentDevice === 'mobile' ? adjustMaxInput(MAX_ROOMS_EMEA, $selector_rooms) : adjustOptionsSelector(MAX_ROOMS_EMEA, $selector_rooms);
      }
      break;
    case is_speciality:
      // Si es especialidad, dejamos el valor por defecto asignado en el init del data-max-rooms
      adjustOptionsSelector(default_max_rooms, $selector_rooms);
      break;
  }
}

/*
 * Update number of options into selector
 * No return
 * example: adjustOptionsSelector(10, $("select"))
 */
function adjustOptionsSelector(new_options, $selector) {
  let actual_options = $selector.find('option').length;
  if (actual_options < new_options) {
    for (let i = actual_options + 1; i <= new_options; i++) {
      $selector.append(
        $('<option>', {
          value: i,
          text: i,
        })
      );
    }
  } else {
    // Si el valor seleccionado es mayor al número de opciones nuevo, lo cambiamos al nuevo máximo
    if (+$selector.find('option[selected]').text() > new_options) {
      $selector.val(new_options).change();
    }
    for (let i = actual_options; i > new_options; i--) {
      $selector.find("option[value='" + i + "']").remove();
    }
  }
  $fastbooking.attr('data-max-rooms', new_options);
}

/*
 * Update number max into input
 * No return
 * example: adjustOptionsSelector(10, $("input"))
 */
function adjustMaxInput(new_options, $selector) {
  if ($selector.val() > new_options) $selector.val(new_options);
  $selector.attr('max', new_options);
  $fastbooking.attr('data-max-rooms', new_options);
  CustomInputNumber.init($selector, 'refresh');
}

function lang_date_format(date_format, lang) {
  switch (lang) {
    case 'de':
      date_format = date_format.replace('dd', 'TT');
      date_format = date_format.replace('mm', 'MM');
      date_format = date_format.replace('yyyy', 'JJJJ');
      return date_format;
    case 'fr':
      date_format = date_format.replace('dd', 'JJ');
      date_format = date_format.replace('mm', 'MM');
      date_format = date_format.replace('yyyy', 'AAAA');
      return date_format;
    case 'it':
      date_format = date_format.replace('dd', 'GG');
      date_format = date_format.replace('mm', 'MM');
      date_format = date_format.replace('yyyy', 'AAAA');
      return date_format;
    case 'pt':
      date_format = date_format.replace('dd', 'DD');
      date_format = date_format.replace('mm', 'MM');
      date_format = date_format.replace('yyyy', 'AAAA');
      return date_format;
    case 'es':
      date_format = date_format.replace('dd', 'DD');
      date_format = date_format.replace('mm', 'MM');
      date_format = date_format.replace('yyyy', 'AAAA');
      return date_format;
    case 'en':
      date_format = date_format.replace('dd', 'DD');
      date_format = date_format.replace('mm', 'MM');
      date_format = date_format.replace('yyyy', 'YYYY');
      return date_format;
    case 'ru':
      date_format = date_format.replace('dd', 'ДД');
      date_format = date_format.replace('mm', 'ММ');
      date_format = date_format.replace('yyyy', 'ГГГГ');
      return date_format;
    default:
      date_format;
  }
}

function initSelect($select, $fake_select) {
  $initializedSelect = $select;
  if (!$fastbooking) {
    $fastbooking = isMobile ? $('.mobile-fastbooking-cnt') : $('.fastbooking');
  }

  $fake_select.remove();
  select_initialized = true;

  $select.on('change', function () {
    let $this = $(this);
    let $selected = $this.find('option:selected');
    let $remove_dates = $('.remove-dates');

    if (['vo_booking_place_to_go', 'vo_booking_to_destinia', 'mobile-hotels-selector', 'airport-mobile-selector'].includes($this.attr('id'))) {
      $this.parents('form').attr('data-user-input', 'NAN');
    }

    if ($fastbooking === undefined) return false;



    let $selectedOptionInChosenResults = $fastbooking.find('.chosen-results .result-selected');

    if ($selected.hasClass('last-search-opt') && !$selected.attr('data-check-in')) {
      FastbookingDateRangePicker.removeDates($fastbooking);
    }

    // shows remove class opt when loading last search with its dates (if previous search was only a destination
    // without dates, it keeps it hidden)
    if ($selected.hasClass('last-search-opt') && $selected.attr('data-check-in')) {
      $('.opt-dates').addClass('hidden');
      $remove_dates.removeClass('hidden');
    }

    if ($selected.hasClass('last-search-opt')) {
      ChildrenControl.setRooms($selected);
    }
    // If children age was previously selected, check if hotel is only adults, and in that case information modal will be shown
    let $inputAge = $fastbooking.find('.input.age');
    if ($inputAge.length) {
      ChildrenControl.checkOnlyAdults($selected);
    }
    ChildrenControl.setMaxAdultsAndChildren($selected);

    ChildrenControl.setAllowChildren($fastbooking);
    ChildrenControl.setMaxChildrenAge($fastbooking);

    if (!$inputAge.length && !occupancyChanged) {
      setTimeout(function () {
        FastbookingDateRangePicker.checkAutoOpen($fastbooking, $selected);
      }, 20);
    }

    // If package loaded manage restrictions and show warnings
    if (FastbookingPackages.hasPackageLoaded()) {
      let $check_in = $fastbooking.find('.check-in');
      let $check_out = $fastbooking.find('.check-out');
      let startDate = $check_in.val().length ? dayjs(dayjs($check_in.val(), 'DD/MM/YYYY').format('YYYY-MM-DD')).format() : '';
      let endDate = $check_out.val().length ? dayjs(dayjs($check_out.val(), 'DD/MM/YYYY').format('YYYY-MM-DD')).format() : '';

      FastbookingPackages.manageRestrictionsLoad($place_to_go.val(), startDate, endDate);
    }

    // Updates occupancy restrictions if package restrictions are loaded
    if (FastbookingPackages.hasRestrictionsLoaded()) {
      FastbookingPackages.manageOccupancyLimits();
    }

    // Validates promocode if needed
    FastbookingPromocode.validationControl();
  });

  fastbooking_data = HotelsData.getData();

  if ($clear_btn === undefined)
    $clear_btn = IB.currentDevice === 'mobile' ? $fastbooking.find('.cnt-select-row .clear-place-to-go') : $fastbooking.find('.input.place-to-go .clear');
  if ($clear_btn_destinia === undefined)
    $clear_btn_destinia =
      IB.currentDevice === 'mobile' ? $fastbooking.find('.js-place-origin-mobile .js-cnt-select-destinia .clear-destinia') : $fastbooking.find('.input.js-place-origin .clear');
  if ($voice_search_destinia === undefined)
    $voice_search_destinia =
      IB.currentDevice === 'mobile'
        ? $fastbooking.find('.js-place-origin-mobile .js-cnt-select-destinia .fb-voice-search-destinia')
        : $fastbooking.find('.input.js-place-origin .fb-voice-search');
  if ($voice_search === undefined) $voice_search = $fastbooking.find('.fb-voice-search');
}

function changeLocale(lang, callback) {
  import(`dayjs/locale/${lang}.js`).then(() => {
    dayjs.locale(lang);
    callback();
  });
}

function setFastbookingType() {
  let next_opening = false;
  let next_opening_date = '';
  let $place_to_go_selected;

  if ($staticHotel.length) {
    $place_to_go_selected = $staticHotel;
  } else {
    $place_to_go_selected = IB.currentDevice === 'mobile' ? $('.label-mobile-place-to-go').find('.chosen-select option:selected') : $('.place-to-go option:selected');
  }

  let place_to_go_val = $place_to_go_selected.val();
  let hotel;

  if (place_to_go_val !== null && typeof place_to_go_val !== 'undefined') {
    let val = $place_to_go_selected.val();
    let type = val.charAt(0);

    if (fastbooking_data === undefined || fastbooking_data === null) fastbooking_data = HotelsData.getData();

    if (type === TYPE_HOTEL && fastbooking_data.hotels !== undefined) {
      let hotels = fastbooking_data.hotels[1];
      hotel = hotels.filter(function (el) {
        return el.id === place_to_go_val;
      });
      next_opening = hotel.length ? hotel[0].data.next_opening : undefined;
    }

    if ($fastbooking !== undefined) {
      let $check_in = $fastbooking.find('.check-in');
      let $check_out = $fastbooking.find('.check-out');
      if (next_opening) {
        // Hemos elegido un hotel de próxima apertura
        $fastbooking.find('.new-hotels-section').removeClass('hidden');
        if ($fastbooking.hasClass('mobile-fastbooking-cnt')) $fastbooking.find('.new-hotels-section').closest('.row').removeClass('hidden');
        if (IB.currentDevice !== 'mobile') $fastbooking.find('.dates, .rooms, .discounts, .people, .promo, .rooms-container').addClass('hidden');
        hideShowButton('next-opening');
        $('#new_vo_booking .discount-checks').addClass('hidden');
        $('#new_vo_booking .actions').addClass('hidden');
        // Change the text of the date
        next_opening_date = dayjs().add(1, 'year');
        if (hotel[0].data.next_opening_date) {
          let lang = document.documentElement.getAttribute('data-js-lang');
          changeLocale(lang, () => {
            next_opening_date = dayjs(hotel[0].data.next_opening_date, 'YYYYMMDD').format('LL');
            let descText = $fastbooking.find('.new-hotels-section .desc').text();
            descText = descText.replace('{{date}}', next_opening_date);
            $fastbooking.find('.new-hotels-section .desc').text(descText);
          });
        }
        $check_in.val('');
        $check_out.val('');

        if ($('.info-nextopening-tooltip').length) {
          //$book_now.addClass('next-opening-disable');
          $('.info-nextopening-tooltip').text($('.new-hotels-section .desc').text());
          Tooltip.init($('.js-book-now-btn'));
        }
      } else {
        Tooltip.destroy($('.js-book-now-btn'));

        $fastbooking.find('.new-hotels-section').addClass('hidden');
        if ($fastbooking.hasClass('mobile-fastbooking-cnt')) $fastbooking.find('.new-hotels-section').closest('.row').addClass('hidden');
        type === TYPE_SPECIALITY || (!$check_in.val() && !$check_out.val()) ? hideShowButton('search') : hideShowButton('book-now');
        $('#new_vo_booking .discount-checks').removeClass('hidden');
        $('#new_vo_booking .actions').removeClass('hidden');
        if (IB.currentDevice === 'tablet') $fastbooking.find('.dates, .people, .promo, .rooms-container').show();
        else if (IB.currentDevice !== 'mobile') $fastbooking.find('.dates, .rooms, .people, .discounts, .promo, .rooms-container').removeClass('hidden');
      }
    }
  }
}

function hideShowButton(btn) {
  if ($fastbooking.hasClass('meetings')) return false;
  let is_mobile = IB.currentDevice === 'mobile';

  if (is_mobile) return false;

  let selectedDestination = $fastbooking.find('.place-to-go').find('select').val();
  let type = selectedDestination ? selectedDestination.charAt(0) : '';
  let $book_now = $('#book-now');

  if (btn === 'search') {
    $book_now.find('.search').removeClass('hidden');
    $book_now.find('.book-now').addClass('hidden');
    $book_now.find('.next-opening').addClass('hidden');
    $book_now.removeClass('next-opening-disable');
    if ($('.info-nextopening-tooltip').length) Tooltip.destroy($('.js-book-now-btn'));
  } else if (btn === 'book-now') {
    let $b = $book_now.find('.book-now');
    $book_now.find('.search').addClass('hidden');
    type === 'd' ? $b.text($b.data('destination-with-dates')) : $b.text($b.data('default'));
    $b.removeClass('hidden');
    $book_now.find('.next-opening').addClass('hidden');
    $book_now.removeClass('next-opening-disable');
    if ($('.info-nextopening-tooltip').length) Tooltip.destroy($('.js-book-now-btn'));
  } else if (btn === 'next-opening') {
    //Inicializo el tooltip de la ficha de hotel cuando éste es de próxima apertura
    if ($('.info-nextopening-tooltip').length) {
      $book_now.addClass('next-opening-disable');
    }
    $book_now.find('.next-opening').removeClass('hidden');
    $book_now.find('.book-now').addClass('hidden');
    $book_now.find('.search').addClass('hidden');
  }
}

function dateInRange(check_in_moment, check_out_moment, restriction) {
  let starts_at;
  let ends_at;
  let range_check_in = false;
  let range_check_out = false;

  starts_at = dayjs(restriction.starts_at, 'YYYYMMDD').valueOf();
  ends_at = dayjs(restriction.ends_at, 'YYYYMMDD').valueOf();

  let before_check_in = check_in_moment !== false && Number(check_in_moment) < Number(starts_at);
  range_check_in = check_in_moment !== false && Number(check_in_moment) >= Number(starts_at) && Number(check_in_moment) <= Number(ends_at);
  let after_check_out = check_out_moment !== false && Number(check_out_moment) >= Number(starts_at);
  range_check_out = check_out_moment !== false && Number(check_out_moment) >= Number(starts_at) && Number(check_out_moment) <= Number(ends_at);

  // Returns true if
  // - CheckIn in range
  // - CheckOut in range
  // - CheckIn before period without CheckOut OR with CheckOut greather than starts_at
  return range_check_in || range_check_out || (before_check_in && (!check_out_moment || after_check_out));
}

/**
 * Limit rooms selector
 *
 * @param {number} roomsLimit - number of rooms to limit or empty/'default' string to set to default
 */
function setRoomsSelectorLimit(roomsLimit) {
  if (!$fastbooking) {
    $fastbooking = isMobile ? $('.mobile-fastbooking-cnt') : $('.fastbooking');
  }

  let $roomsSelector;
  if (isMobile) {
    $roomsSelector = $fastbooking.find('.rooms-selector-cnt');
    if (!$roomsSelector.length) $roomsSelector = ChildrenControl.data.$fastbooking.siblings('#people-mobile-panel').find('.rooms-selector-cnt');
    let defaultMaxRooms = $roomsSelector.attr('data-max-rooms') || 3;
    let $inputRooms = $roomsSelector.find('input.rooms-select');
    if (!roomsLimit || isNaN(roomsLimit)) roomsLimit = +defaultMaxRooms; // Default value

    $inputRooms.attr('max', roomsLimit);
    CustomInputNumber.refresh($inputRooms);

    // update form data-max-rooms
    $fastbooking.find('form').attr('data-max-rooms', roomsLimit);
  } else {
    $roomsSelector = $fastbooking.find('.rooms-selector option');
    roomsLimit = +roomsLimit;

    // restore visibility
    $fastbooking.find('.room-option-wrapper.hidden option').each(function () {
      $(this).unwrap();
    });

    if (roomsLimit && roomsLimit > 0) {
      $roomsSelector
        .filter(function (index, el) {
          return +el.value > roomsLimit;
        })
        .wrap('<div class="room-option-wrapper hidden"></div>');
    }

    // update form data-max-rooms
    $fastbooking.attr('data-max-rooms', roomsLimit);
  }
}

/**
 * Set rooms selector value
 *
 * @param {number} value - value to set in rooms selector
 */
function setRoomsSelectorValue(value) {
  let $roomsSelector;

  if (!value || isNaN(value)) return;

  if (isMobile) {
    $roomsSelector = $fastbooking.find('.rooms-selector-cnt');
    if (!$roomsSelector.length) $roomsSelector = ChildrenControl.data.$fastbooking.siblings('#people-mobile-panel').find('.rooms-selector-cnt');
    let $inputRooms = $roomsSelector.find('input.rooms-select');
    $inputRooms.val(value);
    CustomInputNumber.refresh($inputRooms);
  } else {
    $roomsSelector = $fastbooking.find('.rooms-selector');
    $roomsSelector.val(value).trigger('change');
  }
}

/**
 * Sets room code "tihacodi" in fastbooking in order to pass it as parameter on crs url redirection
 * @param {String} code
 */
function setRoomCode(code) {
  if (!code) return;

  if (!$fastbooking) {
    const $fastbooking = isMobile ? $('.mobile-fastbooking-cnt') : $('.fastbooking');
  }

  $fastbooking.find('.room-code').val(code);
}

/**
 * Adds/removes rooms in rooms-container
 *
 * @param {number} qty - number of rooms to set
 */
function setRooms(qty) {
  let $template = $($room_template.html());
  let $people_mobile_panel = $('#people-mobile-panel');
  let $container = IB.currentDevice === 'mobile' ? ($people_mobile_panel.hasClass('opened') ? $people_mobile_panel : $fastbooking) : $fastbooking;
  let $wrapper = currentDevice.isMobile ? $('.mobile-fastbooking-cnt .rooms-container') : $('.fastbooking .rooms-container');

  let counter = $wrapper.find('.room-row').length;
  let rooms = [];
  let num;

  if (qty > 1) {
    $container.addClass('large');
  } else {
    $container.removeClass('large');
  }
  if (Number(qty) < counter) {
    num = counter - Number(qty);
    for (let i = Number(qty); i < counter; i++) {
      $wrapper
        .find('.room > [data-count=' + (i + 1) + ']')
        .closest('.room-row')
        .remove();
    }
  }

  if (IB.currentDevice === 'mobile') $('#mobile-rooms-selector').val(qty);

  if (Number(qty) > counter) {
    num = Number(qty) - counter;
    let $temp_template;
    for (let e = 0; e < num; e++) {
      $temp_template = $template.clone();
      const $inputAdults = $temp_template.find('.input.adults').find('input');
      const $inputChildren = $temp_template.find('.input.children').find('input');
      const groupName = Date.now();

      $inputAdults.attr('data-max', ChildrenControl.data.max_adults);
      $inputAdults.attr('data-group-name', groupName);
      $inputAdults.attr('data-combined-max', ChildrenControl.data.max_occupancy);
      $inputChildren.attr('data-max', ChildrenControl.data.max_children);
      $inputChildren.attr('data-group-name', groupName);
      $inputChildren.attr('data-combined-max', ChildrenControl.data.max_occupancy);

      $temp_template
        .find('[data-count]')
        .attr('data-count', counter + 1 + e)
        .end();
      $temp_template
        .find('#vo_booking_rooms_adults_count')
        .attr('name', 'vo_booking[rooms][' + (counter + e) + '][adults_count]')
        .attr('id', null)
        .end();
      $temp_template
        .find('#vo_booking_rooms_children_count')
        .attr('name', 'vo_booking[rooms][' + (counter + e) + '][children_count]')
        .attr('id', null)
        .end();
      $temp_template.html($temp_template.html().replace(/Personas/g, 'Personas | #' + (counter + 1 + e) + ' '));
      rooms.push($temp_template);
    }
    $wrapper.append(rooms);
  }
}

/**
 * Fix iOS, when select input text with readonly (datepicker), it shows pointer
 */
$(document).on('focusin', '.datepicker[readonly]', function () {
  this.blur();
});

function setRoomsData(data) {
  if (data === undefined || data === null || data === '') return;

  if (!$fastbooking) {
    $fastbooking = isMobile ? $('.mobile-fastbooking-cnt') : $('.fastbooking');
  }

  let rooms = Object.keys(data).length;
  let is_mobile = IB.currentDevice === 'mobile';

  setRooms(rooms);

  let $form = is_mobile ? $('.mobile-fastbooking-form') : $('.fastbooking').first();
  let rooms_num = $form.find('.room-row').length;
  let $room;
  let room_index;
  let ages;

  ChildrenControl.setAllowChildren($form);

  $form.find('.room-row').each(function (index, el) {
    $room = $(this);
    room_index = index;
    if (typeof data[room_index] !== 'undefined') {
      $room.find('.adults .js-input-number').val(data[room_index].adults_count).change();
      if (data[room_index].children_count) {
        $room.find('.children .js-input-number').val(data[room_index].children_count).change();
      }
      $room.find('.input.age').each(function (index, el) {
        ages = data[room_index].ages || [];
        $(el)
          .find('select.age option[value=' + ages[index] + ']')
          .prop('selected', true);
      });
    }
  });

  if (is_mobile) {
    $form.find('.rooms-select .js-input-number').val(rooms_num);
    CustomInputNumber.init($('.js-input-number'));
  } else {
    $form.find('.rooms-selector').val(rooms_num);
    $form.find('.result-number-of-rooms').text(rooms_num);
    if ($fastbooking.find('.room-row').length > 1) $('#people-popover .room-row').addClass('can-delete');

    $('.result-number-of-guest').text(calcNumberOfGuest());

    setRoomEditingState();
  }
}

function ifElementOnScreen($el) {
  let scroll_bottom = $(window).height() + $(window).scrollTop();
  let element_bottom = $el.offset().top + $el.outerHeight();
  if (scroll_bottom < element_bottom) {
    $('html,body').animate(
      {
        scrollTop: $(window).scrollTop() + element_bottom - scroll_bottom + 32,
      },
      500
    );
  }
}

function getMaxOfArray(numArray) {
  return Math.max.apply(null, numArray);
}

function roomsPopoverPosition($el) {
  if ($el.length != 0) {
    let $people_popover = $('#people-popover');
    let $people_input = $('.second-level .people');
    let people_input = $people_input.get(0);
    let $people_input_position = $people_input.position();
    let inner_width = [];
    let total_width;

    if (!$fastbooking) {
      $fastbooking = isMobile ? $('.mobile-fastbooking-cnt') : $('.fastbooking');
    }

    $people_popover.find('.room-row').each(function (index, el) {
      if ($(el).find('.ages').is(':visible')) {
        inner_width.push($(el).find('.room').outerWidth() + $(el).find('.ages').outerWidth() + 40);
      } else {
        inner_width.push($(el).find('.room').outerWidth() + 40);
      }
    });

    inner_width = getMaxOfArray(inner_width);
    total_width = $people_popover.outerWidth() >= inner_width ? $people_popover.outerWidth() : inner_width;

    $people_popover.css('left', $people_input_position.left);

    $fastbooking.find('.people').addClass('active');

    people_input.classList.add('active');
    people_input.addEventListener('transitionend', recolocatePopover('.second-level .people', '#people-popover'));

    // Relaunching custom input numbers
    CustomInputNumber.init($('.js-input-number'));
  }
}

function discountsPopoverPosition($el) {
  if ($el.length != 0) {
    let $discounts_popover = $('#discounts-popover');
    let $discounts_input = $('.second-level .discounts');
    let discount_input = $discounts_input.get(0);
    let $discounts_input_position = $discounts_popover.position();

    if (!$fastbooking) {
      $fastbooking = isMobile ? $('.mobile-fastbooking-cnt') : $('.fastbooking');
    }

    $discounts_popover.css('left', $discounts_input_position.left);

    $fastbooking.find('.discounts').addClass('active');

    discount_input.classList.add('active');
    discount_input.addEventListener('transitionend', recolocatePopover('.second-level .discounts', '#discounts-popover'));
  }
}

function closePopover($this, force = false, callback) {
  let $this_fastbooking = $this.closest('.fastbooking');
  let $people_popover = $('#people-popover');
  let people_input = document.querySelector('.second-level .people');

  $this_fastbooking.find('.people').removeClass('active');

  $this_fastbooking.parsley().validate('age');

  // Force close of popover if clicking in another input or outside the fastbooking
  if (!force && !$this_fastbooking.parsley().isValid('age', true)) {
    // Show all rooms with age error
    $people_popover.find('.js-child-input-age.parsley-error').closest('.js-room-row').removeClass('can-edit');

    return false;
  }
  $('.number-of-guest').removeClass('active');

  $('.result-number-of-guest').text(calcNumberOfGuest());
  $people_popover.hide();
  $people_popover.removeAttr('tabindex');

  setTimeout(function () {
    $people_popover.blur();
  }, 200);

  $('.rooms-advice-container').hide();

  // Hide package warning telling user that occupancy has been changed
  FastbookingPackages.togglePackageOccupancyModifiedWarning(false);
  IB.fastbooking_state.setState();
  people_input.removeEventListener('transitionend', recolocatePopover('.second-level .people', '#people-popover'));

  callback && callback();
}

function recolocatePopover(inputClasses, popoverId) {
  let input = document.querySelector(inputClasses);
  let popover = document.querySelector(popoverId);
  popover.style.left = `${input.offsetLeft}px`;
}

/*
 * Returns number of guest
 * return int
 */
function calcNumberOfGuest() {
  let $people_popover = $('#people-popover');
  let total = 0;
  let children = 0;

  $people_popover.find('.input.adults').each(function (index, el) {
    total = Number($(this).find('.js-input-number').val()) + total;
  });

  $people_popover.find('.input.children').each(function (index, el) {
    children = Number($(this).find('.js-input-number').val());
    total = children + total;
  });

  IB.fastbooking_state.setState();

  return total;
}

function addRoom($el, numberToAdd) {
  const ROOMS_ALERT_NUMBER = 7;
  let max_rooms = IB.currentDevice !== 'mobile' ? $el.closest('form').attr('data-max-rooms') : $('#fastbooking-mobile').find('form').attr('data-max-rooms');
  let newRooms = typeof numberToAdd === 'undefined' ? 1 : numberToAdd;

  max_rooms = max_rooms != undefined ? max_rooms : 3;

  let totalRooms = 0;

  if (IB.currentDevice !== 'mobile') {
    roomsPopoverPosition($('.number-of-guest'));
    totalRooms = newRooms + parseInt($('#people-popover .room-row').length);
    setRooms(totalRooms);
  } else {
    const customInput = $('.js-input-number');
    CustomInputNumber.init(customInput);
    totalRooms = $('#mobile-rooms-selector').val();
  }

  // Only show fancybox in iberostar_pro microsite and when the number
  // of rooms is 7
  if (IB.is_in_microsite && document.documentElement.dataset.inMicrosite === 'iberostar_pro' && parseInt(totalRooms) === ROOMS_ALERT_NUMBER) {
    const $box = $('.js-rooms-alert-fancybox');

    $.fancybox.open($box, {
      autoFocus: false,
      backFocus: false,
      trapFocus: false,
      beforeShow: function () {
        ScrollLock.on();
        $box.on('click', '.js-continue-btn', function (e) {
          e.preventDefault();
          e.stopPropagation();
          $.fancybox.close();
        });
      },
      afterClose: function () {
        ScrollLock.off();
      },
    });
  }

  if ($('#people-popover .room-row').length > 1) {
    $('#people-popover .room-row').not(':first').addClass('can-delete');
  }

  if ($('#people-popover .room-row').length >= max_rooms) {
    $el.hide();
  }
  let fastbooking_type = $('body').data('ea-fastbooking');
  renameEventCategory('', fastbooking_type);

  setRoomEditingState();
}

function setRoomEditingState() {
  if (IB.currentDevice === 'mobile') return false;

  // Show room if there is one room or it is editing
  let rooms_container = document.querySelector('#people-popover .people-content .rooms-container');
  // let rooms = document.querySelectorAll('#people-popover .people-content .rooms-container .room-row');
  let rooms = rooms_container.querySelectorAll('.room-row');

  if (rooms.length === 1) {
    rooms_container?.querySelector('.room-row:first-child').classList.remove('can-delete', 'can-edit');
  } else {
    rooms_container?.querySelector('.room-row:first-child').classList.add('can-delete');
    rooms_container?.querySelectorAll('.room-row:not(:last-child)').forEach(item => {
      item.classList.add('can-edit');
    });
  }
}

function setNumberOfRooms(val, is_diff) {
  let $people_mobile_panel = $('#people-mobile-panel');
  let $container = IB.currentDevice === 'mobile' ? ($people_mobile_panel.hasClass('opened') ? $people_mobile_panel : $fastbooking) : $fastbooking;

  if (IB.currentDevice === 'mobile') {
    var valueNow = $('#mobile-rooms-selector').val(),
      newValue = is_diff ? parseInt(valueNow) + parseInt(val) : val;
    $('#mobile-rooms-selector').html(newValue);
    $('#mobile-rooms-selector').val(newValue);
  } else {
    var valueNow = +$('.result-number-of-rooms').html(),
      newValue = is_diff ? valueNow + val : val;

    $('.result-number-of-rooms').html(newValue);

    $container.find('.rooms-selector option[value!=' + newValue + ']').removeAttr('selected');
    $container.find('.rooms-selector option[value=' + newValue + ']').attr('selected', 'selected');
  }
}

/**
 * Sets an specific hotel in fastbooking destination selector
 *
 * @param {string} hotelId - hotel id to set in destination selector, or empty string to set as empty
 */
function setDestination(hotelId) {
  if (!$fastbooking) {
    $fastbooking = IB.currentDevice !== 'mobile' ? $('.fastbooking.home') : $('#fastbooking-mobile');
  }

  let $fake_select = $fastbooking.find('.fake-select');
  let $chosen_selectors = $fastbooking.find('.chosen-select');

  // If empty string, clear destination select
  if (!hotelId) {
    setPlaceToGo($chosen_selectors, '');
    return;
  }

  if ($fake_select.length) {
    FastbookingSelect.init($chosen_selectors, function () {
      Fastbooking.initSelect($chosen_selectors, $fake_select);
      setPlaceToGo($chosen_selectors, hotelId);
      $('.js-mobile-fastbooking-bar').find('.open-mobile-panel').addClass('select-initialized');
    });
  } else {
    setPlaceToGo($chosen_selectors, hotelId);
  }

  if (sessionStorage.getItem('fastbooking_data')) {
    FastbookingDiscountResident.checkDiscount(Fastbooking.getSelectedPlace(hotelId));
    FastbookingDiscountMyIB.checkDiscount(Fastbooking.getSelectedPlace(hotelId));
  } else {
    HotelsData.get(function () {
      FastbookingDiscountResident.checkDiscount(Fastbooking.getSelectedPlace(hotelId));
      FastbookingDiscountMyIB.checkDiscount(Fastbooking.getSelectedPlace(hotelId));
    });
  }
}

/**
 * Sets a promocode in fastbooking promocode field
 *
 * @param {string} code - code to set in promocode input, or empty string to set as empty
 * @param {object | undefined} $fastbooking_selector - selector to populate promocode field without initialization.
 * example: setPromocode('LASTMINUTE')
 * example: setPromocode('LASTMINUTE', $('.fastbooking'))
 */
function setPromoCode(code, $fastbooking_selector) {
  code = code || '';
  let $fb = $fastbooking_selector || $fastbooking;
  $fb.find('.promo-code').val(code);
  IB.fastbooking_state.setState();
}

function removeRoom($el) {
  let counter = 0;
  let $people_mobile_panel = $('#people-mobile-panel');
  let $container = IB.currentDevice === 'mobile' ? ($people_mobile_panel.hasClass('opened') ? $people_mobile_panel : $fastbooking) : $fastbooking;
  let max_rooms = IB.currentDevice !== 'mobile' ? $el.closest('form').attr('data-max-rooms') : $('#fastbooking-mobile').find('form').attr('data-max-rooms');

  max_rooms = max_rooms != undefined ? max_rooms : 3;

  $el.closest('.room-row').remove();

  setNumberOfRooms(-1, true);
  let $this_row;
  let counter_age;
  let $this_age;
  $container.find('.room-row').each(function (index, el) {
    $this_row = $(this);
    counter_age = 0;
    let $title = IB.currentDevice === 'mobile' ? $this_row.find('> .title') : $($this_row).find('.room .t-fb-label');
    $title.attr('data-count', counter + 1);

    // Recorro todos los elementos hijo que tienen como id 'age-input...' ya que tengo que modificarles el número de habitación al haber sido borrada una y haber cambiado el data-count de la misma
    $this_row.find('.ages .input.age').each(function (index, el) {
      let $id = $(el).attr('id');
      let $separateContent = $id.split('-');
      $(el).attr('id', 'age-input-' + (counter + 1) + '-' + $separateContent[3]);
      $(el)
        .find('.select-wrapper .age')
        .attr('data-parsley-class-handler', '#age-input-' + (counter + 1) + '-' + $separateContent[3]);
    });

    $this_row.find('.adults .js-input-number').attr('name', 'vo_booking[rooms][' + counter + '][adults_count]');
    $this_row.find('.children .js-input-number').attr('name', 'vo_booking[rooms][' + counter + '][children_count]');

    $this_row.find('.input.age').each(function (index, el) {
      $this_age = $(this);

      $this_age.find('.js-input-number').attr({
        name: 'vo_booking[rooms][' + counter + '][ages][]',
        'data-id': 'vo_booking[rooms][' + counter + '][ages][' + counter_age + ']',
      });
      counter_age++;
    });

    counter++;
  });
  if ($container.find('.room-row').length <= max_rooms) {
    // && (!IB.currentDevice.match('desktop') && !IB.currentDevice.match('small-desktop'))
    $container.find('#people-popover').find('.rooms-actions .add-room').show();
  }
  if ($container.find('.room-row').length < 2) {
    $container.find('.room-row').removeClass('can-delete').closest('.large').removeClass('large');
    $('#people-mobile-panel.large').removeClass('large');
  }

  // Update input number if mobile
  if (IB.currentDevice === 'mobile') {
    let $input = $container.find('.rooms-select.js-input-number');
    $input.val(counter);
    CustomInputNumber.init($input, 'refresh');
  }

  setRoomEditingState();

  roomsPopoverPosition($('.number-of-guest'));
  let fastbooking_type = $('body').data('ea-fastbooking');
  renameEventCategory('', fastbooking_type);
}

/*
 * Checks if promo field is rendered
 * return Boolean
 *
 * @param {object | undefined} $fastbooking_selector  -  variable to check promo length without FB initialization
 *
 * example: isPromoFieldPresent()
 * example: isPromoFieldPresent($('.fastbooking'))
 */
function isPromoFieldPresent($fastbooking_selector) {
  var $fastbooking = $fastbooking_selector || $fastbooking;
  return $fastbooking.find('.promo').length;
}

/*
 * Returns promo code input field
 * return object {}
 */
function getPromoField() {
  return $fastbooking.find('.promo-code');
}

/*
 * Given promo_code
 * Load the var value into promo-code input
 * param String
 * example: loadPromoCode('SSFUNCPACK')
 */
function loadPromoCode(promo_code) {
  if (!isPromoFieldPresent()) return;
  let $input = $fastbooking.find('.promo-code');
  $input.val(promo_code).data('readonly', 'readonly');
  IB.fastbooking_state.setState();
}

/*
 * Given promo_codes
 * Builds the selector for them on the fly and appends in markup
 * Updates selector with selected promocode
 *
 * @param object {} (jquery)
 * @param {string} selected code  -  promo code itself
 * @param {object} $fastbooking_selector - jQuery object to access fastbooking before its initialization
 *
 * example: loadPromoCodeSelector($('.code'), 'SSFUNPACK')
 * example: loadPromoCodeSelector($('.code'), 'SSFUNPACK', $('.fastbooking'))
 */
function loadPromoCodeSelector(promo_codes, selected_code, $fastbooking_selector) {
  var $fastbooking = $fastbooking_selector || $fastbooking;
  if (!isPromoFieldPresent($fastbooking_selector)) return;

  let has_text_field = $fastbooking.find('.promo-code.single').length;
  let has_select = $fastbooking.find('.promo-code.selector.has-fk-select').length;

  if (has_text_field) {
    let $single_promo_field = $fastbooking.find('.promo-code.single');
    let field_id = $single_promo_field.attr('id');
    let field_name = $single_promo_field.attr('name');
    let $selector = $('<select/>').addClass('promo-code selector').attr('id', field_id).attr('name', field_name);
    let previous_val = $single_promo_field.val();
    let i = promo_codes.length;
    let opt;
    let code;

    let fastbooking_type = $('body').data('ea-fastbooking');
    while (i--) {
      code = $(promo_codes[i]).html();
      opt = $('<option/>').html(code).attr('value', code);
      if (selected_code !== undefined && code === selected_code) opt.attr('selected', 'selected');
      $selector.append(opt);
    }

    if (previous_val.length && !$selector.find('option[value=' + previous_val + ']').length) $selector.append($('<option/>').html(previous_val).attr('value', previous_val));

    if ($selector.find('option').length > 1) {
      $single_promo_field.replaceWith($selector);
      $selector.formikation();
    } else {
      $single_promo_field.val($selector.find('option').html());
    }
  }
  if (selected_code !== undefined) $fastbooking.find('.selector.has-fk-select').val(selected_code).trigger('formikation.update');
}

/*
 * Given a place-to-go val
 * Returns the destination
 * example: getDestination('h11')
 * return object {}
 */
function getDestination(val) {
  return HotelsData.get(() => {
    let type = val.charAt(0);
    let item = getSelectedPlace(val);

    if (type === TYPE_DESTINATION) return item;
    if (type === TYPE_HOTEL) {
      let destination = fastbooking_data.destinations[1].filter(function (d) {
        return d.id === item.data.destination;
      })[0];
      return destination;
    }
    return undefined;
  });
}

/*
 * Given a destination or hotel value
 * Returns the item in fastbooking_data
 * example: getSelectedPlace('h11')
 * return object {}
 */
function getSelectedPlace(val) {
  let type = val === undefined ? '' : val.charAt(0);

  if (!fastbooking_data) fastbooking_data = HotelsData.getData();

  if (fastbooking_data !== undefined && fastbooking_data !== null) {
    if (type === TYPE_HOTEL && fastbooking_data.hotels !== undefined) {
      let hotels = fastbooking_data.hotels[1];
      let hotel = hotels.filter(function (el) {
        return el.id === val;
      });
      return hotel.length ? hotel[0] : undefined;
    }
    if (type === TYPE_DESTINATION && fastbooking_data.destinations !== undefined) {
      let destinations = fastbooking_data.destinations[1];
      let destination = destinations.filter(function (el) {
        return el.id === val;
      });
      return destination.length ? destination[0] : undefined;
    }
  }
  return undefined;
}

/**
 * Loading last search into fastbooking. If fastbooking is dropdown, it will show the second level
 * @param {*} last_search Object with last search data
 */
function loadLastSearch(last_search) {
  if (last_search.place_to_go !== undefined && last_search.place_to_go !== null) {
    // If exist last search, show hotels and destinations select

    setTimeout(function () {
      $fastbooking.find('.chosen-results').removeClass('hidden');
      $fastbooking.find('.my-iberostar-info').hide();
    }, 2000);

    // Loading last search only home page
    if ($('.home-page').length) {
      // Show second level
      $fastbooking.addClass('focused');
      // Load place to go
      if (last_search.place_to_go !== undefined) {
        setDestination(last_search.place_to_go);
        activeClearButton(true);
      }
      // Load dates
      if (last_search.check_in_date !== undefined && last_search.check_out_date !== undefined) {
        let html_format = $('html').data('date-format');
        $fastbooking.find('.check-in').val(dayjs(last_search.check_in_date).format(html_format));
        $fastbooking.find('.check-out').val(dayjs(last_search.check_out_date).format(html_format));
        FastbookingDateRangePicker.day_from = last_search.check_in_date;
        FastbookingDateRangePicker.day_to = last_search.check_out_date;
        $fastbooking.find('.opt-dates').addClass('hidden');
        $fastbooking.find('.remove-dates').removeClass('hidden');
        let check_in = dayjs(last_search.check_in_date, html_format);
        let check_out = dayjs(last_search.check_out_date, html_format);
        $fastbooking.find('.check-in-field .date-span').html(dayjs(last_search.check_in_date).format(html_format));
        $fastbooking.find('.check-out-field .date-span').html(dayjs(last_search.check_out_date).format(html_format));
        FastbookingDateRangePicker.updateNumNights($fastbooking, check_out.diff(check_in, 'days'));
      }
      // Load people
      if (last_search.rooms !== undefined) {
        // Load rooms
        $fastbooking.find('.result-number-of-rooms').text(last_search.rooms.length);
        $fastbooking.find('.rooms-selector option[value!=' + last_search.rooms.length + ']').removeAttr('selected');
        $fastbooking.find('.rooms-selector option[value=' + last_search.rooms.length + ']').attr('selected', 'selected');
        setRooms(last_search.rooms.length);
        // Load occupancy
        let occupancy = [];
        for (let i = 0; i < last_search.rooms.length; i += 1) {
          occupancy.push({ adults: parseInt(last_search.rooms[i].adults_count), children: parseInt(last_search.rooms[i].children_count), age: last_search.rooms[i].ages });
        }
        ChildrenControl.setOccupancy(occupancy);
        let optionSelected = $('#vo_booking_place_to_go').find('option:selected');
        if (optionSelected) {
          ChildrenControl.setMaxAdultsAndChildren(optionSelected);
        }
      }
      IB.fastbooking_state.setState();
    }
  }
}

function updatePeopleMobileResume() {
  let $mobile_fastbooking_cnt = $('#people-mobile-panel').hasClass('opened') ? $('#people-mobile-panel') : $('#fastbooking-mobile');
  let rooms_count = $mobile_fastbooking_cnt.find('.room-row').length;
  let $people_mobile_resume = $('.people-mobile-resume');
  let $room_input = $people_mobile_resume.find('.rooms');
  let adults_count = 0;
  let $adults_input = $people_mobile_resume.find('.adults');
  let children_count = 0;
  let $children_input = $people_mobile_resume.find('.children');

  $mobile_fastbooking_cnt.find('.room-row').each(function (index, element) {
    let $element = $(element);
    let $adults = $element.find('.adults');
    let $children = $element.find('.children');

    adults_count += +$adults.find('.input-number').val();
    children_count += +$children.find('.input-number').val();
  });

  let rooms_text = rooms_count === 1 ? rooms_count + ' ' + $room_input.data('room') : rooms_count + ' ' + $room_input.data('rooms');
  let adults_text = adults_count === 1 ? adults_count + ' ' + $adults_input.data('adult') : adults_count + ' ' + $adults_input.data('adults');
  let children_text = children_count === 1 ? children_count + ' ' + $children_input.data('child') : children_count + ' ' + $children_input.data('children');

  $room_input.text(rooms_text);
  $adults_input.text(adults_text);
  $children_input.text(children_text);

  adults_count === 0 ? $adults_input.hide() : $adults_input.show();
  children_count === 0 ? $children_input.hide() : $children_input.show();
}

$doc
  .on('change', '.rooms-select', function () {
    if (IB.currentDevice === 'mobile') setRooms($(this).val());
  })
  .on('change', '.child-select', function () {
    const $this = $(this);
    const numberOfChildren = parseInt($this.val());
    let fastbooking_type = $('body').data('ea-fastbooking');

    // Adds/removes child age selector based of number of children selected
    ChildrenControl.setChildren($this);

    renameEventCategory('', fastbooking_type);
    if ($('#people-popover').is(':visible')) {
      roomsPopoverPosition($('.number-of-guest'));
    }
    if (IB.currentDevice === 'mobile') updatePeopleMobileResume();

    if (numberOfChildren > 0) {
      const $childrenAgeSelectorToWatch = $this.closest('.js-room-row').find('.js-age:not(js-watching)');
      if (!$childrenAgeSelectorToWatch.length) return;

      $childrenAgeSelectorToWatch.addClass('js-watching').on('change', () => {
        const $childrenAgeSelectors = $this.closest('.js-room-row').find('.js-age');
        const allAgesSelected = $childrenAgeSelectors.toArray().every(element => element.value.length);
        if ($childrenAgeSelectors.length === 1 || allAgesSelected) {
          setRoomEditingState();
        }
      });
    }
  })
  .on('change', '.mobile-fastbooking-cnt .input.adults input', function () {
    if (IB.currentDevice === 'mobile') updatePeopleMobileResume();
  })
  .on('change', '.country select', function () {
    // Fastbooking with country/hotel selector
    let country_id = $(this).val();
    let $hotel_options = $('.hotel select option');
    if (country_id.length > 0) {
      $hotel_options
        .prop('disabled', true)
        .hide()
        .filter('[data-country-id="' + country_id + '"]')
        .prop('disabled', false)
        .show();
    } else {
      $hotel_options.prop('disabled', false).show();
    }

    if ($('.hotel.vertical select option:selected').attr('data-country-id') != country_id) {
      $('.hotel.vertical select').val('').change();
    }
  })
  .on('change', '.hotel select, .input.place-to-go select', function () {
    FastbookingDateRangePicker.day_from = null;
    FastbookingDateRangePicker.day_to = null;
    // Enable or disable the children selector
    $fastbooking = $('.fastbooking');
    let $el = $(this);
    let $option = $el.find('option:selected');
    let val = $option.val();
    let type = val === undefined ? '' : val.charAt(0);
    let is_hotels = type === TYPE_HOTEL;
    let is_last_search = $option.parent().hasClass('last-searches');
    let place = getSelectedPlace(val);

    $el.parent().parsley().validate();
    FastbookingDiscountResident.checkDiscount(place);
    FastbookingDiscountMyIB.checkDiscount(place);

    setFastbookingType();
    if (is_hotels) {
      FastbookingDateRangePicker.current_offer = null;
    }
    if (is_last_search) {
      let html_format = $('html').data('date-format');
      let check_in = $option.data('check-in');
      let check_out = $option.data('check-out');
      let rooms_data = $option.data('rooms');
      let rooms_data_obj;

      if (rooms_data) {
        rooms_data_obj = rooms_data.reduce(function (acc, cur, i) {
          acc[i] = cur;
          return acc;
        }, {});

        setRoomsData(rooms_data_obj);
      }


      if (check_in !== undefined) {
        let now = dayjs();
        let b = dayjs(check_in, html_format);
        let diff = now.diff(b, 'days');
        if (diff < 1) {
          $el.closest('form').find('.check-in').val(dayjs(check_in, html_format).format(html_format));
          if (check_out !== undefined) $el.closest('form').find('.check-out').val(dayjs(check_out, html_format).format(html_format));
          if ((IB.currentDevice === 'desktop' || IB.currentDevice === 'small-desktop') && rooms_data !== undefined) {
            let r_number = Object.keys(rooms_data_obj).length;
            $fastbooking.find('.rooms-selector').val(r_number);
            $fastbooking.find('.result-number-of-rooms').html(r_number);
          }
        }
      }
      // If package loaded manage restrictions and show warnings
      if (FastbookingPackages.hasPackageLoaded()) {
        let startDate = check_in ? dayjs(check_in, html_format).format() : '';
        let endDate = check_out ? dayjs(check_out, html_format).format() : '';

        FastbookingPackages.manageRestrictionsLoad($place_to_go.val(), startDate, endDate);
      }
    }
    calAgencyMaxRoom(place, type);

    checkAnimation();
  })
  .on('change', '.mobile-fastbooking-cnt .label-mobile-place-to-go select.chosen-select', function () {
    FastbookingDateRangePicker.day_from = null;
    FastbookingDateRangePicker.day_to = null;

    let $el = $(this);
    let $option = $el.find('option:selected');
    let val = $option.val();
    let type = val.charAt(0);
    let $fastbooking = $el.closest('.mobile-fastbooking-cnt');
    let is_hotels = type === TYPE_HOTEL;
    FastbookingDiscountResident.checkDiscount(getDestination(val));
    FastbookingDiscountMyIB.checkDiscount(getSelectedPlace(val));
    Fastbooking.setFastbookingType();

    // Set children inputs and ages enabled/disabled
    ChildrenControl.setAllowChildren($el.closest('form'));
    // Update age select with min and max valid values
    ChildrenControl.setMaxChildrenAge($el.closest('form'));

    if (is_hotels) {
      FastbookingDateRangePicker.current_offer = null;
    }

    let html_format = $('html').data('date-format');
    let check_in = $option.data('check-in');
    let check_out = $option.data('check-out');
    let $form = $el.closest('form');

    if (check_in !== undefined) {
      let now = dayjs();
      let b = dayjs(check_in, html_format);
      let diff = now.diff(b, 'days');
      if (diff < 1) {
        $form.find('.check-in').val(dayjs(check_in, html_format).format(html_format));
        if (check_out !== undefined) $form.find('.check-out').val(dayjs(check_out, html_format).format(html_format));

        // Set children inputs and ages enabled/disabled
        ChildrenControl.setAllowChildren($form);
        // Update age select with min and max valid values
        ChildrenControl.setMaxChildrenAge($form);
      }
    }

    let $that = $(this);

    if ($that.hasClass('last-search-opt') && !$that.find('.saved-dates').length) {
      $('.remove-dates').trigger('click');
    }

    // shows remove class opt when loading last search with its dates (if previous search was only a destination
    // without dates, it keeps it hidden)
    if ($option.hasClass('last-search-opt')) {
      if (check_in && check_out) {
        $fastbooking.find('.check-in-field .date-span').text(check_in);
        $fastbooking.find('.check-out-field .date-span').text(check_out);
        $fastbooking.find('.check-in-field .check-in').val(dayjs(check_in, html_format).format(html_format));
        $fastbooking.find('.check-in-field .check-out').val(dayjs(check_out, html_format).format(html_format));
        FastbookingDateRangePicker.day_from = check_in;
        FastbookingDateRangePicker.day_to = check_out;
        $fastbooking.find('.opt-dates').addClass('hidden');
        $fastbooking.find('.remove-dates').removeClass('hidden');
        check_in = dayjs(check_in, html_format);
        check_out = dayjs(check_out, html_format);
        FastbookingDateRangePicker.updateNumNights($fastbooking, check_out.diff(check_in, 'days'));
      } else {
        $fastbooking.find('.opt-dates').removeClass('hidden');
        $fastbooking.find('.remove-dates').addClass('hidden');
        $form.find('.check-in').val('');
        $form.find('.check-out').val('');
        FastbookingDateRangePicker.updateNumNights($fastbooking);
      }
    }

    calAgencyMaxRoom(getSelectedPlace(val), type);
    IB.fastbooking_state.setState();
  })

  .on('click', '.number-of-guest', function (event) {
    // Only for home fasbooking
    event.preventDefault();
    let $el = $(this);
    let $people_popover = $('#people-popover');

    let max_rooms = IB.currentDevice !== 'mobile' ? $el.closest('form').attr('data-max-rooms') : $('#fastbooking-mobile').find('form').attr('data-max-rooms');

    if(Object.keys(IB.fastbooking_state.getField('rooms_info')).length >= parseInt(max_rooms)){
      $people_popover.find('.rooms-actions .add-room').hide();
    }

    $el.addClass('active');
    $el.closest('.people').addClass('active');
    $people_popover.show();
    $people_popover.attr('tabindex', 0);

    setTimeout(function () {
      $people_popover.focus();
    }, 200);

    roomsPopoverPosition($el);

    if (FastbookingPackages.hasRestrictionsLoaded()) {
      FastbookingPackages.manageOccupancyLimits();
    }

    let optionSelected;

    if (isFhiPage) {
      optionSelected = $('.js-fhi-hotel-code');
    } else {
      optionSelected = $('#vo_booking_place_to_go').find('option:selected');
    }

    if (optionSelected) {
      ChildrenControl.setMaxAdultsAndChildren(optionSelected);
    }
  })
  .on('click', '.custom-input-number button', function (event) {
    event.preventDefault();
    $('.result-number-of-guest').text(calcNumberOfGuest());
  })
  .on('click', '.close-people-popover', function (event) {
    event.preventDefault();
    if (FastbookingPackages.hasRestrictionsLoaded() && !FastbookingPackages.isOccupancyValid()) {
      return;
    }
    closePopover($(this));
  })
  .on('click', '.fastbooking.home .discounts', function (event) {
    event.preventDefault();
    let $el = $(this);
    let $discounts_popover = $('#discounts-popover');
    const promoCode = $discounts_popover.get(0).querySelector('.js-promo-code')?.value;
    const $discountsInput = document.querySelector('.fastbooking.home .discounts');
    $el.addClass('active');
    $el.closest('.discounts').addClass('active');

    discountsPopoverPosition($el);

    if (!$el.hasClass('opened-popup')) {
      $discounts_popover.show();
      $discounts_popover.attr('tabindex', 0);
      $discounts_popover.focus();
      $el.get(0).classList.add('opened-popup');

      $discounts_popover.get(0).addEventListener(
        FB_DROPDOWN_CLOSE_EVENT,
        event => {
          const $el = event.detail;
          if ($el.classList.contains('discounts-container-open-popup') || $el.closest('.discounts-container-open-popup')) return;
        },
        { once: true }
      );
    } else {
      closePopover($('.fastbooking.home #discounts-popover'));
      ScrollLock.off();
      $el.get(0).classList.remove('opened-popup');
    }

    // Show promocode clear button if has promocode value
    toggleClearPromoButton(!!promoCode);
  })
  .on('change', '.rooms-selector', function (e) {
    // marcamos
    e.preventDefault();
    let newValue = $(this).val();
    let oldValue = $('.result-number-of-rooms').html();

    if (newValue === oldValue) return;

    if (newValue >= oldValue) {
      addRoom($('.add-room'), newValue - oldValue);
    } else {
      $($fastbooking.find('.room-row').slice(newValue - oldValue)).each(function (index, el) {
        removeRoom($(el).find('.remove-room'));
      });
    }
    setNumberOfRooms(newValue, false);

    // Set children inputs and ages enabled/disabled
    ChildrenControl.setAllowChildren($fastbooking);

    // If has package loaded, manage occupancy to respect package limits
    if (FastbookingPackages.hasRestrictionsLoaded()) {
      FastbookingPackages.manageOccupancy();
    }

    IB.fastbooking_state.setState();
    $('.result-number-of-guest').text(calcNumberOfGuest());
  })
  .on('change', '#mobile-rooms-selector', function (e) {
    e.preventDefault();

    if (IB.currentDevice !== 'mobile') return false;

    let $wrapper = $('#people-mobile-panel').hasClass('opened') ? $('#people-mobile-panel .rooms-container') : $fastbooking;
    let newValue = $(this).val();
    let oldValue = $wrapper.find('.room-row').length;
    if (newValue === oldValue) return;

    if (newValue >= oldValue) {
      addRoom($('.add-room'), newValue - oldValue);
    } else {
      $($('.mobile-fastbooking-cnt .room-row').slice(newValue - oldValue)).each(function (index, el) {
        removeRoom($(el).find('.remove-room'));
      });
    }
    setNumberOfRooms(newValue, false);

    // Set children inputs and ages enabled/disabled
    ChildrenControl.setAllowChildren($fastbooking);

    updatePeopleMobileResume();
  })
  .on('click', '.rooms-selector-cnt .js-input-number-inc:not(.is-disabled)', function (e) {
    // If has package loaded, manage occupancy to respect package limits
    if (FastbookingPackages.hasRestrictionsLoaded()) {
      FastbookingPackages.manageOccupancy();
    }
  })
  .on('click', '.add-room', function (e) {
    e.preventDefault();
    addRoom($(this));
    let $input = $('#people-popover').find('.js-input-number');

    // Set children inputs and ages enabled/disabled
    ChildrenControl.setAllowChildren($fastbooking);

    CustomInputNumber.init($input, 'refresh');

    // If has package loaded, manage occupancy to respect package limits
    if (FastbookingPackages.hasRestrictionsLoaded()) {
      FastbookingPackages.manageOccupancy();
    }

    setNumberOfRooms(1, true);
  })
  .on('click', '.remove-room', function (event) {
    event.preventDefault();
    removeRoom($(this));
    if (IB.currentDevice === 'mobile') {
      updatePeopleMobileResume();
    }

    // If has package loaded, manage occupancy to respect package limits
    if (FastbookingPackages.hasRestrictionsLoaded()) {
      FastbookingPackages.manageOccupancy();
    }

    $('.result-number-of-guest').text(calcNumberOfGuest());
  })
  .on('click', '.edit-room', function (event) {
    event.preventDefault();
    $fastbooking.find('.room-row').not('can-edit').addClass('can-edit');
    setRoomEditingState();
    $(this).closest('.js-room-row').removeClass('can-edit');
  })
  .on('click', '#search-new-hotel', function (event) {
    // Clear and open mobile fastbooking
    setPlaceToGo($('.label-mobile-place-to-go select'), '');
    $('.js-mobile-fastbooking-bar .open-mobile-panel').trigger('click');
  })
  .on('click', '.label-place-to-go', function (e) {
    let $chosen_select = $(this).closest('.place-to-go').find('.chosen-container');
    if (!$chosen_select.hasClass('chosen-with-drop')) {
      $chosen_select.trigger('mousedown');
    } else if ($chosen_select.hasClass('chosen-with-drop')) {
      $chosen_select.trigger('mouseup');
    }
  })
  .on('click', '.only-adults-fancybox .js-continue-btn', function (e) {
    e.preventDefault();
    e.stopPropagation();
    document.querySelectorAll('#people-popover .js-room-row .js-input-number').forEach(input => {
      ChildrenControl.setChildren($(input));
    })
    $.fancybox.close();
  })
  .on('click', '.only-adults-fancybox .js-change-destination-btn', function () {
    // Sin el timeout no funciona
    $.fancybox.close();
    setTimeout(function (e) {
      openPlaceToGoBox();
    }, 1);
  })
  .on('click', '.rooms-advice-container .close', function (e) {
    e.preventDefault();
    $(this).closest('.rooms-advice-container').hide();
  })
  /*
   * 3. dropdowns events destinia
   */
  .on('click', '.js-label-origin', function (e) {
    let $chosen_select = $(this).closest('.js-place-origin').find('.js-container-destinia');
    if (!$chosen_select.hasClass('chosen-with-drop')) {
      $chosen_select.trigger('mousedown');
    } else if ($chosen_select.hasClass('chosen-with-drop')) {
      $chosen_select.trigger('mouseup');
    }
  });
// Toggles promocode visibility
function toggleClearPromoButton(force) {
  const $clearBtn = $fastbooking.get(0).querySelector('.js-clear-promo');
  if (!$clearBtn) return;

  const shouldToggleVisibility = typeof force === 'boolean' ? !force : undefined;
  $clearBtn.classList.toggle('is-hidden', shouldToggleVisibility);
}

$doc.on('change keyup', '.js-promo-code', function (e) {
  const hasValue = !!$(this).val();
  toggleClearPromoButton(hasValue);
});

$doc.on('click', '.js-clear-promo', function (e) {
  const $promoInput = $fastbooking.get(0).querySelector('.js-promo-code');
  this.closest('.promo-active')?.classList.remove('promo-active');
  $promoInput.value = '';
  RemoveBannerAppliedCodeUnderFb ()

  // Clear error message
  if(currentDevice.isDesktop) {
    const promocodeText = $fastbooking.get(0).querySelector('.js-promocode-text');
    const  promocodeInput = $fastbooking.get(0).querySelector('.js-promocode-input');
    promocodeText.innerHTML = ''
    promocodeText.classList.add('hidden')
    promocodeInput.dataset.valueInput = ''
  }

  const promoError = $fastbooking.get(0).querySelector('.input.promo.error');
  const promoErrorMsg = $fastbooking.get(0).querySelector('.promo-error');

  promoError && promoError.classList.remove('error')
  promoErrorMsg && promoErrorMsg.classList.add('hidden')

  updateDiscountCounter();
  toggleClearPromoButton(false);
});

/* CLEAR INPUT BUTTON */
$(document)
  .on('click', '.input.place-to-go .clear, .cnt-select-row .clear-place-to-go', function (e) {
    e.preventDefault();
    e.stopPropagation();

    clearPlaceToGo();
    $('.ages .general-age').removeClass('hidden');
    $('.ages .limit-age').addClass('hidden');
    Tooltip.init($('.ages [data-tooltip]'));
  })

  .on('click', '.chosen-results .close-no-results', function () {
    clearPlaceToGo();
  })
  .on('click touchstart', '.input.place-to-go, .label-mobile-place-to-go', function () {
    //, .label-mobile-place-to-go
    if ($clear_btn === undefined) $clear_btn = IB.currentDevice === 'mobile' ? $('.cnt-select-row .clear-place-to-go') : $('.fastbooking .input.place-to-go .clear');
    if ($voice_search === undefined) $voice_search = $('.mobile-fastbooking-cnt .fb-voice-search');
    if (IB.currentDevice !== 'mobile') {
      let $select = $fastbooking.find('.chosen-select');
      let $my_iberostar_label = $fastbooking.find('.my-iberostar-info');
      if (!$my_iberostar_label.find('.my-title').text().length) {
        $my_iberostar_label.find('.my-title').text($select.data('my-iberostar-title'));
        $my_iberostar_label.find('.my-info').text($select.data('my-iberostar-info'));
        $my_iberostar_label
          .find('.my-link')
          .attr({ href: $select.data('log-in-url'), 'data-is-in-iberostarpro': $select.data('is-in-iberostarpro'), 'data-user-url': $select.data('user-url') })
          .text($select.data('my-iberostar-button'));
      }
    }
    setTimeout(switchClearButtonPlaceToGo(), 500);
    /* Fixed scroll is not top when open searcher */
    if (IB.currentDevice === 'desktop') $(this).find('.chosen-results').scrollTop(0);
  })

  /*
   * 4. Clear input button (destinia)
   */
  .on('click', '.input.js-place-origin .clear, .js-place-origin-mobile .js-cnt-select-destinia .clear-destinia', function (e) {
    e.preventDefault();
    e.stopPropagation();
    clearPlaceToDestinia();
    if (IB.currentDevice === 'desktop') $(this).find('.js-results-destinia').scrollTop(0);
  })
  .on('click', '.js-results-destinia .close-no-results', function () {
    clearPlaceToDestinia();
  })
  .on('click touchstart', '.input.js-place-origin, .js-place-origin-mobile', function () {
    document.querySelector('.js-input-user-text-destinia').classList.add('show');

    if ($clear_btn_destinia === undefined)
      $clear_btn_destinia = IB.currentDevice === 'mobile' ? $('.js-place-origin-mobile .js-cnt-select-destinia .clear-destinia') : $('.fastbooking .input.js-place-origin .clear');
    if ($voice_search_destinia === undefined)
      $voice_search_destinia =
        IB.currentDevice === 'mobile' ? $('.js-place-origin-mobile .js-cnt-select-destinia .fb-voice-search-destinia') : $('.fastbooking .input.js-place-origin .fb-voice-search');
    if (IB.currentDevice !== 'mobile') {
      let $select = $fastbooking.find('.js-destinia-select');
      let $my_iberostar_label = $fastbooking.find('.my-iberostar-info');
      if (!$my_iberostar_label.find('.my-title').text().length) {
        $my_iberostar_label.find('.my-title').text($select.data('my-iberostar-title'));
        $my_iberostar_label.find('.my-info').text($select.data('my-iberostar-info'));
        $my_iberostar_label
          .find('.my-link')
          .attr({ href: $select.data('log-in-url'), 'data-is-in-iberostarpro': $select.data('is-in-iberostarpro'), 'data-user-url': $select.data('user-url') })
          .text($select.data('my-iberostar-button'));
      }
    }
    setTimeout(switchClearButtonPlaceToGo(), 500);
    /* Fixed scroll is not top when open searcher */
    if (IB.currentDevice === 'desktop') $(this).find('.chosen-results').scrollTop(0);
  });

function clearPlaceToGo() {
  let is_mobile = IB.currentDevice === 'mobile';
  let $container = is_mobile ? $('.label-mobile-place-to-go') : $('#desktop-place-to-go');
  let $select = is_mobile ? $('#mobile-hotels-selector') : $container.find('select');
  setPlaceToGo($select, '');
  ChildrenControl.setMaxAdultsAndChildren($select.find('option:selected'));
  setTimeout(function () {
    activeClearButton(false);
    openPlaceToGoBox();
    resetPlaceToGoBox();
    $('.fastbooking .fake-select-click').trigger('click');
  }, 500);

  // If package loaded manage restrictions and show warnings
  if (FastbookingPackages.hasPackageLoaded()) {
    let $check_in = $fastbooking.find('.check-in');
    let $check_out = $fastbooking.find('.check-out');
    let startDate = $check_in.val().length ? dayjs(dayjs($check_in.val(), 'DD/MM/YYYY').format('YYYY-MM-DD')).format() : '';
    let endDate = $check_out.val().length ? dayjs(dayjs($check_out.val(), 'DD/MM/YYYY').format('YYYY-MM-DD')).format() : '';

    FastbookingPackages.manageRestrictionsLoad($place_to_go.val(), startDate, endDate);
  }
}

function activeClearButton(state) {
  if ($clear_btn !== undefined) {
    state ? $clear_btn.show() : $clear_btn.hide();
    if (!FastbookingVoiceSearch.isSpeechRecEnabled()) {
      if ($voice_search.length) $voice_search.remove();
      return;
    }
    state ? $voice_search.hide() : $voice_search.show();
  }
}

function switchClearButtonPlaceToGo() {
  const $destinationSelect = !currentDevice.isMobile ? document.querySelector('#desktop-place-to-go select') : document.querySelector('#mobile-hotels-selector');
  const $destinationInput = $fastbooking.get(0).querySelector('.input-text-by-user');
  const destinationHasValue = ($destinationSelect && $destinationSelect.value) || ($destinationInput && $destinationInput.value);
  activeClearButton(destinationHasValue);
}
/**
 * 5. on click on bin button clear(reset) input, open dropdown
 */
function clearPlaceToDestinia() {
  let is_mobile = IB.currentDevice === 'mobile';
  let $container = is_mobile ? $('.js-label-origin-mobile') : $('#desktop-origin');
  let $select = is_mobile ? $('#airport-mobile-selector') : $container.find('.js-destinia-select');

  setFastbookingDepartureAirport($select, '');
  setTimeout(function () {
    activeClearButtonDestinia(false);
    openFastbookingDepartureAirport();
    resetFastbookingDepartureAirport();
    $('.fastbooking .fake-select-click-destinia').trigger('click');
  }, 500);
}
/**
 * 6. show or hide clear button
 */
function activeClearButtonDestinia(state) {
  if ($clear_btn_destinia !== undefined) {
    state ? $clear_btn_destinia.show() : $clear_btn_destinia.hide();
    if (!FastbookingVoiceSearch.isSpeechRecEnabled()) {
      if ($voice_search_destinia.length) $voice_search_destinia.remove();
      return;
    }
    state ? $voice_search_destinia.hide() : $voice_search_destinia.show();
  }
}

function switchClearButtonDestinia() {
  const $airportsSelect = !currentDevice.isMobile ? document.querySelector('#desktop-origin input') : document.querySelector('#airport-mobile-selector');
  const $airportsInput = $fastbooking.get(0).querySelector('.js-input-user-text-destinia');
  const airportsHasValue = ($airportsSelect && $airportsSelect.value) || ($airportsInput && $airportsInput.value);
  activeClearButtonDestinia(airportsHasValue);
}

$(document)
  .on('keyup input', '.input.place-to-go, .label-mobile-place-to-go', function () {
    // If user begins to write, the hotels and destinations select is showed
    if (!$fastbooking.find('.chosen-results').is(':visible')) {
      $fastbooking.find('.chosen-results').removeClass('hidden');
      $fastbooking.find('.my-iberostar-info').hide();
    }

    let $this = $(this);
    let $container = IB.currentDevice === 'mobile' ? $('.label-mobile-place-to-go') : $('#desktop-place-to-go');
    option_active_result = $container.find('.active-result');

    // Ponemos visible el botón para limpiar el campo de texto
    setTimeout(switchClearButtonPlaceToGo(), 500);
  })

  /**
   * 7. show airport dropdown on mobile
   */
  .on('keyup input', '.input.js-place-origin, .js-label-origin-mobile', function () {
    document.querySelector('.js-input-user-text-destinia').classList.add('show');

    // If user begins to write, the hotels and destinations select is showed
    if (!$fastbooking.find('.js-results-destinia').is(':visible')) {
      $fastbooking.find('.js-results-destinia').removeClass('hidden');
      $fastbooking.find('.my-iberostar-info').hide();
    }
    let $this = $(this);
    let $container = IB.currentDevice == 'mobile' ? $('.js-label-origin-mobile') : $('#desktop-origin');
    option_active_result = $container.find('.active-result');
    /**
     * 8. show clean button for input
     */
    setTimeout(switchClearButtonDestinia(), 500);
  })
  .on('keypress', '.fastbooking .people-content, .fastbooking .input.people', function (e) {
    let key = e.which;
    if (key == 13) {
      // the enter key code
      $('.fastbooking .people-content .close-people-popover').click();
      return false;
    }
  });

/* FOCUS ON FASTBOOKING */
/* FOCUS ON FASTBOOKING FUNCTIONS */
function createNewDOMitem(createLinkElement, new_item_class, parent, attr) {
  if (new_item_class && parent && attr && $('[' + attr + ']').length <= 0) {
    let elementToPrepend = createLinkElement
      ? '<a href="" class="' + new_item_class + '" ' + attr + ' aria-haspopup="true" tabindex="0"></a>'
      : '<span class="' + new_item_class + '" ' + attr + '></span>';
    $(parent).prepend(elementToPrepend);
  }
}
function RemoveBannerAppliedCodeUnderFb() {
  const bannerCodeActivated = document.querySelector('.code-activated');
  bannerCodeActivated?.classList.add('is-hidden');
}


function createFakeDestinationClick() {
  createNewDOMitem(true, 'fake-select-click utag-fake-fb-click', '.input.place-to-go', 'data-fake-select-click'); // class, //parent, //attr
}
/**
 * 9. create fake select for airport (destinia)
 */
function createFakeDestiniaClick() {
  createNewDOMitem(true, 'fake-select-click-destinia', '.input.js-place-origin', 'data-fake-select-click-destinia'); // class, //parent, //attr
}

/* FOCUS ON FASTBOOKING EVENTS */
$(document).on('mousedown', '.fastbooking .rooms-selector', function () {
  checkFocusedElement($(this));
});

$(document).on(
  'click',
  '.fastbooking .number-of-guest, .fastbooking .discounts-fb-link, .discounts-container-open-popup, .fastbooking .check-in-container, .fastbooking .check-out-container, .fastbooking .fake-select, .fastbooking .fake-select-click, .fastbooking .promo-code',
  function () {
    checkFocusedElement($(this));
  }
);
/**
 * 10. focus on input for airport (destinia)
 */
$(document).on('click', '.fastbooking .fake-select-click-destinia, .fastbooking .fake-select-click-destinia', function () {
  document.querySelector('.js-input-user-text-destinia').classList.add('show');
  checkFocusedElementDestinia($(this));
});

function checkFocusedElement(_this) {
  let $this = _this;
  $('.fastbooking').addClass('focused');
  /* if its the simple type of fastbooking it doesnt add anything */
  if (!$('.fastbooking').hasClass('simple')) {
    /* removes class focus from the other fields and adds it to the clicked one */
    focusoutChosen();

    $('.input:not(.submit), .dates').removeClass('focused').find('.check-in-container, .check-out-container').removeClass('focused');

    let $focused_container;
    if ($this.hasClass('check-in-container')) {
      $focused_container = $('.dates').find('.check-in-container');
    } else if ($this.hasClass('check-out-container')) {
      $focused_container = $('.dates').find('.check-out-container');
    } else {
      $focused_container = $this.parents('.input');
    }

    $focused_container.addClass('focused');

    /* add or removes fake-click if destination selector was clicked or not */
    if (!$this.hasClass('place-to-go')) {
      createFakeDestinationClick();
    } else {
      $('.fake-select-click').remove();
    }
    if (!$this.hasClass('number-of-guest') && $('#people-popover').is(':visible')) closePopover($this, true);
  }
}

/**
 * 11. check if airport input is on focus (destinia)
 */

function checkFocusedElementDestinia(_this) {
  let $this = _this;
  $('.fastbooking').addClass('focused');
  /* if its the simple type of fastbooking it doesnt add anything */
  if (!$('.fastbooking').hasClass('simple')) {
    /* removes class focus from the other fields and adds it to the clicked one */
    focusoutChosen();

    let $focused_container;

    $focused_container = $this.parents('.input');

    $focused_container.addClass('focused');

    /* add or removes fake-click if destination selector was clicked or not */
    if (!$this.hasClass('js-place-origin')) {
      createFakeDestiniaClick();
    } else {
      $('.fake-select-click-destinia').remove();
    }
  }
}

$(document)
  .on('click', '.fake-select-click', function (evt) {
    evt.preventDefault();
    //Abro la caja que antiguamente era un chosen y ahora es el place_to_go_box
    openPlaceToGoBox(evt);

    // Establecemos el texto del mensaje que aparece cuando uno de los huespedes es un niño
    $fastbooking.find('.fastbooking-adults').html($fastbooking.find('.fastbooking-adults-text'));
  })
  .on('click', '.label-mobile-place-to-go', function (evt) {
    evt.preventDefault();
    openPlaceToGoBox(evt);
  })
  .on('click', '.people-restrictions-alert .close-alert', function () {
    $(this).closest('.people-restrictions-alert').addClass('hidden');
  })
  .on('click', '.fastbooking-wrapper .focus[data-focus-bg]', function () {
    /* removes the focus div and the focused style from all fields */
    let emptySelects = false;
    document.querySelectorAll('select.age').forEach(select => {
      emptySelects = select.selectedIndex === 0;
    });

    if (!emptySelects) {
      $(this).remove();
      createFakeDestinationClick();
      if ($('#people-popover').is(':visible')) closePopover($('#people-popover'), true);
      focusoutChosen();
      $('.input:not(.submit), .dates, .dates .check-in-container, .dates .check-out-container').removeClass('focused');
    }

    changeDiscountArrow();
  })
  .on('click', '.fastbooking-wrapper .dates, .fastbooking .number-of-guest', function () {
    let scroll_bottom = $(window).height() + $(window).scrollTop();
    let is_guests = $(this).hasClass('number-of-guest');
    let $el = is_guests ? $('#people-popover') : $(this);
    let element_bottom = $el.offset().top + $el.outerHeight() + $('.fb-datepicker .footer').outerHeight();
    if (!is_guests) element_bottom += $('.month-wrapper').outerHeight() + $('.dates-info').outerHeight();
    if (scroll_bottom < element_bottom) {
      $('html,body').animate(
        {
          scrollTop: $(window).scrollTop() + element_bottom - scroll_bottom,
        },
        500
      );
    }
  })
  .on('change', '.check-out', function (evt) {
    const separator = evt.target.closest('.input').querySelector('.date-separator')
    const noDate = evt.target.closest('.input').querySelector('input.check-in')

    if (this.value === '') {
      noDate.classList.add('long-text');
      if (!separator.classList.contains('hidden')) {
        separator.classList.add('hidden');
      }
    } else {
      separator.classList.remove('hidden');
      noDate.classList.remove('long-text');
    }
  })

  /**
   * 12. click events on airports inputs and open dropdowns (destinia)
   */
  .on('click', '.js-label-origin-mobile', function (evt) {
    evt.preventDefault();
    openFastbookingDepartureAirport(evt);
  })

  .on('click', '.fake-select-click-destinia', function (e) {
    e.preventDefault();
    openFastbookingDepartureAirport(e);
  })
  .ready(function () {
    let fastbooking_klass = IB.currentDevice === 'mobile' ? '.mobile-fastbooking-cnt' : '.fastbooking';
    createFakeDestinationClick();
    createFakeDestiniaClick();

    $(fastbooking_klass + ' .discount-checks .discount[data-discount=my-IB] .info').addClass('cro-my-ibe');
    checkAnimation();
  });

function focusoutChosen() {
  $('.input:not(.submit), .dates').removeClass('focused');
  if (!$('.place-to-go').hasClass('focused')) return false;
  if (!$('.js-place-origin').hasClass('focused')) return false;
  let $place_to_go = IB.currentDevice === 'mobile' ? $('.label-mobile-place-to-go') : $('.place-to-go');
  let $place_origin = IB.currentDevice === 'mobile' ? $('.js-label-origin-mobile') : $('.js-place-origin');
  // La comprobación de que anteriormente ya hemos seleccionado un valor
  // Entonces anulamos todo
  if ($place_to_go.find('select').val().length) return false;
  if ($place_origin.find('select').val().length) return false;
  // Metemos en otro hilo de ejecución ya que si hacemos click o intro para seleccionar un valor
  // No le da tiempo de pillar el value del lo que hemos seleccionado
  // Necesitamos este value para saber que ya lo hemos seleccionado y no setearlo
  if (
    $(option_active_result).length === 1 &&
    $(option_active_result).data('id') !== undefined &&
    $(option_active_result).data('id') !== 'undefined' &&
    $place_to_go.find('select').val().length === 0
  ) {
    setPlaceToGo($place_to_go.find('select'), $(option_active_result).data('id'));
  }
  /**
   * 12. setting destinia values (destinia)
   */
  if (
    $(option_active_result).length === 1 &&
    $(option_active_result).data('name') !== undefined &&
    $(option_active_result).data('name') !== 'undefined' &&
    $place_origin.find('select').val().length === 0
  ) {
    setFastbookingDepartureAirport($place_origin.find('select'), $(option_active_result).data('name'));
  }
}

/* FOCUS ON FASTBOOKING END */

/**
 * Check if the current state meets certain conditions
 * @param {string|null} promoError - Error container node
 * @returns {boolean} - If it meets the conditions defined in the conditional, it returns false.
 */
function checkConditions(promoError) {
  // [PY04777-447][feature]
  // If the promotional code is incorrect, allow FB's natural behaviour, unless the following requirements are met:
  if(promoError !== null && IB.fastbooking_state.getDestinationType() === 'hotel' && IB.fastbooking_state.getState().dates !== undefined) return false
  return true
}

$doc
  .on('click', '.fastbooking .js-book-now-btn, .mobile-fastbooking-cnt .actions .js-book-now-btn', function (e) {
    const promoError = document.querySelector('.input.promo.error');

    // Apply validations for CP
    if(checkConditions(promoError)) {
      let day = dayjs().format('DD-MM-YYYY');
      let select = IB.currentDevice === 'mobile' ? $('.mobile-fastbooking-cnt .label-mobile-place-to-go select.chosen-select').val() : $('.fastbooking select.chosen-select').val();
      let selectOrigin = IB.currentDevice === 'mobile' ? $('.mobile-fastbooking-cnt .js-airport').val() : $('.fastbooking .js-airport').val();
      let checkIn = IB.currentDevice === 'mobile' ? $('.mobile-fastbooking-cnt .check-in').val() : $('.fastbooking .check-in').val();
      let checkOut = IB.currentDevice === 'mobile' ? $('.mobile-fastbooking-cnt .check-out').val() : $('.fastbooking .check-out').val();

      if (hotelTabIsActive) {
        if (select === '' || select === null) {
          e.preventDefault();
          let $select = $('.fake-select');
          let $chosen_selectors = $fastbooking.find('.chosen-select');
          const $selectDestinationAlert = $fastbooking.find('.fastbooking-placetogo');
          if (IB.currentDevice !== 'mobile') {
            if (!$('.fastbooking .chosen-container').length) {
              FastbookingSelect.init($chosen_selectors, function () {
                initSelect($chosen_selectors, $select);
              });
            }
            $('.fastbooking .fake-select, .fastbooking .fake-select-click').trigger('click');
            $('.fastbooking .chosen-container .chosen-drop .select-place-to-go').show();
            $('.fastbooking .chosen-container .chosen-results .group-option.featured').show();
            openPlaceToGoBox();
          } else {
            $('.mobile-fastbooking-cnt .chosen-container .chosen-drop .select-place-to-go').show();
            $('.mobile-fastbooking-cnt .chosen-container .chosen-drop .familiar-hotels').hide();
            if (!select_initialized) {
              initSelect($chosen_selectors, $select);
              let $selector = $fastbooking.find('select.chosen-select');
              FastbookingSelect.init($selector, function () {
                setPlaceToGo($selector, $selector.val());
              });
            }
            openPlaceToGoBox();
            $('.mobile-fastbooking-cnt .chosen-container .chosen-results .group-option.featured').show();
          }

          $fastbooking.find('.select-place-to-go').html($selectDestinationAlert);
          // Set keep opened to avoid destination selector close on current click (because it's a click outside chosen selector)
          $chosen_selectors.data('keep-opened-once', true);
        } else if ($('.ages .parsley-required').length === 0) {
          openLoader()
        }
      } else if (airportTabIsActive) {
        if (selectOrigin === '' || selectOrigin === null) {
          e.preventDefault();
          let $select = $('.fake-select-destinia');
          let $chosen_selectors = $fastbooking.find('.js-destinia-select');
          const $selectDestinationAlert = $fastbooking.find('.fastbooking-placetogo');
          if (IB.currentDevice !== 'mobile') {
            if (!$('.fastbooking .js-container-destinia').length) {
              initSelect($chosen_selectors, $select);
            }
            $('.fastbooking .fake-select-destinia, .fastbooking .fake-select-click-destinia').trigger('click');
            $('.fastbooking .js-destinia-select .chosen-drop-destinia .select-place-to-go-destinia').show();
            $('.fastbooking .js-destinia-select .chosen-results-destinia .group-option-destinia.featured').show();
            openFastbookingDepartureAirport();
          } else {
            $('.mobile-fastbooking-cnt .chosen-container-destinia .chosen-drop-destinia .select-place-to-go-destinia').show();
            if (!select_initialized) {
              initSelect($chosen_selectors, $select);
              let $selector = $fastbooking.find('.js-destinia-select');
              setFastbookingDepartureAirport($selector, $selector.value);
            }
            openFastbookingDepartureAirport();
            $('.mobile-fastbooking-cnt .chosen-container-destinia .chosen-results-destinia .group-option-destinia.featured').show();
          }
          $fastbooking.find('.select-place-to-go-destinia').html($selectDestinationAlert);

          // Set keep opened to avoid destination selector close on current click (because it's a click outside chosen selector)
          $chosen_selectors.data('keep-opened-once', true);
        } else if (select === '' || select === null) {
          e.preventDefault();
          let $select = $('.fake-select');
          let $chosen_selectors = $fastbooking.find('.chosen-select');
          if (IB.currentDevice !== 'mobile') {
            if (!$('.fastbooking .chosen-container').length) {
              FastbookingSelect.init($chosen_selectors, function () {
                initSelect($chosen_selectors, $select);
              });
            }
            $('.fastbooking .fake-select, .fastbooking .fake-select-click').trigger('click');
            $('.fastbooking .chosen-container .chosen-results .group-option.featured').show();
            openPlaceToGoBox();
          } else {
            $('.mobile-fastbooking-cnt .chosen-container .chosen-drop .select-place-to-go').show();
            $('.mobile-fastbooking-cnt .chosen-container .chosen-drop .familiar-hotels').hide();
            if (!select_initialized) {
              initSelect($chosen_selectors, $select);
              let $selector = $fastbooking.find('select.chosen-select');
              FastbookingSelect.init($selector, function () {
                setPlaceToGo($selector, $selector.val());
              });
            }
            openPlaceToGoBox();
            $('.mobile-fastbooking-cnt .chosen-container .chosen-results .group-option.featured').show();
          }
          // Set keep opened to avoid destination selector close on current click (because it's a click outside chosen selector)
          $chosen_selectors.data('keep-opened-once', true);
        } else if ((checkIn === '' || checkIn === null) && (checkOut === '' || checkOut === null)) {
          e.preventDefault();
          const $datepicker = $fastbooking.find('.js-dates');
          if ($datepicker.length) {
            FastbookingDateRangePicker.activate($fastbooking, $datepicker, true);
            $datepicker.data().dateRangePicker.open();
          }
        } else if ($('.ages .parsley-required').length === 0) {
          openLoader()
        }
      }
    } else {
      e.preventDefault();
      const discountBlock = document.querySelector(".input.discounts");
      const discountPopover = document.querySelector(".js-discounts-popover.discounts-popover");
      if(discountBlock && discountPopover){
        discountBlock.classList.add("active", "opened-popup");
        discountPopover.style.display = 'block';
      }
    }
  })
  .on('click', '.fastbooking .wrapper .container .place-to-go, .mobile-fastbooking-cnt .label-mobile-place-to-go', function (e) {
    $('.fastbooking .select-place-to-go').hide();
    $('.mobile-fastbooking-cnt .label-mobile-place-to-go .chosen-container .chosen-drop .select-place-to-go').hide();
    $('.mobile-fastbooking-cnt .chosen-container .chosen-results .group-option.featured').hide();
    $('.fastbooking .chosen-container .chosen-results .group-option.featured').show();
    if (document.documentElement.dataset.inMicrosite === 'ibercuba') {
      try {
        let experienceCuba = document.querySelector('.chosen-results--specialties');
        experienceCuba.classList.add('no-specialties');
      } catch (error) {}
    }
  })
  .on('touchstart', '.mobile-fastbooking-cnt .chosen-search input', function () {
    $('.mobile-fastbooking-cnt .label-mobile-place-to-go .chosen-container .chosen-drop .select-place-to-go').hide();
    $('.mobile-fastbooking-cnt .chosen-container .chosen-results .group-option.featured').hide();
  })
  .on('touchstart', '.my-iberostar-info .my-link.fake-link', function () {
    if (IB.currentDevice === 'tablet') $(this).trigger('click');
  })
  .on('click', '.my-iberostar-info .my-link.fake-link', function () {
    if ($(this).data('is-in-iberostarpro')) window.location.href = $(this).attr('href');
  })

  /**
   * 13. on click we have the airports results dropdown, mobile and desktop (destinia)
   */
  .on('click', '.fastbooking .wrapper .container .js-place-origin, .mobile-fastbooking-cnt .js-label-origin-mobile', function (e) {
    let chosenDrop = document.querySelector('.chosen-drop-destinia');
    chosenDrop.style.opacity = '1';

    $('.fastbooking .select-place-to-go-destinia').hide();
    $('.mobile-fastbooking-cnt .js-label-origin-mobile .js-container-destinia .chosen-drop-destinia .select-place-to-go-destinia').hide();
    $('.mobile-fastbooking-cnt .js-container-destinia .js-results-destinia .group-option-destinia.featured').hide();
    $('.fastbooking .js-container-destinia .js-results-destinia').show();
  })

  .on('touchstart', '.mobile-fastbooking-cnt .chosen-search-destinia input', function () {
    $('.mobile-fastbooking-cnt .js-label-origin-mobile .js-container-destinia .chosen-drop-destinia .select-place-to-go-detinia').hide();
    $('.mobile-fastbooking-cnt .js-container-destinia .js-results-destinia .group-option-destinia.featured').hide();
  });

function checkAnimation() {
  let fastbooking_klass = IB.currentDevice === 'mobile' ? '.mobile-fastbooking-cnt' : '.fastbooking';

  if ($(fastbooking_klass + ' .discount-checks .discount[data-discount=my-IB] #mi-iberostar').is(':checked') || $('.discount label.fk-check').hasClass('checked')) {
    $(fastbooking_klass + ' .discount-checks .info.cro-my-ibe').removeClass('animation');
  } else {
    $(fastbooking_klass + ' .discount-checks .info.cro-my-ibe').addClass('animation');
  }
}

/**
 * Open people selector panel
 */
function openOccupancyPanel() {
  if (IB.currentDevice === 'mobile') {
    $('.show-people-panel').trigger('click');
  } else {
    setTimeout(function () {
      Fastbooking.roomsPopoverPosition($('.number-of-guest'));
      $('.fastbooking #people-popover').show();
    }, 0);
  }
}

/**
 * Detect click on dates-alert to show date selector in FB
 */
$doc.on('click', '.js-dates-alert', function (e) {
  let $target = $(e.target);
  if ($target.hasClass('close')) {
    $target.closest('.js-dates-alert').hide();
    return;
  }

  hideDatesAlert();
  setTimeout(function () {
    let $fastbooking = isMobile ? $('.js-mobile-fastbooking-bar-wrapper, .js-fastbooking-mobile') : $('.fastbooking.home');
    let $fake_datepicker = $fastbooking.find('.dates .fake-datepicker');
    if (isMobile) {
      $('.js-mobile-fastbooking-bar-wrapper .js-cta-btn').trigger('click');
    }
    if ($fake_datepicker.length != 0) {
      $fake_datepicker.trigger('click');
    } else {
      $fastbooking.find('.dates').trigger('focus').trigger('click');
    }
  }, 50);
});

/**
 * Hide the dates alert
 */
function hideDatesAlert() {
  let $fastbooking = isMobile ? $('.js-mobile-fastbooking-bar-wrapper, .js-fastbooking-mobile') : $('.fastbooking.home');
  $fastbooking.find('.dates-alert').addClass('hidden');
}

/**
 * Show dates alert if no dates selected in results page
 */
function showDatesAlert() {
  let $fastbooking = isMobile ? $('.js-fastbooking-mobile') : $('.fastbooking.home');
  let is_hotel_page = $('.hotel-page').length;

  // Only show alert in destination, hotel and results page
  if ($('.destination-page').length == 0 && $('.fastbooking-results').length == 0 && !is_hotel_page) {
    return;
  }

  let $check_in = $fastbooking.find('.check-in');
  let $check_out = $fastbooking.find('.check-out');
  if (!$check_in.val() && !$check_out.val()) {
    if (is_hotel_page) {
      $('.room .dates-alert').removeClass('hidden');
      $('.js-fastbooking-mobile .dates-alert').removeClass('hidden');
    } else {
      if (isMobile) {
        $fastbooking.find('.dates-alert').removeClass('hidden');
      }
      observeHotelsList();
    }
  }
}

/**
 * Observe if we've reached hotels list to show dates alert
 */
function observeHotelsList() {
  let $fastbooking = isMobile ? $('.js-mobile-fastbooking-bar-wrapper') : $('.fastbooking.home');

  let observer = new IntersectionObserver(function (entries) {
    entries.forEach(function (entry) {
      let $dates_wrapper = isMobile ? $('.js-fastbooking-mobile') : $('.fastbooking.home');
      let $check_in = $dates_wrapper.find('.check-in');
      let $check_out = $dates_wrapper.find('.check-out');
      if (entry.isIntersecting) {
        if (!$check_in.val() || !$check_out.val()) {
          $fastbooking.find('.dates-alert').removeClass('hidden');
        }
      } else {
        $fastbooking.find('.dates-alert').addClass('hidden');
      }
    });
  });

  $('.js-hotels-list-observable').each(function (i, hotels_list) {
    observer.observe(hotels_list);
  });
}

/**
 * Set cta text
 *
 * @param {string} textType - text type (viewPrices / viewHotels / search)
 */
function setCtaText(textType) {
  let ctaTexts = IB.i18n.ctaFb;
  let ctaText = ctaTexts[textType];

  if (!ctaText) return;

  $fastbooking.find('.js-book-now-btn').text(ctaText).val(ctaText);
}

function changesBeforeHideChosen() {
  // IMPORTANT: this event fires before change and chosen:hiding_dropdown
  let $el = $('.chosen-container');
  let $selectWrapper = isMobile ? $el.closest('.cnt-select') : $el.closest('.input.place-to-go');
  let $place_to_go = IB.currentDevice === 'mobile' ? $('.label-mobile-place-to-go') : $('.place-to-go');
  let $select_place_to_go = $place_to_go !== undefined ? $place_to_go.find('select') : null;
  let selected_param = $select_place_to_go === null ? '' : $select_place_to_go.val();
  occupancyChanged = false;

  // Load package restrictions if hotel is in package
  if (FastbookingPackages.hasPackageLoaded()) {
    if (FastbookingPackages.isHotelInPackage(selected_param)) {
      $selectWrapper.removeClass('has-package-destination-warning');
      // allow destination dropdown hide
      FastbookingDateRangePicker.setPreventDatepickerOpen(false);
    } else {
      $selectWrapper.addClass('has-package-destination-warning');
      // Prevent destination dropdown hide
      $el.trigger('chosen:prevent_dropdown_hide');
      // Prevet datepicker opening if hotel is not included
      FastbookingDateRangePicker.setPreventDatepickerOpen(true);
    }
  }
}

function showingDropdown() {
  if ($fastbooking === undefined) return false;

  let $chosen_selectors = $fastbooking.find('.chosen-select');
  let $select = $('.fake-select');

  if ($initializedSelect === null) {
    FastbookingSelect.init($chosen_selectors, function () {
      initSelect($chosen_selectors, $select);
    });
  }

  let $list_hotel_selector_cnt = $('.list-hotel-selector-cnt');

  if ($fastbooking === undefined) return;

  if ($('body').hasClass('home-page') && IB.currentDevice === 'mobile') {
    bodyScrollAnimationIsFinished = false;

    $('html,body').animate(
      {
        scrollTop: $fastbooking.offset().top - 40,
      },
      500,
      'swing',
      function () {
        IB.resetUserScrollListener();
        // this function is called when animation is completed
        bodyScrollAnimationIsFinished = true;
      }
    );
  }

  if ($fastbooking.find('.input.age').length) {
    $('.familiar-hotels').show();
    $('.chosen-results--hotels .tags .familiar').removeClass('is-hidden');
    $fastbooking.find('.chosen-container .chosen-results').addClass('icon');
    $list_hotel_selector_cnt.addClass('icon');
  } else {
    $('.familiar-hotels').hide();
    $('.chosen-results--hotels .tags .familiar').addClass('is-hidden');
    $fastbooking.find('.chosen-container .chosen-results').removeClass('icon');
    $list_hotel_selector_cnt.removeClass('icon');
  }

  if (IB.currentDevice !== 'mobile') {
    ifElementOnScreen($('.place-to-go').find('.chosen-results'));
  }

  if ($('.last-searches').length) {
    let $chosen_container = $fastbooking.find('.chosen-container');

    $chosen_container.find('.last-search-opt:not(.result-selected)').length ? $chosen_container.addClass('last-exists') : $chosen_container.removeClass('last-exists');
  }
}

/**
 * 14. ckeck if the selected hotel has restrictions and prevent hiding dropdowns (destinia)
 */

function changesBeforeHideChosenDestinia() {
  // IMPORTANT: this event fires before change and chosen:hiding_dropdown
  let $el = $('.js-container-destinia');
  let $selectWrapper = isMobile ? $el.closest('.js-cnt-select-destinia') : $el.closest('.input.js-place-origin');
  let $place_origin = IB.currentDevice === 'mobile' ? $('.js-label-origin-mobile') : $('.js-place-origin');
  let $select_origin = $place_origin !== undefined ? $place_origin.find('select') : null;
  let selected_param = $select_origin === null ? '' : $select_origin.val();

  // Load package restrictions if hotel is in package
  if (FastbookingPackages.hasPackageLoaded()) {
    if (FastbookingPackages.isHotelInPackage(selected_param)) {
      $selectWrapper.removeClass('has-package-destination-warning');
      // allow destination dropdown hide
      FastbookingDateRangePicker.setPreventDatepickerOpen(false);
    } else {
      $selectWrapper.addClass('has-package-destination-warning');
      // Prevent destination dropdown hide
      $el.trigger('chosen:prevent_dropdown_hide');
      // Prevet datepicker opening if hotel is not included
      FastbookingDateRangePicker.setPreventDatepickerOpen(true);
    }
  }
}
/**
 * 15. when showing airports results animation (destinia)
 */
function showingDropdownDestinia() {
  if ($fastbooking === undefined) return false;

  let $destinia_selectors = $fastbooking.find('.js-destinia-select');
  let $select = $('.fake-select-destinia');

  if ($initializedSelect === null) {
    initSelect($destinia_selectors, $select);
  }

  if ($fastbooking === undefined) return;

  if ($('body').hasClass('home-page') && IB.currentDevice === 'mobile') {
    bodyScrollAnimationIsFinished = false;

    $('html,body').animate(
      {
        scrollTop: $fastbooking.offset().top - 40,
      },
      500,
      'swing',
      function () {
        IB.resetUserScrollListener();
        // this function is called when animation is completed
        bodyScrollAnimationIsFinished = true;
      }
    );
  }

  if (IB.currentDevice !== 'mobile') {
    ifElementOnScreen($('.js-place-origin').find('.js-results-destinia'));
  }
}

function setauto_offer_search_ready(value) {
  auto_offer_search_ready = value;
}

/**
 * Updates total discounts applied checking promocode, resident discount
 * checks and my iberostar discount check
 */
function updateDiscountCounter() {
  const discountContainer = document.querySelector('.opened-popup');
  const discountCounterNode = document.querySelector('.js-discount-count');
  const activeDiscounts = document.querySelectorAll('.discount-checks .discount:not(.hidden).active').length;
  const validPromocode = document.querySelector('.promo.input.promo-active');

  discountCounter = activeDiscounts + !!validPromocode;

  if (discountCounterNode) discountCounterNode.textContent = '(' + discountCounter + ')';

  const placeholder = document.querySelector('.js-no-discount');
  const residentText = document.querySelector('.js-resident-text')
  const horizonsText = document.querySelector('.js-horizons-text')
  const promocodeText = document.querySelector('.js-promocode-text')

  setPromoCodeDataset()
  if(promocodeText && promocodeText.innerHTML){
    placeholder?.classList.add('hidden');
    residentText?.classList.add('hidden')
    horizonsText?.classList.add('hidden')
  } else{
    if(activeDiscounts){
      const residentCheck = document.querySelector('.discount-checks .discount:not(.hidden).active input.resident')

      if(residentCheck && residentCheck.checked){
        residentText?.classList.remove('hidden')
        residentText?.classList.add('active')
        residentCheck.closest(".fk-check")?.classList.add('checked')


      }else{
        residentText?.classList.add('hidden')
      }

      const horizonsCheck = document.querySelector('.discount-checks .discount:not(.hidden).active #mi-iberostar')

      if(horizonsCheck && horizonsCheck.checked){
        horizonsText?.classList.remove('hidden')
        horizonsText?.classList.add('active')
        horizonsCheck.closest(".fk-check")?.classList.add('checked')
      }else{
        horizonsText?.classList.add('hidden')
      }

      placeholder?.classList.add('hidden');
    }else{
      placeholder?.classList.remove('hidden');
      residentText?.classList.add('hidden')
      horizonsText?.classList.add('hidden')
    }
  }

  if (IB.currentDevice !== 'mobile' && discountContainer) {
    if (discountCounter) {
      discountCounterNode?.classList.remove('hidden');
      discountContainer.classList.add('has-promo-activated');
    } else {
      discountCounterNode?.classList.add('hidden');
      discountContainer?.classList.remove('has-promo-activated');
    }
  }
}

/* set dataset of promocode for utag */
function setPromoCodeDataset () {
  const residentText = document.querySelector('.js-resident-text')
  const promocodeInput = currentDevice.isMobile ? document.querySelector('.js-promo-code') : document.querySelector('.js-promocode-input')
  const fkCheckResident = document.querySelector('.fk-check.resident')

  if (!fkCheckResident) return

  const residentCheck = fkCheckResident.classList.contains('checked')

  if (currentDevice.isMobile) {
    if (residentCheck && promocodeInput.value === '') {
      promocodeInput.dataset.valueInput = 'Resident'
    } else if (residentCheck && promocodeInput.value !== '') {
      promocodeInput.dataset.valueInput = `Resident` + `-${promocodeInput.value}`
    } else if (!residentCheck && promocodeInput.value !== '') {
      promocodeInput.dataset.valueInput = `${promocodeInput.value}`
    } else {
      promocodeInput.dataset.valueInput = ''
    }
  } else {
    const promoCode= document.querySelector('.js-promo-code');
    if (promoCode.value !== '' && residentCheck) {
      promocodeInput.dataset.valueInput = `${residentText.innerHTML}-${promoCode.value}`
    } else if (residentCheck && promoCode.value === '') {
      promocodeInput.dataset.valueInput = `${residentText.innerHTML}`

    } else if (!residentCheck && promoCode.value !== ''){
      promocodeInput.dataset.valueInput = promoCode.value
    }
    else {
      promocodeInput.dataset.valueInput = ''
    }
  }
}

function closeDatepicker() {
  const $datepicker = $fastbooking.find('.dates');

  if (!$fastbooking.length) return;

  $datepicker.data().dateRangePicker.close();
}

const Fastbooking = {
  init: init,
  initSelect: initSelect,
  setFastbookingType: setFastbookingType,
  setRooms: setRooms,
  setRoomsData: setRoomsData,
  setRoomsSelectorLimit: setRoomsSelectorLimit,
  setRoomsSelectorValue: setRoomsSelectorValue,
  setRoomCode: setRoomCode,
  setNumberOfRooms: setNumberOfRooms,
  setDestination: setDestination,
  setPromoCode: setPromoCode,
  addRoom: addRoom,
  dateInRange: dateInRange,
  ifElementOnScreen: ifElementOnScreen,
  roomsPopoverPosition: roomsPopoverPosition,
  closePopover: closePopover,
  loadPromoCode: loadPromoCode,
  loadPromoCodeSelector: loadPromoCodeSelector,
  getPromoField: getPromoField,
  getDestination: getDestination,
  getSelectedPlace: getSelectedPlace,
  createFakeDestinationClick: createFakeDestinationClick,
  createFakeDestiniaClick: createFakeDestiniaClick,
  hideShowButton: hideShowButton,
  activeClearButton: activeClearButton,
  activeClearButtonDestinia: activeClearButtonDestinia,
  openOccupancyPanel: openOccupancyPanel,
  checkAnimation: checkAnimation,
  loadLastSearch: loadLastSearch,
  checkFastbookingSticky: checkFastbookingSticky,
  hideDatesAlert: hideDatesAlert,
  showDatesAlert: showDatesAlert,
  setCtaText: setCtaText,
  switchClearButtonPlaceToGo: switchClearButtonPlaceToGo,
  switchClearButtonDestinia: switchClearButtonDestinia,
  showingDropdown: showingDropdown,
  showingDropdownDestinia: showingDropdownDestinia,
  changesBeforeHideChosen: changesBeforeHideChosen,
  changesBeforeHideChosenDestinia: changesBeforeHideChosenDestinia,
  childrenControl: ChildrenControl,
  packages: FastbookingPackages,
  rangedatepicker: FastbookingDateRangePicker,
  select: FastbookingSelect,
  sticky: FastbookingSticky,
  discount_resident: FastbookingDiscountResident,
  discount_myiberostar: FastbookingDiscountMyIberostar,
  discount_my_IB: FastbookingDiscountMyIB,
  voice_search: FastbookingVoiceSearch,
  voice_search_destinia: FastbookingVoiceSearch,
  checkFocusedElement: checkFocusedElement,
  setauto_offer_search_ready: setauto_offer_search_ready,
  updateDiscountCounter,
  closeDatepicker,
};

document.addEventListener('DOMContentLoaded', async () => {
  let $fastbooking = isMobile ? $('.js-fastbooking-mobile') : $('.fastbooking.home');
  if (IB.currentDevice === 'mobile') {
    // both fastbooking and rangedatepicker initialize
    // upon .open-mobile-panel click
    // see ready.js
    $('body').attr('data-ea-fastbooking', 'Fastbooking | Mobile');

    Fastbooking.init($fastbooking);
    FastbookingDateRangePicker.init($fastbooking);
    Fastbooking.setFastbookingType();
    let $selector = $fastbooking.find('select.chosen-select');
    let $selectorDestinia = $fastbooking.find('input.js-destinia-select');
    $fastbooking.find('.fake-select').on('click', function (e) {
      FastbookingSelect.init($selector, function () {
        setPlaceToGo($selector, $selector.val());
        //$selector.trigger('chosen:updated');
      });
    });
    $fastbooking.find('.fake-select-destinia').on('click', function (e) {
      FastbookingSelect.init($selectorDestinia, function () {
        setFastbookingDepartureAirport($selectorDestinia, $selectorDestinia.val());
        //$selector.trigger('chosen:updated');
      });
    });
  } else {
    // Remove additional fastbookings when admin_browsing=true
    if($fastbooking.length > 1){
      $fastbooking.each(function (index){
        if(index > 0){
          $(this).closest('.fastbooking-wrapper').remove()
        }
      })
    }

    Fastbooking.init($fastbooking);
    FastbookingDateRangePicker.init($fastbooking);
  }

  if ($.cookie('fastbooking_is_search') !== undefined && $.cookie('fastbooking_is_search') !== null) {
    $('.fastbooking').addClass('focused');
    setCookie('fastbooking_is_search', false, '-1');
  }

  // Actions before submit
  $(document).on('submit', '.fastbooking, .mobile-fastbooking-form', function (e) {


    // If package and restrictions loaded and occupancy is not valid, prevent submit
    if (FastbookingPackages.hasRestrictionsLoaded() && !FastbookingPackages.isOccupancyValid()) {
      e.preventDefault();
      Fastbooking.openOccupancyPanel();
      return;
    }

    let $form = $(e.target);

    // If fastbooking is submited without dates, clear promo-code input
    if (!IB.fastbooking_state.getField('dates') && document.querySelector('.promo-code').value) {
      document.querySelector('.promo-code').value = '';
      IB.fastbooking_state.setState();
    }

    // If package and restrictions load, and fastbooking selection valir, append input hidden with crs url
    if (FastbookingPackages.hasRestrictionsLoaded() && FastbookingPackages.isFastbookingSelectionValid()) {
      let hotelCrsUrl = FastbookingPackages.getHotelCrsUrl($form.find('.chosen-select').val());
      if (hotelCrsUrl) {
        $('<input/>')
          .attr({
            id: 'vo_booking_package_crs_url',
            type: 'hidden',
            name: 'vo_booking[package_crs_url]',
            value: hotelCrsUrl,
          })
          .appendTo($form);
      }
    }

    const submitButton = document.querySelector('.js-book-now-btn')
    if (airportTabIsActive && !submitButton.classList.contains('modal-shown')) {
      e.preventDefault()
      closeLoader()

      showWarningModal(
        () => {
          submitButton.classList.add('modal-shown')
          submitButton.click()
          submitButton.classList.remove('modal-shown')
        }
      )
    }
  });

  if (!$('#availabity-dates').length) {
    $('a[href$="#availabity-dates"]').parent('li').remove();
    if (!$('.js-near-hotels').length) {
      $('.best-rate').addClass('more-bottom');
    }
  }

  if ($('html').hasClass('grand-collection')) {
    let $best_img = $('.best-rate').find('img');
    let grand_best = $best_img.attr('data-grand');
    $best_img.attr('src', grand_best);
  }

  let data = $fastbooking.data('room-info') || IB.fastbooking_state.getField('rooms_info');
  let $place_to_go = $fastbooking.find('.chosen-select');
  let $place_origin = $fastbooking.find('.js-destinia-select');
  let data_selected = $fastbooking.find('.chosen-select').data('selected');
  let $origin_data_selected = $fastbooking.find('.js-airport');
  let $fake_select = $fastbooking.find('.fake-select');
  let $fake_select_destinia = $fastbooking.find('.fake-select-destinia');

  if (data_selected !== null && data_selected !== undefined && $fake_select.length) {
    FastbookingSelect.init($place_to_go, function () {
      FastbookingDiscountResident.checkDiscount(Fastbooking.getSelectedPlace(data_selected));
      Fastbooking.setFastbookingType();
      ChildrenControl.setMaxAdultsAndChildren($place_to_go.find('option:selected'));
      FastbookingDiscountMyIB.checkDiscount(Fastbooking.getSelectedPlace(data_selected));
      $('.js-mobile-fastbooking-bar').find('.open-mobile-panel').addClass('select-initialized');
      Fastbooking.activeClearButton(true);

      Fastbooking.initSelect($place_to_go, $fastbooking.find('.fake-select'));
    });
  } else if ($staticHotel.length) {
    // The destination selector its not a select tag, its an input hidden/text instead
    const staticHotelId = $staticHotel.val();

    if (!HotelsData.isHotelsDataAvailable()) {
      await HotelsData.asyncGetData();
    }

    FastbookingDiscountResident.checkDiscount(Fastbooking.getSelectedPlace(staticHotelId));
    Fastbooking.setFastbookingType();
    ChildrenControl.setMaxAdultsAndChildren($staticHotel);
    FastbookingDiscountMyIB.checkDiscount(Fastbooking.getSelectedPlace(staticHotelId));
    $('.js-mobile-fastbooking-bar').find('.open-mobile-panel').addClass('select-initialized');
    Fastbooking.activeClearButton(true);
  }

  if ($origin_data_selected !== null && $origin_data_selected !== undefined && $fake_select_destinia.length) {
    FastbookingSelect.init($place_origin, function () {
      $('.js-mobile-fastbooking-bar').find('.open-mobile-panel').addClass('select-initialized');
      Fastbooking.activeClearButtonDestinia(true);
      Fastbooking.initSelect($place_origin, $fastbooking.find('.fake-select-destinia'));
    });
  } else {
    $('.js-mobile-fastbooking-bar').find('.open-mobile-panel').addClass('select-initialized');
    Fastbooking.activeClearButtonDestinia(true);
  }

  let pax_config = IB.urlParser.getParam('pax_config');
  let data_url = IB.urlParser.getParam('room_info');
  try {
    if (data_url) {
      data = JSON.parse(data_url);
    } else if (pax_config) {
      // Ej: pax_config=adultos#niños-edad1.edad2_adultos+niños
      let rooms_config = pax_config.split('_');
      data = {};
      for (let i = 0; i < rooms_config.length; i++) {
        let ages = rooms_config[i].split('-')[1];
        if (ages) {
          ages = ages.split('.');
        } else {
          ages = [];
        }
        data[i] = { adults_count: rooms_config[i].split('p')[0], children_count: rooms_config[i].split('p')[1].split('-')[0], ages: ages };
      }
    } else if (!window.location.href.match('(\/reservas|\/bookings)\/') && !IB.fastbooking_state.isSharedSearch()) {
      data = null;
    }
  } catch (e) {
    console.log('Error parsing PAX to JSON');
  }

  if (data === '' || data === undefined || data === null) return false;
  Fastbooking.setRoomsData(data);
});

$(document)
  .on('click', '.mobile-fastbooking-cnt .label-mobile-place-to-go', function () {
    let $input = $('.mobile-fastbooking-cnt .mobile-fastbooking-form .label-mobile-place-to-go');
    let $chosen_results = $('.mobile-fastbooking-cnt .mobile-fastbooking-form .label-mobile-place-to-go .chosen-container .chosen-results');
    $chosen_results.scroll(function () {
      $('.familiar-hotels').hide();
    });
    $input.on('change keyup paste', function () {
      $('.familiar-hotels').hide();
    });
  })
  .on('click', '.fastbooking-mobile-apply-dates a', function (e) {
    e.preventDefault();
    $('.date-picker-wrapper').find('.return-btn').trigger('click');
    $('.mobile-fastbooking-bg').remove();
    $('body').attr('style', '');
  })
  .on('click', '.fastbooking-mobile-apply-people', function (e) {
    e.preventDefault();

    $('#people-mobile-panel').find('.return-btn').trigger('click');
  })
  .on('click', '.show-people-panel', function () {
    let $this = $(this);
    let $body = $('body');
    let $form = $this.closest('form');
    let $rooms_container = $form.find('.rooms-container');
    if (FastbookingPackages.hasRestrictionsLoaded()) {
      FastbookingPackages.manageOccupancyLimits();
    }

    if (!$('.mobile-fastbooking-bg').length) {
      $body.addClass('mobile-fastbooking-opened');
      $body.append('<div class="mobile-fastbooking-bg"></div>');
    }

    $('#people-mobile-panel').append($rooms_container);
    $('.fastbooking-mobile-apply-people').addClass('active');

    isFhiPage && ChildrenControl.setMaxAdultsAndChildren($('.js-fhi-hotel-code'));
  })
  .on('click', '#people-mobile-panel .apply-btn, #people-mobile-panel .return-btn', function (e) {
    let $this_fastbooking = $('#fastbooking-mobile');
    let $people_mobile_panel = $('#people-mobile-panel');
    let $select_age = $people_mobile_panel.find('select.age');
    let valid_age = true;

    $select_age.each(function (index, element) {
      valid_age = !!($(element).parsley().validate() === true && valid_age);
    });

    if ($select_age.length && !valid_age) {
      e.preventDefault(), e.stopImmediatePropagation();
      $people_mobile_panel.scrollTop($people_mobile_panel.find('.parsley-error').first().offset().top);
    } else {
      $this_fastbooking.find('.rooms-hidden-container').append($('#people-mobile-panel').find('.rooms-container'));
      $('.fastbooking-mobile-apply-people').removeClass('active');
    }

    // Hide package warning telling user that occupancy has been changed
    if (FastbookingPackages.hasPackageLoaded()) {
      FastbookingPackages.togglePackageOccupancyModifiedWarning(false);
    }

    IB.fastbooking_state.setState();
  })
  .on('click', '.js-toggle-fhi-desktop-fb', function (e) {
    e.preventDefault();
    const fastbookingSticky = document.querySelector('.fhi-fastbooking-sticky');
    fastbookingSticky.classList.remove('active');
  });

if (isMobile) {
  document.addEventListener(
    'scroll',
    _debounce(() => {
      if (!bodyScrollAnimationIsFinished) return;

      let $destinationInput = document.querySelector('.mobile-fastbooking-cnt .chosen-search input');
      let $originDestiniaInput = document.querySelector('.mobile-fastbooking-cnt .chosen-search-destinia input');

      if (!$destinationInput) return;
      if (!$originDestiniaInput) return;

      let $destinationSelect = $destinationInput.closest('.cnt-select');
      let $orginDestiniaSelect = $originDestiniaInput.closest('.js-cnt-select-destinia');

      if (!isFocused($destinationInput)) return;
      if (!isFocused($originDestiniaInput)) return;

      inViewport(
        $destinationSelect,
        element => {
          if (!element.isIntersecting && sessionStorage.getItem('userHasScrolled') === 'true') {
            $destinationInput.blur();
          }
        },
        { threshold: 1 }
      );

      inViewport(
        $orginDestiniaSelect,
        element => {
          if (!element.isIntersecting && sessionStorage.getItem('userHasScrolled') === 'true') {
            $originDestiniaInput.blur();
          }
        },
        { threshold: 1 }
      );
    }, 100)
  );
}

//----------------------------------------------------------------//

window.addEventListener('load', function () {
  /**
   * 16. Check if we have tab airport + hotel
   * if we have, check the market and depend of the market the tab will be redirect to the correct url
   * or show the other fastbooking with the correct provider data
   */
  flightPlusHotelDetection()
    .then(val => {
      const filterTabsWrappers = Array.from(document.querySelectorAll('.js-fastbooking-tabs'));

      filterTabsWrappers?.forEach(filterTabsWrapper => {
        if (val !== undefined && val !== null && val.status == '200' && filterTabsWrapper) {
          const filterTabs = filterTabsWrapper?.querySelectorAll('.js-content-tab');

          // el html que hay en val.html es el bóton de la tab
          filterTabsWrapper.innerHTML = filterTabsWrapper.innerHTML + val.html;
          val.provider == 'Destinia' ? filterTabsWrapper.setAttribute('data-fastbooking-type', 'destinia') : filterTabsWrapper.setAttribute('data-fastbooking-type', 'main');
          filterTabsWrapper && filterTabsWrapper.classList.remove('is-hidden');

          filterTabs &&
            filterTabs.forEach(tab => {
              tab.classList.remove('is-hidden');
            });

          let $e_filter_tabs = document.querySelector('.e-filter-tabs');
          if ($e_filter_tabs !== null) $e_filter_tabs.classList.remove('is-hidden');

          const fastbookingTabs = filterTabsWrapper.querySelectorAll('.js-fastbooking-tabs .js-tab');
          fastbookingTabs.forEach(tab => {
            addClickEventListenerToTab(tab)
          });

          document.dispatchEvent(new Event(FASTBOOKING_FLIGHT_TAB_CREATED_EVENT))
          initializeFastbookingTabUtagEvent()
        }
      });
    })
    .catch(error => {
      console.error('Error in flightPlusHotelDetection', error);
    });
});

/* eslint-enable */
function setActiveFastbookingTab(tab) {
  const fastbookingTabs = tab.closest('.js-fastbooking-tabs').querySelectorAll('.js-tab')
  fastbookingTabs.forEach(t => {
    t.classList.remove('active')
  })
  tab.classList.add('active')
}

function checkUserDestinationsVisibility(show) {
  const panels = document.querySelectorAll('#place-to-go-box [data-fastbooking-type=flight]')

  if (show) {
    panels.forEach(panel => {
      panel.classList.remove('is-hidden')
    })
  } else {
    panels.forEach(panel => {
      if (!panel.classList.contains('second-column')) panel.classList.add('is-hidden')
    })
  }
}

function addClickEventListenerToTab(tab) {
  const origin = document.querySelector('.js-place-origin')
  const originMobile = document.querySelector('.js-place-origin-mobile')
  const discount = document.querySelector('.discounts-container-open-popup')
  const discountMobile = document.querySelector('.js-promo-destinia')
  const discountCheck = document.querySelector('.discount-checks')
  const alertDiscount = document.querySelector('.dates-alert')
  const selectDestinia = document.querySelector('#desktop-place-to-go')
  const chosenDrop = document.querySelector('.chosen-drop')
  const chosenResults = document.querySelector('.chosen-results')
  const groupOption = document.querySelectorAll('.group-option')

  tab.addEventListener('click', function () {
    setActiveFastbookingTab(tab)

    switch (tab.dataset.type) {
      case 'hotel':
        FastbookingDateRangePicker.removeDates($fastbooking)
        hotelTabIsActive = true
        airportTabIsActive = false
        if (isMobile) originMobile.classList.add('hidden')
        else {
          if (!isFhiPage) {
            chosenDrop.classList.remove('on-destinia')
            chosenResults.classList.remove('on-destinia')
          }
          selectDestinia.classList.remove('on-origin')
          origin.classList.add('hidden')
          discount?.classList.remove('is-hidden')
          checkUserDestinationsVisibility(true)
        }
        document.querySelector('.js-fastbooking-container').classList.remove('is-in-flight-plus-hotel-tab')
        groupOption.forEach(group => {
          group.classList.remove('destinia-hidden')
        })

        break
      case 'flight':
        hotelTabIsActive = false
        airportTabIsActive = true
        initializeVueFastbookingDepartureAirport()
        resetPlaceToGoBox()
        FastbookingDateRangePicker.removeDates($fastbooking)
        checkUserDestinationsVisibility(false)

        if (isMobile) {
          originMobile.classList.remove('hidden')
          discountMobile.classList.add('is-hidden')
          discountCheck.classList.add('is-hidden')
          alertDiscount.classList.add('is-hidden')
        } else {
          origin.classList.remove('hidden')
          discount?.classList.add('is-hidden')
          if (!isFhiPage) {
            chosenDrop.classList.add('on-destinia')
            chosenResults.classList.add('on-destinia')
          }
          selectDestinia.classList.add('on-origin')
          document.querySelector('.js-fastbooking-container').classList.add('is-in-flight-plus-hotel-tab')
          groupOption.forEach(group => {
            if (group.dataset.isDestinia === 'false') {
              group.classList.add('destinia-hidden')
            }
          })
        }
        break
    }
  })
}

if (typeof IB === 'undefined') {
  window.IB = {}
}
window.IB.fastbooking = Fastbooking

export default Fastbooking
