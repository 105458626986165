/* eslint-disable */
import dayjs from 'dayjs';
import CustomInputNumber from '../../elements/custom_input_number/custom_input_number';
import FastbookingPackages from './fastbooking.packages';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { currentDevice } from '../../core/utils/currentDevice';
import { userFromIhg, showIhgRestrictionsMessage } from '../../core/utils/ihg';

dayjs.extend(customParseFormat);

let isMobile = IB.currentDevice === 'mobile';
let data = {
  $fastbooking:        null,
  $children_template:  null,
  TYPE_HOTEL:          'h',
  TYPE_DESTINATION:    'd',
  TYPE_SPECIALITY:     's',
  fastbooking_data:    null,
  max_room_occupation: null,
  max_adults:          6,
  max_children:        3,
  last_room_selected:  JSON.stringify([])
};
const isFhiPage = document.querySelector('html').dataset.fhiTest !== undefined;

function initVars($fastbooking) {
  ChildrenControl.data.$fastbooking = $fastbooking;
  ChildrenControl.data.$children_template = ChildrenControl.data.$fastbooking.find('.children-template');
  if (IB.hotels_data.check()) {
    ChildrenControl.data.fastbooking_data = IB.hotels_data.getData();
  } else {
    IB.hotels_data.get(function () {
      ChildrenControl.data.fastbooking_data = IB.hotels_data.getData();
    });
  }
}


/**
 * Checks if selected destination is only adults, in that case shows a lightbox with information
 *
 * @param {object} $el - jquery object of destination select
 */
function checkOnlyAdults($el) {
  let $place_to_go_selected = (IB.currentDevice === 'mobile') ? $('.mobile-fastbooking-cnt .label-mobile-place-to-go select.chosen-select option:selected') : $('.place-to-go option:selected');
  let val = $place_to_go_selected.val();
  let type = val.charAt(0);
  let $box = $('.only-adults-fancybox');

  // Set data in info alerts to use them if needed
  $('.only_adults_title_name, .age_limit_destination_title_name').text($el.text());
  $('.only_adults_title_info2').text($el.text());
  $('.only_adults_title_age').text(limitsForChildrenAge(false));
  $box.find('.info-text').hide();

  const selectedAge = isNaN(parseInt($('.ages .age select').val())) ? 0 : parseInt($('.ages .age select').val());

  // If children not allowed or children age selected is < min children age allowed
  if (!allowChildren() || selectedAge < limitsForChildrenAge(false)) {
    // Prevent fancybox open if its already opened
    if ($.fancybox.getInstance()) return;

    // Open only adults fancybox
    $.fancybox.open($box, {
      autoFocus:  false,
      backFocus:  false,
      trapFocus:  false,
      beforeShow: function () {
        ScrollLock.on();
        setTimeout(function () {
          $('.mobile-fastbooking-cnt .label-mobile-place-to-go .chosen-container .chosen-search input').blur();
        }, 150);
      },
      afterShow: function () {
        let $pr_btn = $box.find('.btn-primary');

        if ($('.ages .age select').val() < limitsForChildrenAge(false) && allowChildren()) {
          if (type === ChildrenControl.data.TYPE_HOTEL) {
            $box.find('.only_adults_title_min').show();
            $('.adults-info-min-hotel').show();
            $pr_btn.find('.hotel-btn-text').show();
            $pr_btn.find('.destination-btn-text').hide();
          }
          if (type === ChildrenControl.data.TYPE_DESTINATION) {
            $box.find('.only_adults_title_min_destination').show();
            $('.destination-min-info').show();
            $pr_btn.find('.hotel-btn-text').hide();
            $pr_btn.find('.destination-btn-text').show();
          }
        }
        if (!allowChildren()) {
          if (type === ChildrenControl.data.TYPE_SPECIALITY) {
            $box.find('.only_adults_title_min_destination').show();
            $('.destination-min-info').show();
            $pr_btn.find('.hotel-btn-text').hide();
            $pr_btn.find('.destination-btn-text').show();
          } else {
            $box.find('.only_adults_title').show();
            $box.find('.adults-info-hotel').show();
            $pr_btn.find('.hotel-btn-text').show();
            $pr_btn.find('.destination-btn-text').hide();
          }
        }
      },
      afterClose: function () {
        ScrollLock.off();
      }
    });
  } else {
    // If children allowed
    // Legend:
    // .age-range is the information message about children age variation
    // .age-range-hotel is the information message that indicates children allowed ages
    if (type === ChildrenControl.data.TYPE_HOTEL || type === ChildrenControl.data.TYPE_DESTINATION) {
      $('.age-range').hide();
      $('.age-range-hotel').show();
      checkChildrenRoom($el);
    } else {
      $('.age-range').hide();
      $('.age-range-hotel').hide();
    }
  }
}

function checkTooltipChildren() {
  let $place_to_go_selected = (IB.currentDevice === 'mobile') ? $('.mobile-fastbooking-cnt .label-mobile-place-to-go select.chosen-select option:selected') : $('.place-to-go option:selected');
  let val = $place_to_go_selected.val();
  let type = val !== undefined && val !== null ? val.charAt(0) : '0';
  let max_age = +limitsForChildrenAge(true);
  let min_age = +limitsForChildrenAge(false);
  let $age_range_hotel = IB.currentDevice === 'mobile' ? ChildrenControl.data.$fastbooking.find('.age-range-hotel') : ChildrenControl.data.$fastbooking.find('.children-range-age');
  let type_text = type === ChildrenControl.data.TYPE_HOTEL ? $age_range_hotel.data('hotel') : $age_range_hotel.data('destination');
  if (type !== ChildrenControl.data.TYPE_HOTEL && type !== ChildrenControl.data.TYPE_DESTINATION) {
    $('.ages .general-age').removeClass('hidden');
    $('.ages .limit-age').addClass('hidden');
  } else {
    $('.age_range_hotel_min').text(min_age);
    $('.age_range_hotel_max').text(max_age);
    $('.age_range_hotel_min_element').text(type_text);
    $('.ages .general-age').addClass('hidden');
    $('.ages .limit-age').removeClass('hidden');
  }
  IB.tooltip.init($('.ages [data-tooltip]'));
}

function checkChildrenRoom($el) {
  let ages = ChildrenControl.data.$fastbooking.find('.select-wrapper select');
  let max_age = +limitsForChildrenAge(true);
  let num = 0;
  let rooms = 0;
  let total = 0;
  let children = [];
  let $total_adults = ChildrenControl.data.$fastbooking.find('.js-input-adults-count');
  let $total_children = ChildrenControl.data.$fastbooking.find('.js-input-children-count');

  checkTooltipChildren();

  $(ages).each(function () {
    if (parseInt($(this).val()) > parseInt(max_age)) {
      $('.rooms-advice-container').css('display', 'flex');

      if (IB.currentDevice === 'mobile') $('.show-people-panel').trigger('click');

      setTimeout(function () {
        IB.fastbooking.roomsPopoverPosition($('.number-of-guest'));
        $('.fastbooking #people-popover').show();
      }, 0);
      num = num + 1;
      children.push($(this));
    }
  });

  if (num > 0) {
    if ((parseInt($total_adults.val()) + num) <= 6) {
      $(children).each(function () {
        removeChildren($(this));
      });
    } else {
      total = (parseInt($total_adults.val()) + num) - 6;
      if (total == 1) {
        $total_adults.val(parseInt($total_adults.val()) - 1);
        $total_children.val(parseInt($total_children.val()) - 1);
        IB.fastbooking.addRoom($el, 1);
        IB.fastbooking.setNumberOfRooms(1, true);
      } else {
        IB.fastbooking.addRoom($el, 1);
        IB.fastbooking.setNumberOfRooms(1, true);
        rooms = ChildrenControl.data.$fastbooking.find('.room-row .room');
        if (total == 2) {
          $total_children.val(0);
          $el.closest('.input.age').remove();
        } else {
          $total_children.val(0);
          rooms.last().find('.input.adults .custom-input-number .input-number').val(total);
        }
      }
      $('.rooms-advice-container .rooms_advice_people').hide();
      $('.rooms-advice-container .rooms_advice').show();
      let totalRooms = document.querySelectorAll('.room-row .room').length;

      document.querySelector('.rooms_advice_adults').innerHTML = total + 6;
      document.querySelector('.rooms_advice_children').innerHTML = parseInt($total_children.val());
      document.querySelector('.rooms_advice_rooms').innerHTML = totalRooms

      $(children).each(function () {
        $(this).closest('.input.age').remove();
      });

      let qty = $total_children.val();
      let $roomRow = $('.room-row');
      let $wrapper = $roomRow.find('.ages');
      if (qty == 0) {
        $roomRow.removeClass('with-ages');
        $wrapper.addClass('hidden');
      } else {
        $roomRow.addClass('with-ages');
        $wrapper.removeClass('hidden');
      }
    }
  }
}


function removeChildren($el) {
  let $roomRow = $el.closest('.room-row');

  $el.closest('.input.age').remove();

  let $total_adults = $roomRow.find('.input.adults').find('input');
  let $total_children = $roomRow.find('.input.children').find('input');
  let $container = $roomRow.closest('.rooms-container');
  let full_total_adults = 0;
  let full_total_children = 0;
  let newTotalAdults = parseInt($total_adults.val()) + 1;
  let newTotalChildren = parseInt($total_children.val()) - 1;

  // If package is loaded, max adults limit will be taken into account
  if (FastbookingPackages.hasRestrictionsLoaded()) {
    let packageData = FastbookingPackages.getPackage();

    if (packageData.occupancy.max_adults < newTotalAdults) {
      newTotalAdults = packageData.occupancy.max_adults;
    }
  }

  $total_adults.val(newTotalAdults);
  $total_children.val(newTotalChildren);

  CustomInputNumber.refresh($total_adults);
  CustomInputNumber.refresh($total_children);

  $container.find('.room-row').each(function (index, element) {
    let $element = $(element);
    full_total_adults += +$element.find('.input.adults').find('input').val();
    full_total_children += +$element.find('.input.children').find('input').val();
  });

  if (IB.currentDevice === 'mobile') {
    let $f_cnt = $('.mobile-fastbooking-cnt');

    $f_cnt.find('.rooms_advice_people_adults').html(full_total_adults);
    $f_cnt.find('.rooms_advice_people_children').html(full_total_children);
  } else {
    ChildrenControl.data.$fastbooking.find('.rooms_advice_people_adults').html(full_total_adults);
    ChildrenControl.data.$fastbooking.find('.rooms_advice_people_children').html(full_total_children);
  }

  $('.rooms-advice-container .rooms_advice_people').show();
  $('.rooms-advice-container .rooms_advice').hide();

  let qty = $total_children.val();
  let $wrapper = $roomRow.find('.ages');
  if (qty == 0) {
    $roomRow.removeClass('with-ages');
    $wrapper.hide();
    return $wrapper.hide().find('.age').remove();
  } else {
    if (qty < 3) $roomRow.find('.input.children').find('.js-input-number-inc').removeClass('is-disabled');

    $roomRow.find('.input.age').each(function (index, element) {
      let $element = $(element);

      $element.find('.t-fb-label').attr('data-count', index + 1);
    });

    $roomRow.addClass('with-ages');
    $wrapper.show();
  }
}


/**
 * Check if children is allowed in selected destination
 *
 * @returns {boolean} - true if children allowed / false if not allowed
 */
function allowChildren() {
  let restrictions = $('.hotel select option:selected').data('restrictions');
  let $place_to_go_selected = (IB.currentDevice === 'mobile') ? $('.label-mobile-place-to-go').find('.chosen-select option:selected') : $('.place-to-go option:selected');
  let place_to_go_val = $place_to_go_selected.val();
  let allow_children = ($place_to_go_selected.attr('data-allow-children') === 'true');
  let $check_in = ChildrenControl.data.$fastbooking.find('.check-in');
  let $check_out = ChildrenControl.data.$fastbooking.find('.check-out');
  let check_in_moment;
  let check_out_moment;
  let type;

  if (place_to_go_val !== null && typeof place_to_go_val !== 'undefined') {
    let val = $place_to_go_selected.val();
    type = val.charAt(0);
    if (ChildrenControl.data.fastbooking_data !== null) {
      if (ChildrenControl.data.fastbooking_data.hotels !== undefined) {
        let f_data = null;

        // If selected destination is a hotel, set restrictions to check it later
        if (type === ChildrenControl.data.TYPE_HOTEL) {
          f_data = ChildrenControl.data.fastbooking_data.hotels[1];
          if (f_data !== null) {
            let item = f_data.filter(function (el) {
              return el.id === place_to_go_val;
            });
            restrictions = item.length ? item[0].data.restrictions : undefined;
          }
        } else if (type === ChildrenControl.data.TYPE_DESTINATION) {
          // If selected destination is a destination, check its allow_children value from fastbooking_data
          f_data = ChildrenControl.data.fastbooking_data.destinations[1];
          if (f_data !== null) {
            let item = f_data.filter(function (el) {
              return el.id === place_to_go_val;
            });
            allow_children = item.length ? item[0].data.allow_children : true;
          }
        } else if (type === ChildrenControl.data.TYPE_SPECIALITY) {
          // If selected destination is a specialty, check its allow_children value from fastbooking_data
          f_data = ChildrenControl.data.fastbooking_data.specialties[1];
          if (f_data !== null) {
            let item = f_data.filter(function (el) {
              return el.id === place_to_go_val;
            });
            allow_children = item.length ? item[0].data.allow_children : false;
          }
        }
      }
    }
  }

  // If there are restrictions, check them by date to determine allow_children
  if (restrictions !== null && typeof restrictions !== 'undefined') {
    if ($check_in.val()) {
      check_in_moment = dayjs($check_in.val(), $('html').attr('data-date-format')).valueOf();
    } else {
      check_in_moment = false;
    }

    if ($check_out.val()) {
      check_out_moment = dayjs($check_out.val(), $('html').attr('data-date-format')).valueOf();
    } else {
      check_out_moment = false;
    }

    for (let i = restrictions.length - 1; i >= 0; i--) {
      // In range if both dates in range, or only check in date (checkout date not provided) in range
      if (IB.fastbooking.dateInRange(check_in_moment, check_out_moment, restrictions[i])) {
        allow_children = restrictions[i].allow_children;
        continue;
      }
    }

    // Allowed by default
    if (typeof allow_children === 'undefined') {
      allow_children = true;
    }
  } else if (type !== 's') {
    // Allowed by default if there is not selected hotel
    allow_children = true;
  }

  return allow_children;
}


/**
 * Set children inputs and ages enabled/disabled
 */
function setAllowChildren($el) {
  let allow = allowChildren();
  let $inputChildren = $el.find('.children');
  let $inputAge = $el.find('.age');
  allow ? $inputChildren.removeClass('is-disabled') : $inputChildren.addClass('is-disabled');

  if (allow === true || allow === 'true') {
    $inputChildren.removeClass('is-disabled');
    $inputAge.removeClass('is-disabled');
  } else {
    $inputChildren.addClass('is-disabled');
    $inputAge.addClass('is-disabled');

    // Children counter is always 0 (custom_input_number => refresh)
    // Forces change event to set child age selector based of number of children selected
    $inputChildren.find('.js-input-number').trigger('change');
    if (!$('#rooms-popover').is(':visible')) IB.fastbooking.roomsPopoverPosition($('.number-of-guest'));
  }
}


/**
 * Updates age select with min and max valid values
 */
function setMaxChildrenAge($el) {
  let max_children_age = +limitsForChildrenAge(true);
  let min_children_age = +limitsForChildrenAge(false);
  let $select = $el.find('.select-wrapper select');
  let invalidChildAgeData = false;


  if (isNaN(max_children_age)) return false;

  $('.age_range_hotel_min').text(min_children_age);
  $('.age_range_hotel_max').text(max_children_age);


  $select.each(function (index, element) {
    let $this = $(element);
    let val = parseInt($this.val());
    $this.html('');
    $this.append('<option value="">#</option>');

    for (let i = min_children_age; i <= max_children_age; i++) {
      $('<option>').attr('value', i).text(i === 0 ? '<1' : i)
        .appendTo($this);
    }
    $this.prop('required', true).attr('data-parsley-group', 'age');
    if (val < max_children_age && val >= min_children_age) $this.val(val).change();
    invalidChildAgeData = invalidChildAgeData || val >= max_children_age || val < min_children_age;
  });

  if (invalidChildAgeData) IB.fastbooking.roomsPopoverPosition($('.number-of-guest'));
}


/**
 * Listeners on children selects
 */
function initSelectListeners() {
  $(document).on('focusin', '.select-wrapper select', function () {
    $(this).closest('.select-wrapper').addClass('select-wrapper--focused');
  });

  $(document).on('focusout', '.select-wrapper select', function () {
    $(this).closest('.select-wrapper').removeClass('select-wrapper--focused');
  });
}


/**
 * Adds/removes child age selector based of number of children selected
 *
 * @param {object} $el - jquery object of child-select
 */
function setChildren($el) {
  let qty = $el.val();
  let $roomRow = $el.closest('.room-row');
  let $wrapper = $roomRow.find('.ages');
  let $template = $(ChildrenControl.data.$children_template.html());
  let counter = $wrapper.find('.input.age').length;
  let is_mobile = (IB.currentDevice === 'mobile');
  let room_number = $el.closest('.room').find('.title').data('count');
  let childs = [];

  // Manage visibility of age warning messages
  checkAgeRange($el);

  if (Number(qty) === 0) {
    $roomRow.removeClass('with-ages');
    return $wrapper.addClass('hidden').find('.age').remove();
  } else {
    $roomRow.addClass('with-ages');
    $wrapper.removeClass('hidden');
  }

  // Ocultamos el tooltip de todas las habitaciones excepto de la primera que tenga algún niño.
  if ($el.closest('.rooms-container').find('.ages:not(.hidden) .children-range-age').length) {
    $el.closest('.rooms-container').find('.ages:not(.hidden) .children-range-age').slice(1)
      .remove();
    $el.closest('.rooms-container').find('.ages:not(.hidden) .tooltip-children-age').slice(1)
      .remove();
  }

  if (qty > counter) {
    qty = qty - counter;
    for (let i = 0; i < qty; i++) {
      $template.html($template.html().replace('%{age_child}', (counter + 1 + i)));
      let $temp_template = $template.clone();
      let input_id = 'age-input-' + $el.closest('.room-row').find('[data-count]').data('count') + '-' + (i + 1 + counter);
      setMaxChildrenAge($temp_template);
      if (is_mobile) $temp_template.attr('id', 'input-age-' + (i + 1 + counter) + '-' + room_number).find('select').attr('data-parsley-class-handler', '#input-age-' + (i + 1 + counter) + '-' + room_number);
      $temp_template.attr('id', input_id);
      $temp_template.find('select').attr('data-parsley-class-handler', '#' + input_id);
      $temp_template.find('[data-count]').attr('data-count', i + 1 + counter);
      $temp_template.find('[name=ages]').attr('name', $el.attr('name').replace('[children_count]', '[ages][]')).attr('data-id', $el.attr('name').replace('[children_count]', '[ages][' + i + counter + ']'));

      childs.push($temp_template);
    }
    $.each(childs, function (indexInArray, valueOfElement) {
      $(valueOfElement).insertBefore($wrapper.find('.error'));
    });
  } else if (qty < counter) {
    qty = counter - qty;
    $wrapper.find('.input.age').slice(-qty).remove();
  }

  checkTooltipChildren();
}


/**
 * Manages visibility of age warning messages
 * @param {object} $el - jquery object of child-select
 */
function checkAgeRange($el) {
  if (IB.currentDevice !== 'mobile' && $el.closest('.mobile-fastbooking-cnt').length) return false;


  let $fastbooking = (IB.currentDevice === 'mobile') ? $('.mobile-fastbooking-cnt') : $('.fastbooking');
  let $place_to_go = (IB.currentDevice === 'mobile') ? $fastbooking.find('.label-mobile-place-to-go') : $fastbooking.find('.place-to-go');
  let qty = $el.val();

  // Legend:
  // .age-range is the information message about children age variation
  // .age-range-hotel is the information message that indicates children allowed ages
  if (Number(qty) === 0) {
    $('.age-range').hide();
    $('.age-range-hotel').hide();
  } else if ($place_to_go.attr('data-parsley-id') === undefined) {
    $('.age-range').show();
    $('.age-range-hotel').hide();
  } else {
    $('.age-range').hide();
    $('.age-range-hotel').show();
  }
}

/**
 * Get max or min children age
 *
 * @param {boolean} maxLimit - if true, function will return max children age, else will return min children age
 *
 * @returns {number} - max or min children age
 */
function limitsForChildrenAge(maxLimit) {
  let restrictions = $('.hotel select option:selected').data('restrictions');
  let $place_to_go_selected;
  let place_to_go_val;
  let limit_children_age_max;
  let $check_in = ChildrenControl.data.$fastbooking.find('.check-in');
  let $check_out = ChildrenControl.data.$fastbooking.find('.check-out');
  let check_in_moment;
  let check_out_moment;

  if (isFhiPage) {
    $place_to_go_selected = $('.js-fhi-hotel-code');
    place_to_go_val = $place_to_go_selected.val();
    const hotelData = IB.hotels_data.getHotelById(place_to_go_val);
    if (!Boolean(hotelData)) return;
    limit_children_age_max = hotelData.data.restrictions[0].max_children_age;
  } else {
    $place_to_go_selected = (IB.currentDevice === 'mobile') ? $('.label-mobile-place-to-go').find('.chosen-select option:selected') : $('.place-to-go option:selected');
    place_to_go_val = $place_to_go_selected.val();
    limit_children_age_max = $place_to_go_selected.attr('data-max-childre-age');
  }

  if (!place_to_go_val && !maxLimit) return 0;


  if (place_to_go_val !== null && typeof place_to_go_val !== 'undefined') {
    let val = $place_to_go_selected.val();
    let type = val.charAt(0);

    if (type === ChildrenControl.data.TYPE_HOTEL && ChildrenControl.data.fastbooking_data.hotels !== undefined) {
      let hotel = ChildrenControl.data.fastbooking_data.hotels[1].filter(function (el) {
        return el.id === place_to_go_val;
      });
      restrictions = hotel.length ? hotel[0].data.restrictions : undefined;

      if (restrictions !== null && typeof restrictions !== 'undefined') {
        if ($check_in.val()) {
          check_in_moment = dayjs($check_in.val(), $('html').attr('data-date-format')).valueOf();
        } else {
          check_in_moment = false;
        }

        if ($check_out.val()) {
          check_out_moment = dayjs($check_out.val(), $('html').attr('data-date-format')).valueOf();
        } else {
          check_out_moment = false;
        }

        for (let i = restrictions.length - 1; i >= 0; i--) {
          if (IB.fastbooking.dateInRange(check_in_moment, check_out_moment, restrictions[i])) {
            if (maxLimit) {
              limit_children_age_max = restrictions[i].max_children_age;
            } else {
              limit_children_age_max = restrictions[i].min_children_age;
            }
          } else if (!maxLimit) {
            limit_children_age_max = $place_to_go_selected.data('min-childre-age');
          }
        }
        // Allowed by default
        if (typeof limit_children_age_max === 'undefined') {
          limit_children_age_max = (maxLimit ? 16 : 0);
        }
      }
    }
    if (type === ChildrenControl.data.TYPE_DESTINATION && ChildrenControl.data.fastbooking_data.destinations !== undefined) {
      let destination = ChildrenControl.data.fastbooking_data.destinations[1].filter(function (el) {
        return el.id === place_to_go_val;
      });
      if (maxLimit) {
        limit_children_age_max = destination.length && typeof(destination[0].data.max_children_age) !== 'undefined' ? destination[0].data.max_children_age : 16;
      } else {
        limit_children_age_max = destination.length && typeof(destination[0].data.min_children_age) !== 'undefined' ? destination[0].data.min_children_age : 0;
      }
    }
    if (type === ChildrenControl.data.TYPE_SPECIALITY && ChildrenControl.data.fastbooking_data.specialties !== undefined) {
      let specialty = ChildrenControl.data.fastbooking_data.specialties[1].filter(function (el) {
        return el.id === place_to_go_val;
      });
      if (maxLimit) {
        limit_children_age_max = specialty.length && typeof(specialty[0].data.max_children_age) !== 'undefined' ? specialty[0].data.max_children_age : 16;
      } else {
        limit_children_age_max = specialty.length && typeof(specialty[0].data.min_children_age) !== 'undefined' ? specialty[0].data.min_children_age : 0;
      }
    }
  }

  return limit_children_age_max;
}


/**
 * Set max and min values for each adults/children selector based on destination selected
 *
 * @param {object} $selected - jquery object of destination select
 */
function setMaxAdultsAndChildren($selected) {
  if (typeof $selected !== 'undefined' && $selected.length > 0) {
    let val = $selected.val();
    let type = val.charAt(0);
    if (type === 'd' || type === 'h' || type === 's')
    {
      setFromDataMaxAdultsAndChildren(val, type);
    } else {
      setDefaultAdultsAndChildren();
    }
  } else if (ChildrenControl.data.$fastbooking.attr('data-from-room')) {
    setFromDataMaxAdultsAndChildren();
  } else {
    setDefaultAdultsAndChildren();
  }
}

function setDefaultAdultsAndChildren() {
  let $fastbooking = ChildrenControl.data.$fastbooking;
  if ($fastbooking === null) {
    $fastbooking = isMobile ? $('#fastbooking-mobile') : $('.fastbooking.home');
    ChildrenControl.initVars($fastbooking);
  }
  ChildrenControl.data.max_adults = $fastbooking.data('default-max-adults'),
  ChildrenControl.data.max_children = $fastbooking.data('default-max-children');
  ChildrenControl.data.max_room_occupation = ''; // should never be reached, total control to max adults and max children per room
  changeMaxAdultsAndChildren(ChildrenControl.data.max_room_occupation, ChildrenControl.data.max_adults, ChildrenControl.data.max_children);
}

function setFromDataMaxAdultsAndChildren(val, type) {
  if (ChildrenControl.data.$fastbooking === null) return false;

  let roomOccupancyRestrictions = ChildrenControl.data.$fastbooking.attr('data-from-room')!== undefined ? ChildrenControl.data.$fastbooking.attr('data-from-room') : ChildrenControl.data.last_room_selected;
  roomOccupancyRestrictions = roomOccupancyRestrictions !== undefined ? JSON.parse(roomOccupancyRestrictions) : undefined;
  let item = [];

  if (val && type) {
    let data = [];
    if (type === 'h') { data = ChildrenControl.data.fastbooking_data.hotels[1]; }
    if (type === 'd') { data = ChildrenControl.data.fastbooking_data.destinations[1]; }
    if (type === 's') { data = ChildrenControl.data.fastbooking_data.specialties[1]; }

    item = data.filter(function (el) {
      return el.id === val;
    });
  }

  if (item.length && !roomOccupancyRestrictions.length) { // function called with val and type params and found occupancy data in fastbooking data
    ChildrenControl.data.max_occupancy = +item[0].data.max_occupancy;
    ChildrenControl.data.max_adults = +item[0].data.max_adults,
    ChildrenControl.data.max_children = +item[0].data.max_children;
    changeMaxAdultsAndChildren(ChildrenControl.data.max_occupancy, ChildrenControl.data.max_adults, ChildrenControl.data.max_children);
  } else if ( roomOccupancyRestrictions && roomOccupancyRestrictions?.length) { // data read from specific room occupancy object - sticky FB from rooms' CTA -  no val nor type params
    changeMaxAdultsAndChildren(roomOccupancyRestrictions[0].max_occupancy, roomOccupancyRestrictions[0].max_adults, roomOccupancyRestrictions[0].max_children);
    ChildrenControl.data.last_room_selected = JSON.stringify(roomOccupancyRestrictions);
    validateOccupationByRestrictions();
  } else { // function called with val and type params but no info found in fastbooking data
    setDefaultAdultsAndChildren();
  }
}
/* eslint-enable */

function changeMaxAdultsAndChildren(max_occupancy, max_adults, max_children) {
  const $peopleWrapper = $('#people-mobile-panel').hasClass('opened')
    ? $('#people-mobile-panel')
    : ChildrenControl.data.$fastbooking
  const $rooms = $peopleWrapper.find('.js-room-row')
  let $inputs_adults = $peopleWrapper.find('.input.adults').find('input')
  let $inputs_childrens = $peopleWrapper.find('.input.children').find('input')
  const parentElement = currentDevice.isMobile
    ? document.querySelector('.js-people-mobile-panel')
    : document.querySelector('.fastbooking')
  const alertRestrictions = parentElement.querySelectorAll(
    '.people-restrictions-alert, .people-restrictions-alert-adults, .ihg-restrictions-alert'
  )
  const max_children_age = limitsForChildrenAge(true)
  const min_children_age = limitsForChildrenAge(false)

  $rooms.each(function (index, room) {
    const $room = $(room)
    const groupName = `room-people-${index}`

    $inputs_adults = $room.find('.input.adults').find('input')
    $inputs_adults.attr('data-max', max_adults === 0 ? 1 : max_adults)
    $inputs_adults.attr('data-combined-max', max_occupancy)

    $inputs_adults = $room.find('.input.adults').find('input')
    $inputs_adults.attr('data-max', max_adults === 0 ? 1 : max_adults)
    $inputs_adults.attr('data-combined-max', max_occupancy)
    $inputs_adults.attr('data-group-name', groupName)

    $inputs_childrens = $room.find('.input.children').find('input')
    $inputs_childrens.attr('data-max', max_children)
    $inputs_childrens.attr('data-combined-max', max_occupancy)
    $inputs_childrens.attr('data-group-name', groupName)

    CustomInputNumber.init($inputs_adults, 'refresh')
    CustomInputNumber.init($inputs_childrens, 'refresh')
  })

  alertRestrictions.forEach(alert => {
    const maxAdultsElem = alert.querySelector('.room-max-adults')
    if (maxAdultsElem) maxAdultsElem.innerHTML = max_adults
    const maxChildrenElem = alert.querySelector('.room-max-children')
    if (maxChildrenElem) maxChildrenElem.innerHTML = max_children
    const maxOccupancyElem = alert.querySelector('.room-max-occupancy')
    if (maxOccupancyElem) maxOccupancyElem.innerHTML = max_occupancy
    const maxChildrenAgeElem = alert.querySelector('.room-max-children-age')
    if (maxChildrenAgeElem) maxChildrenAgeElem.innerHTML = max_children_age
    const minChildrenAgeElem = alert.querySelector('.room-min-children-age')
    if (minChildrenAgeElem) minChildrenAgeElem.innerHTML = min_children_age
  })

  // Hide the rest of the messages
  ;['.people-restrictions-alert-adults', '.people-restrictions-alert', '.ihg-restrictions-alert'].forEach(selector => {
    parentElement.querySelector(selector).classList.add('hidden')
  })

  if (max_children === 0) {
    // Only adults
    parentElement.querySelector('.people-restrictions-alert-adults').classList.remove('hidden')
  } else if (userFromIhg() && showIhgRestrictionsMessage()) {
    // Show IHG message
    parentElement.querySelector('.ihg-restrictions-alert').classList.remove('hidden')
  } else {
    parentElement.querySelector('.people-restrictions-alert').classList.remove('hidden')
  }
}

/* eslint-disable */

/**
 * Set occupancy passed as config object
 *
 * @param {object} occupancy - config object defining occupancy by room:
 * [
 *  { adults: 1, children: 1, age: [1] },
 *  { adults: 2, children: 0 },
 *  { adults: 1, children: 2, age: [1, 2] }
 * ]
 */
function setOccupancy(occupancy) {
  let roomRows = ChildrenControl.data.$fastbooking.find('.rooms-container .room-row');

  // Sometimes in mobile room-rows are in other container
  if (isMobile && !roomRows.length) {
    roomRows = ChildrenControl.data.$fastbooking.siblings('#people-mobile-panel').find('.rooms-container .room-row');
  }

  $.each(occupancy, function (index, roomOccupancy) {
    let $roomRow = $(roomRows[index]);
    let $input_adults = $roomRow.find('.input.adults').find('input');
    let $input_children = $roomRow.find('.input.children').find('input');

    if (roomOccupancy.adults && roomOccupancy.adults >= +$input_adults.attr('min') && roomOccupancy.adults <= +$input_adults.attr('max')) {
      $input_adults.val(roomOccupancy.adults);
      CustomInputNumber.refresh($input_adults);
    } else if (roomOccupancy.adults === 0) {
      $roomRow.find('.remove-room').trigger('click');
    }

    if (isNaN(roomOccupancy.children)) { roomOccupancy.children = 0; }
    if (allowChildren() && roomOccupancy.children >= 0 && roomOccupancy.children >= +$input_children.attr('min') && roomOccupancy.children <= +$input_children.attr('max')) {
      $input_children.val(roomOccupancy.children);
      CustomInputNumber.refresh($input_children);
    }

    if (roomOccupancy.age !== undefined && roomOccupancy.age !== null) {
      for (let i = 0; i < roomOccupancy.age.length; i += 1) {
        $($roomRow.find('.input select.age').get(i)).val(roomOccupancy.age[i]);
      }
    }
    if (index > 0) $roomRow.addClass('can-delete');
    if (index + 1 === roomRows.length) return false;
  });
}

/**
 * Set occupancy limit max/min for each adults/children selector passed as config object
 *
 * @param {object} occupancyLimits - config object defining occupancy limit by room:
 * [
 *  { max_adults: 1, max_children: 1, min_adults: 1, min_children: 1 },
 *  { max_adults: 2, max_children: 0, min_adults: 1, min_children: 1 },
 *  { max_adults: 1, max_children: 2, min_adults: 1, min_children: 1 }
 * ]
 */
function setOccupancyLimits(occupancyLimits) {
  let roomRows = ChildrenControl.data.$fastbooking.find('.rooms-container .room-row');

  // Sometimes in mobile room-rows are in other container
  if (isMobile && !roomRows.length) {
    roomRows = ChildrenControl.data.$fastbooking.siblings('#people-mobile-panel').find('.rooms-container .room-row');
  }

  $.each(occupancyLimits, function (index, occupancyLimitsItem) {
    if (Object.keys(occupancyLimitsItem).length) {
      let $roomRow = $(roomRows[index]);
      let $input_adults = $roomRow.find('.input.adults').find('input');
      let $input_children = $roomRow.find('.input.children').find('input');

      if (occupancyLimitsItem.max_occupancy) {
        ChildrenControl.data.max_occupancy = occupancyLimitsItem.max_occupancy;
        $input_adults.attr('combined-max', occupancyLimitsItem.max_occupancy);
        $input_children.attr('combined-max', occupancyLimitsItem.max_occupancy);
      }

      if (occupancyLimitsItem.max_adults) {
        ChildrenControl.data.max_adults = occupancyLimitsItem.max_adults,
        $input_adults.attr('max', occupancyLimitsItem.max_adults);
      }
      if (occupancyLimitsItem.min_adults) {
        $input_adults.attr('min', occupancyLimitsItem.min_adults);
      } else {
        $input_adults.attr('min', 1);
      }

      if (occupancyLimitsItem.max_children >= 0) {
        ChildrenControl.data.max_children = occupancyLimitsItem.max_children;
        $input_children.attr('max', occupancyLimitsItem.max_children);
      }
      if (occupancyLimitsItem.min_children) {
        $input_children.attr('min', occupancyLimitsItem.min_children);
      } else {
        $input_children.attr('min', 0);
      }

      CustomInputNumber.refresh($input_adults);
      CustomInputNumber.refresh($input_children);

      if (index + 1 === roomRows.length) return false;
    }
  });
}

function validateOccupationByRestrictions() {
  const $peopleWrapper = $('#people-mobile-panel').hasClass('opened') ? $('#people-mobile-panel') : ChildrenControl.data.$fastbooking;
  const $rooms = $peopleWrapper.find('.js-room-row');

  $rooms.each(function(index, room) {
    let $room = $(room);

    let $input_adults = $room.find('.input.adults').find('input');
    let maxAdults = $input_adults.attr('data-max');
    if ($input_adults.val() > maxAdults) $input_adults.val(maxAdults);

    let $input_children = $room.find('.input.children').find('input');
    let maxChildren = $input_children.attr('data-max');
    for (let i = $room.find('.js-child-input-age').length; i > maxChildren; i--) {
      $room.find(`#age-input-1-${i}`).remove();
    }

    CustomInputNumber.init($input_adults, 'refresh');
    CustomInputNumber.init($input_children, 'refresh');
  });
}

function setRooms($selected){
  //Eliminamos todos las habitaciones del fastbooking
  let $oldRooms = $('.js-room-row');
  let lastSearchRooms = $selected.data('rooms');
  //Eliminamos la habitaciones antiguas
  $.each($oldRooms, function( index, value ) {
    $(this).remove();
  });
  //Añadimos las del nuevo hotel seleccionado
  IB.fastbooking.setRoomsData(lastSearchRooms);
}


const ChildrenControl = {
  initVars:                   initVars,
  initSelectListeners:        initSelectListeners,
  data:                       data,
  checkOnlyAdults:            checkOnlyAdults,
  allowChildren:              allowChildren,
  setAllowChildren:           setAllowChildren,
  setMaxChildrenAge:          setMaxChildrenAge,
  setMaxAdultsAndChildren:    setMaxAdultsAndChildren,
  setFromDataMaxAdultsAndChildren: setFromDataMaxAdultsAndChildren,
  changeMaxAdultsAndChildren: changeMaxAdultsAndChildren,
  setChildren:                setChildren,
  limitsForChildrenAge:       limitsForChildrenAge,
  setOccupancy:               setOccupancy,
  setOccupancyLimits:         setOccupancyLimits,
  setRooms:                   setRooms
};

export default ChildrenControl;
