/* eslint-disable */

import _isEmpty from 'lodash/isEmpty'

import { renameEventCategory } from '../../core/analytics/dataLayerFunctions'
import { sendUtagEvent } from '../../core/analytics/utag_events'
// PY05355, Header REB - Ficha de hotel 2023, ?test_reb_header=true
import { initTestRebHeader } from '../../utils/test_reb_header'
import MainHeader, { EXCEPTIONS_REB_TEST } from '../../layout/header/reb/header';
import { get_last_search } from '../last_searches/last_searches'

import Fastbooking from './fastbooking'
import FastbookingPackages from './fastbooking.packages'
import FastbookingDateRangePicker from './fastbooking.rangedatepicker'

import { FB_STICKY_OPEN_EVENT, FB_STICKY_CLOSE_EVENT } from './fastbooking.constants'

const IN_RESULTS_PAGE = !!document.querySelector('[data-page-type="bookings"]')

const isMobile = IB.currentDevice === 'mobile'

// PY05355, Header REB - Ficha de hotel 2023, ?test_reb_header=true
const currentPageType = IB.currentPageType.get()
let $fastbooking

if (initTestRebHeader && EXCEPTIONS_REB_TEST.includes(currentPageType)) {
  $fastbooking = IB.currentDevice !== 'mobile' ? $('.js-reb-header') : $('#fastbooking-mobile')
} else {
  $fastbooking = IB.currentDevice !== 'mobile' ? $('.fastbooking.home') : $('#fastbooking-mobile')
}

// PY05355, Header REB - Ficha de hotel 2023, ?test_reb_header=true
// currentFastbooking means if REB header or fasbooking original is active
const $currentFastbooking = initTestRebHeader ? $('.js-reb-header') : $fastbooking

const $fastbooking_original_wrapper = $fastbooking.parent()
const $fbDateSelector = $fastbooking.find('.js-dates')
const openEvent = new Event(FB_STICKY_OPEN_EVENT)
const closeEvent = new Event(FB_STICKY_CLOSE_EVENT)

$fbDateSelector.on('datepicker-open', () => {
  if ($currentFastbooking.hasClass('sticky')) {
    fixCalendarPosition()
  }
})

$(document).on('click', '.btn-floating-fastbooking', function (event) {
  const $this = $(this)
  let hotelID = $this.attr('data-hotel-id')
  const hotelName = $this.attr('data-hotel-name')
  const roomMaxOccupancy = $this.attr('data-max-room-occupancy')
  const isInFancybox = $this.closest('.fancybox-container').length
  const isInModal = $this.closest('.js-modal').hasClass('is-open')
  const isInGmapsInfoBox = $this.closest('.infoBox').length

  /*
    When lightbox is opened in fastbooking results, its button has not href attribute (redirection), so
    we have to call a button with same hotelID, that it has href attrribute.
   */
  if (IN_RESULTS_PAGE && $.fancybox.getInstance()) {
    let $fb_button = document.querySelector(`.hotel-list .btn-floating-fastbooking.js-book-btn[data-hotel-id=${hotelID}]`)
    $.fancybox.close(true)

    if($fb_button) {
      $fb_button?.click()
    } else {
      $fb_button = document.querySelector(`.hotel-list .btn-floating-fastbooking.availability[data-hotel-id=${hotelID}]`)
      $fb_button?.click()
    }
  }

  if (isInFancybox) {
    $.fancybox.close(true)
  }
  if (isInModal) {
    Modal.closeAll()
  }



  if (!$this.hasClass('offer-code')) {
    if (!$this.attr('data-hotel-id') || IN_RESULTS_PAGE) return
  }

  event.preventDefault()

  const in_mobile = IB.currentDevice === 'mobile'
  const is_promo_code = $this.hasClass('offer-code')
  const promoCode = $this.attr('data-promo-code') !== undefined ? $this.attr('data-promo-code') : ''

  // Unload package if it was previously loaded
  if (FastbookingPackages.hasPackageLoaded()) {
    FastbookingPackages.unLoadPackage()
  }

  if (hotelID) {
    if (!hotelID.includes('h')) hotelID = `h${hotelID}`
    if (!$this[0].className.includes('utag-room-book-now')) {
      Fastbooking.setDestination(hotelID)
    }
    if (promoCode !== '') {
      Fastbooking.setPromoCode(promoCode, $fastbooking)
    }
  }

  if ($this.attr('data-room-code')) {
    $('.room-code').val($this.attr('data-room-code'))
  }

  // builds room's occupation object to update fastbooking children control
  if (roomMaxOccupancy) {
    const totalRooms = IB.fastbooking.childrenControl.data.$fastbooking.find('.rooms-container .room-row').length
    const occupancyRestrictionsPerRoom = []

    const roomOccupancyLimits = {
      max_occupancy: $this.data('max-room-occupancy'),
      max_adults: $this.data('max-adults'),
      max_children: $this.data('max-children'),
      min_adults: $this.data('min-adults'),
      min_children: $this.data('min-children'),
    }

    for (let i = 0; i < totalRooms; i++) {
      occupancyRestrictionsPerRoom.push(roomOccupancyLimits)
    }

    IB.fastbooking.childrenControl.data.$fastbooking.attr('data-from-room', JSON.stringify(occupancyRestrictionsPerRoom))
    IB.fastbooking.childrenControl.setOccupancyLimits(occupancyRestrictionsPerRoom)
    IB.fastbooking.childrenControl.setFromDataMaxAdultsAndChildren()
  }

  FastbookingDateRangePicker.current_offer = $this.data('offer-id')
  if (!in_mobile) {
    const showStickyFb = () => {
      addSemitransparentOverlayToFastbooking()
      loadPromoDataInFB($this)
      $fastbooking.find('.check-in-container').addClass('focused')
      if (isInFancybox || !isInGmapsInfoBox) {
        toggleFastbookingPlaceholder(true)
        // PY05355, Header REB - Ficha de hotel 2023, ?test_reb_header=true
        if (initTestRebHeader) {
          MainHeader.openPanel()
        } else {
          $fastbooking.addClass('sticky')
          // If page has hotel header, Add a new class to control header's opening
          if ($('.hotel-header, .hotel-header-inner').length ) {
            $fastbooking.addClass('sticky-force')
          }
        }
      }
      // Si existe un promocode asignado a su input, mostramos todo el contenido del FB
      if ($('.promo-code').length && $('.promo-code').val() !== '' && $('.promo-code').val() !== null) $fastbooking.addClass('has-promocode')
      if (is_promo_code) {
        return // prevents fastbooking from showing itself if called from offer-code div
      }

      FastbookingSticky.open({
        openDatepickerOnInit: true,
      })
    }
    // Si existe un promocode asignado a su input, mostramos todo el contenido del FB
    if ($('.promo-code').length && $('.promo-code').val() !== '' && $('.promo-code').val() !== null) $fastbooking.addClass('has-promocode')
    else $fastbooking.removeClass('has-promocode')

    // If its in fancybox or the Scroll is locked wait for scrolllock to be unlocked
    if (isInFancybox || isInModal || ScrollLock.isLock()) {
      ScrollLock.off(() => {
        // !! Timeout needed to avoid problems while closing a Modal or fancybox before opening fastbooking
        setTimeout(showStickyFb, 10)
      })
    } else {
      showStickyFb()
    }
  } else {
    if (typeof utag !== 'undefined' && !$this[0].className.includes('utag-room-book-now')) {
      sendUtagEvent({ data: { event_cat: 'destinations', event_act: 'interactive map', event_lbl: `book now|${hotelName?.toLowerCase() || ''}` } })
    }
    if (!is_promo_code) {
      // If its in fancybox wait for scrolllock to be unlocked
      FastbookingSticky.open()
    }
    loadPromoDataInFB($this)
  }
})

/* eslint-enable */

let overlayAdded = false

function addSemitransparentOverlayToFastbooking(target) {
  if (!overlayAdded) {
    const chosenContainer = document.querySelector('.chosen-container')
    const stickyFb = document.querySelector('.sticky-fb')
    const overlay = document.createElement('div')
    overlay.classList.add('fb-sticky-overlay')
    stickyFb.appendChild(overlay)
    overlayAdded = true

    overlay.addEventListener('click', function () {
      overlay.remove()
      document.querySelector('.input.discounts').classList.remove('opened-popup', 'focused', 'active')
      chosenContainer.classList.remove('chosen-with-drop', 'chosen-container-active')
      chosenContainer.querySelector('.chosen-results').classList.add('hidden-to-tab-navigation')
      overlayAdded = false
    })
  }
}

const jsDates = document.querySelectorAll('.js-dates, .second-level, .place-to-go')
for (let i = 0; i < jsDates.length; i++) {
  jsDates[i].addEventListener('click', function (e) {
    addSemitransparentOverlayToFastbooking(e.target)
  })
}

/* eslint-disable */

function loadPromoDataInFB($that) {
  const last_search = get_last_search()
  const codes = $('.offer-codes .offer-code .code') // if the page doesn't have codes ends function
  const code = $that.find('.code').html()

  if (!codes.length) return

  Fastbooking.loadPromoCodeSelector($('.offer-codes .offer-code .code'), code, $fastbooking)

  if (!_isEmpty(last_search) && last_search.check_in_date !== undefined) {
    FastbookingDateRangePicker.day_from = last_search.check_in_date
    FastbookingDateRangePicker.day_to = last_search.check_out_date
  }
}

/**
 * Opens sticky fastbooking
 *
 * @params {object} options - options
 */
function open(options) {
  const defaults = {
    openDatepickerOnInit: false, // If true, shows datepicker after open
    openDestinationSelectOnInit: false, // If true, shows destination select after open. Has priority over openDatepickerOnInit
  }
  const config = $.extend({}, defaults, options || {})

  if (isMobile) {
    if (IB.currentPageType.isHome()) {
      window.scrollTo(0, document.querySelector('#fastbooking-mobile').offsetTop - 65)

    }else {
      document.querySelector('.mobile-fastbooking-cnt.js-fastbooking-mobile.mobile-panel.top').classList.add('opened')
      $('body').append('<div class="mobile-fastbooking-bg"></div>')
    }

    if (config.openDestinationSelectOnInit) {
      Fastbooking.setDestination('')
      // open destination select if configured
      setTimeout(function () {
        const $fakeSelect = $fastbooking.find('.fake-select')
        if ($fakeSelect.length) {
          $fakeSelect.trigger('click')
        } else {
          $fastbooking.find('.chosen-single').trigger('mousedown')
        }
      }, 800)
    }
  } else {
    const $datepicker = $fastbooking.find('.dates')

    if (config.openDestinationSelectOnInit) {
      Fastbooking.setDestination('')
      // open destination select if configured
      setTimeout(function () {
        let $fakeSelect = $fastbooking.find('.fake-select')
        $fakeSelect = $fakeSelect.length ? $fakeSelect : $fastbooking.find('.fake-select-click')
        $fakeSelect.trigger('click')
      }, 800)
      $('body').append($fastbooking).addClass('fixed-calendar')
    } else if (config.openDatepickerOnInit) {
      if (document.querySelector('.hotel-test-fhi')) {
        document.querySelector('.fhi-fastbooking-sticky').classList.add('active')
        $fastbooking.removeClass('sticky')
      } else {
        // PY05355, Header REB - Ficha de hotel 2023, ?test_reb_header=true
        if (!initTestRebHeader) {
          // open datepicker if configured
          $('body').append($fastbooking).addClass('fixed-calendar')
        }
        // END - PY05355, Header REB - Ficha de hotel 2023, ?test_reb_header=true
      }
      setTimeout(function () {
        if ($datepicker.length > 0) {
          if (!$datepicker.data().dateRangePicker) {
            FastbookingDateRangePicker.activate($fastbooking, true)
          }
          $datepicker.data().dateRangePicker.open()
        }
      }, 800)
    }

    // PY05355, Header REB - Ficha de hotel 2023, ?test_reb_header=true
    if (!initTestRebHeader) {
      // Dispatch the event
      $fastbooking.get(0).dispatchEvent(openEvent)
    }
    // END - PY05355, Header REB - Ficha de hotel 2023, ?test_reb_header=true

    renameEventCategory($fastbooking, 'Fastbooking | Botón')
  }

  $fastbooking.trigger('sticky-fastbooking:open')
}

/**
 * Closes sticky fastbooking
 */
function close() {
  if (IB.is_in_mice) return
  if (!$fastbooking_original_wrapper.get(0).contains($fastbooking.get(0))) {
    $fastbooking_original_wrapper.append($fastbooking);
  }
  const $overlay = $('.fb-sticky-overlay')
  removeFixedCalendar();
  $overlay.remove();
  renameEventCategory($fastbooking, 'Fastbooking | Principal');

  // Unload package if it was previously loaded
  if (FastbookingPackages.hasPackageLoaded()) {
    FastbookingPackages.unLoadPackage()
  }

  // Remove previously applied room occupancy restraints
  if ($fastbooking[0].hasAttribute('data-from-room')) {
    IB.fastbooking.childrenControl.data.$fastbooking.removeAttr('data-from-room')
    IB.fastbooking.childrenControl.setMaxAdultsAndChildren($('#vo_booking_place_to_go'))
  }

  // Dispatch the event.
  $fastbooking.get(0).dispatchEvent(closeEvent)
}

/**
 * Keeps calendar fixed in position during user scroll
 */
function fixCalendarPosition() {

  const top_distance = $currentFastbooking.find('.fastbooking-calendar-placeholder').outerHeight()
  const calendar = document.querySelectorAll('.fb-datepicker')

  if (calendar.length) {
    calendar.length > 1 && calendar[0].remove()

    // initial values for calendar
    calendar[0].style.position = 'fixed'
    calendar[0].style.top = `${top_distance}px`
  }
}

function removeFixedCalendar() {
  const calendar = document.querySelector('.fb-datepicker')
  if (calendar) {
    $('.fastbooking').find('.dates').data().dateRangePicker.close()
    // initial values for calendar
    calendar.style.position = 'absolute'
  }
}

/**
 * Shows/hides fastbooking placeholder
 * - Fastbooking placeholder is a container that keeps fastbooking place while is sticky
 * @param {Boolean} force - forces placeholder visibility. True to show, false to hide
 * @param {Object} $fb - fastbooking element
 */
function toggleFastbookingPlaceholder(force, $fb = $fastbooking.get(0)) {
  if (document.querySelector('.hotel-test-fhi')) return // don't do this for FHI
  let $fbPlaceholder = document.querySelector('.js-fastbooking-placeholder')
  force = typeof force === 'boolean' ? force : undefined

  // Create fastbooking placeholder if not present
  if (!$fbPlaceholder) {
    if (IB.is_in_mice) return
    $fbPlaceholder = document.createElement('div')
    $fbPlaceholder.classList.add('js-fastbooking-placeholder')
    $fbPlaceholder.style.height = `${$fb.clientHeight}px`
    $fb.parentNode.insertBefore($fbPlaceholder, $fbPlaceholder.nextSibling)
  }

  // Toggle placeholder visibility
  $fbPlaceholder.classList.toggle('is-hidden', !force)
}

const FastbookingSticky = {
  open,
  close,
  fixCalendarPosition,
  removeFixedCalendar,
  toggleFastbookingPlaceholder,
}

if (typeof IB === 'undefined') {
  window.IB = {
    fastbooking: {},
  }
} else if (IB.fastbooking === undefined) {
  window.IB.fastbooking = {}
}

window.IB.fastbooking.sticky = FastbookingSticky

export default FastbookingSticky
