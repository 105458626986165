import { getPrefix } from '../core/utils/locale.js'

async function flightPlusHotelDetection() {
  let level, country, params, url;
  level = $.cookie('logged_messages') === undefined ? '1' : level = $.cookie('logged_messages').split('true:')[1];
  country = $.cookie('x-country');
  params = new URLSearchParams();
  url = `${getPrefix()}/ajax_contents/get_flight_and_hotel_tab/`;

  // const constrainedViewId = document.querySelector('body').getAttribute('data-constrained-view-id')
  const constrainedViewId = 'null'
  const pageType = document.querySelector('body').getAttribute('data-page-type')

  if (country) params.append("country", country);
  params.append("level", level);
  if ([null,undefined,"","undefined","null"].indexOf(constrainedViewId) === -1) params.append('constrained_view_id', constrainedViewId);
  if ([null,undefined,"","undefined","null"].indexOf(pageType) === -1) params.append("page_type", pageType);

  url = `${url}?${params.toString()}`

  // Get data
  const user_data = await fetch(url)
    .then(response => {
      return response.json()
    })
    .then(data => {
      return data
    })
    .catch(function (err) {
      console.log(err)
    })

  // Return user data
  return user_data
}

export default flightPlusHotelDetection
