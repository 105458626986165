import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import _filter from 'lodash/filter';
import _forEach from 'lodash/forEach';
import _uniq from 'lodash/uniq';
import { DESTINATION_PAGE_TYPE, HOTEL_PAGE_TYPE, SPECIALTY_PAGE_TYPE } from '../../utils/page_types';
import { initializeVars, setPlaceToGo } from './place_to_go_box';

dayjs.extend(customParseFormat);

let $doc = $(document);
let hotels_data = null;
let destinations_data = null;
let last_searches_data = [];
let is_mobile = null;
let specialties_data = null;
let next_opening_data = null;
let date_format = null;

function init($select, callback) {
  if (IB.hotels_data.check()) {
    initSelect($select);
    callback();
  } else {
    IB.hotels_data.get(function () {
      initSelect($select);
      callback();
    });
  }
}

function initSelect($select) {
  let fastbooking_data = IB.hotels_data.getData();

  if (!$select.length) return false;
  // Cacheamos si estamos en mobile o no
  is_mobile = !!(IB.currentDevice === 'mobile' || IB.currentDevice === 'tablet');

  date_format = $select.parents('[data-date-format]').data('date-format') || 'DD-MM-YYYY';

  setData(fastbooking_data);

  let $destinations_group = getOptions(destinations_data, fastbooking_data.destinations[0], 'destinations');
  let $hotels_group = getOptions(hotels_data, fastbooking_data.hotels[0], 'hotels');
  let $specialties_group = getOptions(specialties_data, fastbooking_data.specialties[0], 'specialties');
  let $next_opening_group = getOptions(next_opening_data, $('#next-opening-copy').data('fb-select'), 'next-openings');
  let $suggestions = getOptionsFeatured(specialties_data, '', 'suggestions');

  // checks the FB info in state and page info so we know wether to load the last search into the place to fo field
  // or not
  let state = IB.fastbooking_state.getState();
  let page_type = document.querySelector('[data-page-type]').getAttribute('data-page-type');

  if (is_mobile) {
    $select.append($suggestions);
  }

  $select.append($destinations_group, $hotels_group, $specialties_group, $next_opening_group);

  let local_last_searches = JSON.parse(localStorage.getItem('last_searches'));

  if (page_type === DESTINATION_PAGE_TYPE || page_type === HOTEL_PAGE_TYPE || page_type === SPECIALTY_PAGE_TYPE || IB.fastbooking_state.isSharedSearch()) {
    if (local_last_searches !== null) {
      insertLastSearches($select, local_last_searches);
    }
    setPlaceToGo($select, $select.data('selected'), true);
    if (local_last_searches !== null) {
      last_searches_data = local_last_searches.length > 3 ? local_last_searches.slice(Math.max(local_last_searches.length - 3, 1)) : local_last_searches;
    }
  } else if (local_last_searches !== null) {
    insertLastSearches($select, local_last_searches);
    if (typeof state.place_to_go !== 'undefined') {
      setPlaceToGo($select, IB.fastbooking_state.getState().place_to_go.id_and_type);
    }
  }

  // Add hotels in offer area if needed
  if ($.trim($('.hotels-to-filter-data').first().html())) {
    let hotel_ids_in_offer = JSON.parse($('.hotels-to-filter-data').first().html()).hotel_ids;
    addHotelsInOfferArea($select, hotel_ids_in_offer, null, null, local_last_searches);
  }

  if (document.documentElement.dataset.inMicrosite === 'ibercuba') {
    destinations_data = _filter(destinations_data, o => o.data.depth !== 0)
  }

  initializeVars(destinations_data, last_searches_data, hotels_data, specialties_data, next_opening_data);
}

function insertLastSearches($select, local_last_searches) {
  $select.find('.last-searches').remove();

  let searches = local_last_searches;
  let state = IB.fastbooking_state.getState();
  let $option_group = $('<optgroup label="' + $select.data('last-searches') + '" class="last-searches"></optgroup>');
  if (searches.length > 3) {
    searches = searches.slice(Math.max(searches.length - 3, 1));
  }
  searches = filterSearchsForSimpleSearch(searches, is_mobile);
  last_searches_data = searches;
  $.each(searches, function (index, obj) {
    let id = obj.place_to_go;
    let $option = $select
      .find('option[value=' + id + ']')
      .first()
      .clone();
    $option.addClass('last-search-opt');
    $option.attr({
      'data-check-in': obj.check_in_date !== undefined ? dayjs(obj.check_in_date).format(date_format) : null,
      'data-check-out': obj.check_out_date !== undefined ? dayjs(obj.check_out_date).format(date_format) : null,
      'data-rooms': JSON.stringify(obj.rooms),
    });
    $option_group.prepend($option);
  });
  $option_group.insertBefore($select.find('optgroup.destinations'));
}

/**
 * Creates a new offer area at the beginning of destination select content
 *
 * @param {oject} $select - jquery object with destination select
 * @param {array} hotelIds - array with hotel ids to add
 * @param {string} offerAreaTitle - title of offer area
 * @param {string} offerAreaClass (optional) - class to set to offer area
 */

function addHotelsInOfferArea($select, hotelIds, offerAreaTitle, offerAreaClass, local_last_searches) {
  if (!hotels_data) {
    hotels_data = IB.hotels_data.getData().hotels[1].filter(function (hotel) {
      return typeof hotel.data.next_opening === 'undefined' || hotel.data.next_opening === false;
    });
  }
  offerAreaClass = offerAreaClass || '';
  let hotels = hotelIds.map(function (element) {
    return hotels_data.filter(function (hotel) {
      return hotel.id === element;
    })[0];
  });

  // Remove offer area if it was previously added
  removeOfferArea();
  if (hotels != undefined && hotels.length > 0 && hotels[0] !== undefined) {
    let optgroup_text = offerAreaTitle || $select.data('hotelsInOfferText');
    let $in_offer_group = getOptions(hotels, optgroup_text, 'highlighted in-offer ' + offerAreaClass);
    //Esto hace que no actualice lso campos de habitación y usuarios
    let $option = $in_offer_group.find('option');
    $option.addClass('hotel-in-offer ' + offerAreaClass);
    local_last_searches?.map(function (element) {
      if ($option.val() === element.place_to_go) {
        $option.addClass('last-search-opt');
        $option.attr({
          'data-check-in': element.check_in_date !== undefined ? dayjs(element.check_in_date).format(date_format) : null,
          'data-check-out': element.check_out_date !== undefined ? dayjs(element.check_out_date).format(date_format) : null,
          'data-rooms': JSON.stringify(element.rooms),
        });
      }
    });
    $select.prepend($in_offer_group);
  }
}

function setData(data) {
  hotels_data = data.hotels[1].filter(function (hotel) {
    return typeof hotel.data.next_opening === 'undefined' || hotel.data.next_opening === false || hotel.data.next_opening === true;
  });
  next_opening_data = data.hotels[1].filter(function (hotel) {
    return hotel.data.next_opening === true;
  });
  destinations_data = data.destinations[1];
  specialties_data = data.specialties[1];
  setAllDestinationToHotel();
}

function removeOfferArea() {
  let $fastbooking = IB.currentDevice === 'mobile' ? $('#fastbooking-mobile') : $('.fastbooking.home');
  $fastbooking.find('.hotel-in-offer').remove();
  $fastbooking.find('.highlighted.in-offer').remove();
}

function setAllDestinationToHotel() {
  let all_ids = [];
  let d = null;
  let ids = null;
  let id = null;
  let dest = null;
  let t = _forEach(hotels_data, function (hotel) {
    d = _filter(destinations_data, ['id', hotel.data.destination])[0];
    ids = [];
    if (!d) {
      return;
    } else {
      id = d.id;
      ids.push(id);
      for (let i = d.data.depth; i >= 0; i--) {
        dest = _filter(destinations_data, ['id', id])[0];
        if (!dest) {
          break;
        } else {
          ids.push(id);
          id = dest.data.parent_id;
        }
      }
    }
    hotel.all_dest = _uniq(ids) || [];
    all_ids = all_ids.concat(ids);
  });
  return all_ids;
}

function getOptions(data, label, groupclass) {
  let $opt_group = $('<optgroup class="' + groupclass + '" label="' + label + '"></optgroup>');
  data.sort(compareByTitle);
  for (let i = data.length - 1; i >= 0; i--) {
    if (data[i] != null) {
      $opt_group.prepend(createOption(data[i]));
    }
  }
  return $opt_group;
}

function getOptionsFeatured(data, label, groupclass) {
  let $opt_group = $('<optgroup class="' + groupclass + '" label="' + label + '"></optgroup>');
  $.each(data, function (id, element) {
    // FAMILIAR s5, STAR PRESTIGE s11, TODO INCLUIDO s56
    if (element.id == 's5' || element.id == 's11' || element.id == 's56') $opt_group.prepend(createOptionFeatured(element));
  });
  return $opt_group;
}

function createOption(data) {
  let $el;
  let next_opening_var = 'data-next-opening="false"';
  if (typeof data.data != 'undefined') {
    if (typeof data.data.next_opening != 'undefined') next_opening_var = 'data-next-opening="' + data.data.next_opening + '"';
    if (typeof data.data.restrictions != 'undefined') {
      $el = $(
        '<option value="' +
          data.id +
          '" data-max-childre-age="' +
          data.data.restrictions[0].max_children_age +
          '" data-min-childre-age="' +
          data.data.restrictions[0].min_children_age +
          '" data-url="' +
          data.data.url +
          '" data-allow-children="' +
          data.data.restrictions[0].allow_children +
          '" ' +
          next_opening_var +
          '>' +
          data.title +
          '</option>'
      );
    } else {
      $el = $(
        '<option value="' +
          data.id +
          '" data-max-childre-age="16" data-min-childre-age="0"  data-allow-children="false" data-url="' +
          data.data.url +
          '" ' +
          next_opening_var +
          '>' +
          data.title +
          '</option>'
      );
    }
  } else {
    $el = $('<option value="' + data.id + '" data-max-childre-age="16"  data-min-childre-age="0" ' + next_opening_var + '>' + data.title + '</option>');
  }

  if (data.translation_list) {
    $el.attr('data-translations', data.translation_list);
  }
  return $el;
}

function createOptionFeatured(data) {
  let $el = $('<option class="featured" value="' + data.id + '">' + data.title + '</option>');
  if (data.translation_list) {
    $el.attr('data-translations', data.translation_list);
  }
  return $el;
}

/**
 * With this function we add the option group to the place to go select dynamically
 */
export function addOptionGroupToPlaceToGo(data, label, groupclass) {
  let newOptionsGroup = getOptions(data, label, groupclass);
  const select = IB.currentDevice === 'mobile' ? document.getElementById('mobile-hotels-selector') : document.getElementById('vo_booking_place_to_go')
  select.appendChild(newOptionsGroup[0])
}

$doc.on('click', '.return-mobile-panel', function (e) {
  $('.info-box-mobile').remove();
});

function compareByTitle(a, b) {
  if (a.title && b.title) return a.title.localeCompare(b.title);
  else return false;
}

//
// @doc
// The block static_image_with_simple_search has a very simple fastbooking. It only has the place to go field.
// It is specified to prevent jumping to CRS by *not loading* last seaches.
// This function filters the searches before they are loaded into the select.
// Notice it will not be executed if the simple fastbooking is not present or
// if we are in mobile so it doesn't affect more fastbookings.
function filterSearchsForSimpleSearch(searches, is_mobile) {
  if (!$('.fastbooking.simple').length || is_mobile) return searches;
  return [];
}

const FastbookingSelect = {
  init: init,
  addHotelsInOfferArea: addHotelsInOfferArea,
  removeOfferArea: removeOfferArea,
};

if (typeof IB === 'undefined') {
  window.IB = {
    fastbooking: {},
  };
} else if (IB.fastbooking === undefined) {
  window.IB.fastbooking = {};
}

window.IB.fastbooking.select = FastbookingSelect;

export default FastbookingSelect;
