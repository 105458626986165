import Fastbooking from './fastbooking';
import FastbookingSelect from './fastbooking.select';
import { setPlaceToGo, closePlaceToGoBox }  from './place_to_go_box';

let voice_recognition;

function isSpeechRecEnabled() {
  return Boolean(voice_recognition);
}

function speechRecognition() {
  try {
    // SpeechRecognition mandatory condition
    let SpeechRecognition = SpeechRecognition || webkitSpeechRecognition;
    voice_recognition = new SpeechRecognition();
    $('.fb-voice-search').removeClass('hidden');
  } catch (e) {}
}

function startRecording() {
  voice_recognition.start();

  voice_recognition.onresult = function (event) {
    searchStringInSelect(event.results[0][0].transcript);
  };
}

function searchStringInSelect(search_text) {
  let $select = $('.label-mobile-place-to-go select');
  let $result = $select.find('option:not(".last-search-opt"):contains("' + search_text + '")');

  if (!$result.length) return;
  setPlaceToGo($select, $result.val());
  closePlaceToGoBox();
  $select.next('#mobile_hotels_selector_chosen').find('.chosen-single').text(search_text);

  IB.fastbooking_state.setState();
}

function stopRecording() {
  if (voice_recognition !== undefined) voice_recognition.stop();
}


const FastbookingVoiceSearch = {
  init:               speechRecognition,
  start:              startRecording,
  stop:               stopRecording,
  isSpeechRecEnabled: isSpeechRecEnabled
};


$(function () {
  if (IB.currentDevice !== 'mobile' || !$('.fb-voice-search').length) return;

  FastbookingVoiceSearch.init();
  if (!FastbookingVoiceSearch.isSpeechRecEnabled()) return;

  $(document).on('click', '.fb-voice-search', function (event) {
    event.preventDefault();
    event.stopPropagation();
    let $fastbooking = $('#fastbooking-mobile');
    let $selector = $fastbooking.find('.label-mobile-place-to-go select');
    let $fake_select = $fastbooking.find('.fake-select');
    let init_class = 'initialized';

    if (!$fastbooking.hasClass(init_class)) {
      FastbookingSelect.init($selector, function () {
        Fastbooking.initSelect($selector, $fake_select);
      });
      $fastbooking.addClass(init_class);
    }
    $fake_select.trigger('click');
    FastbookingVoiceSearch.start();
  });

  $(document).on('click', '.close-mobile-panel', function () {
    FastbookingVoiceSearch.stop();
  });
});

if (typeof IB === 'undefined') {
  window.IB = {
    fastbooking: {}
  };
} else if (IB.fastbooking === undefined) {
  window.IB.fastbooking = {};
}

window.IB.fastbooking.voice_search = FastbookingVoiceSearch;

export default FastbookingVoiceSearch;
