/**
 * @format
 */

import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'

import { fetchLastSearch } from '../../../../frontend/src/shared/last_searches_cards/last_searches_cards'
import { readCookie } from '../../../../frontend/src/utils/cookie_manager'
import filterObjectProperties from '../../../../frontend/src/utils/filter_object_properties'

import { initializeUtagEvents } from './last_searches.analytics'

dayjs.extend(customParseFormat)
dayjs.extend(isSameOrAfter)

const LAST_SEARCH_DATE_FORMAT = 'YYYY-MM-DD'
const LOCALSTORAGE_SEARCHES_ID = 'last_searches'
export const PLACEHOLDER_IMAGE = '../../../images/icons/placeholder-last-search-card.png'

function getDates($element, query) {
  let $check_in_date = $element.find('[name*=check_in_date]')
  let $check_out_date = $element.find('[name*=check_out_date]')
  const format = $('html').data('date-format')
  if ($check_in_date.length > 0 && $check_out_date.length > 0) {
    if ($check_in_date.val() !== '') {
      query['check_in_date'] = dayjs($check_in_date.val(), format).format(LAST_SEARCH_DATE_FORMAT)
      $check_in_date = `ci${query['check_in_date'].replaceAll('-', '')}`
    } else $check_in_date = null
    if ($check_out_date.val() !== '') {
      query['check_out_date'] = dayjs($check_out_date.val(), format).format(LAST_SEARCH_DATE_FORMAT)
      $check_out_date = `co${query['check_out_date'].replaceAll('-', '')}`
    } else $check_out_date = null
    query['day'] = dayjs().format(LAST_SEARCH_DATE_FORMAT)
  }
  query['datesInfoKey'] = $check_in_date && $check_out_date ? [$check_in_date, $check_out_date].join('_') : null
}

function getRooms($element, query) {
  query['pax_config'] = []
  $element
    .find('.rooms-container')
    .find('.room-row')
    .each(function (index, el) {
      const room = {}
      const $el = $(el)
      room.adults_count = $el.find('.adults').find('.js-input-number').val()
      const children = $el.find('.children').find('.js-input-number').val()
      if (children > 0) {
        $el
          .find('.ages')
          .find('.age')
          .each(function (i, age) {
            const val = $(age).find('.age').val()
            room.ages = room.ages ? room.ages : []
            if (val != null) room.ages.push(val)
          })
      }
      room.children_count = children
      query['rooms'] = query['rooms'] ? query['rooms'] : []
      query['rooms'].push(room)
      if (room.children_count > 0) {
        const child_ages = JSON.stringify(room.ages)
          .replace(/[""]/g, '')
          .replace(/[\[\]]/g, '')
          .replaceAll(',', '*')
        query['pax_config'].push(`${room.adults_count}p${room.children_count}-${child_ages}`)
      } else {
        query['pax_config'].push(`${room.adults_count}p${room.children_count}`)
      }
    })
  query['pax_config'] = query['pax_config'].join('_')
}

function getPromocode($element, query) {
  let $promo_code = $element.find('[name*=promo_code]')
  if ($promo_code.val() !== '') {
    $promo_code = `pc${$promo_code.val()}`
  } else $promo_code = null
  query['pc'] = $promo_code
}

function getResident($element, query) {
  const resident_cookie = readCookie('resident')
  const $resident = resident_cookie !== null ? $element.find(`[data-discount*=resident-${resident_cookie}]`) : null
  query['resident'] = $resident.length == 1
}

function getHorizon(query) {
  const horizons_cookie = readCookie('my_iberostar_discount')
  query['horizons'] = horizons_cookie === 'true'
}

function createSearchUrl($element, query) {
  query['search_url'] = []
  query['search_url'].push(query['place_to_go'])
  query['search_url'].push(query['pax_config'])

  if (query['datesInfoKey']) {
    query['search_url'].push(query['datesInfoKey'])
    delete query['datesInfoKey']
  }

  if (query['pc']) {
    query['search_url'].push(query['pc'])
  }

  if (query['resident']) {
    query['search_url'].push('resident')
  }
  delete query['resident']

  if (query['horizons']) {
    query['search_url'].push('horizon')
  }
  delete query['horizons']

  query['search_url'] = query['search_url'].join('_')
}

function getPlaceToGo($element, query) {
  const $place_to_go = $element.find('[name*=place_to_go]')
  query['place_to_go'] = $place_to_go.val()
}

function queryDefaultValues($element) {
  const query = {}
  query['image'] = ''
  query['image_alt'] = ''
  query['title'] = $element.find('.input-text').text()
  query['description'] = ''
  query['place_title'] = ''
  query['locale'] = document.documentElement.getAttribute('data-js-lang')
  return query
}

function get_current_search(target) {
  const $element = $(target)
  const query = queryDefaultValues($element)
  getPlaceToGo($element, query)
  // Solo nos interesa para el fastbooking de tipo "place to go".
  if (query['place_to_go']) {
    //Si el elelement que desata el evento es el FB, nos quedamos con las fechas
    //Si entramos a una ficha del hotel no nos interesa tener las fechas.
    if (target !== document) {
      getDates($element, query)
    }
    getRooms($element, query)
    getPromocode($element, query)
    getResident($element, query)
    getHorizon(query)

    createSearchUrl($element, query)

    return query
  }
  return null
}

function removeOldSearches(searches) {
  searches = searches.slice(-3) // max last searches saved: 3
  return searches
}

/**
 * Determines if a search is valid based on its check in and check out dates.
 * A search is valid if it has no check in date, or if it has a check in date that is the same day or after today,
 * and it has no check out date, or if it has a check out date that is after today.
 *
 * @param {Object} search - The search object containing check in and check out dates.
 * @returns {boolean} - True if the search is valid, false otherwise.
 */
function isSearchValid(search) {
  // valid if has no dates
  if (!search.check_in_date) {
    return true
  }
  const today = dayjs()
  const checkInDate = dayjs(search.check_in_date, LAST_SEARCH_DATE_FORMAT)
  const checkInDateIsValid = checkInDate.isSameOrAfter(today, 'day')
  // valid if has no check out date and check in date is valid
  if (!search.check_out_date) {
    return checkInDateIsValid
  }
  const checkOutDate = dayjs(search.check_out_date, LAST_SEARCH_DATE_FORMAT)
  // valid if both dates are today or after today
  return checkInDateIsValid && checkOutDate.isAfter(today, 'day')
}

/**
 * Removes dates in expired searches from an array of search objects.
 *
 * @param {Array} searches - The array of search objects to update.
 * @returns {Array} - The updated array of search objects without dates in expired searches.
 */
function removeDatesFromExpiredSearches(searches) {
  return searches.map(search => {
    if (!isSearchValid(search)) {
      if (Object.prototype.hasOwnProperty.call(search, 'check_in_date')) {
        delete search.check_in_date
      }
      if (Object.prototype.hasOwnProperty.call(search, 'check_out_date')) {
        delete search.check_out_date
      }
    }

    return search
  })
}

/**
 * Removes repeated searches from an array of objects.
 * @param {Array} searches - An array of objects representing the searches performed.
 * @returns {Array} - A new array with unique searches.
 */
function removeRepeatedSearches(searches) {
  // Properties that will be used to check if objects are similar
  const propertiesToCompare = ['check_in_date', 'check_out_date', 'place_to_go', 'pax_config']
  // Filters the searches array
  return searches.filter((obj, index, self) => {
    const currentObj = filterObjectProperties(obj, propertiesToCompare)
    // Find the index of the first occurrence of the object in the array
    const firstIndex = self.findIndex(search => {
      const currentSearch = filterObjectProperties(search, propertiesToCompare)
      // Compare the current search object with the current object being filtered
      return JSON.stringify(currentSearch) === JSON.stringify(currentObj)
    })
    // If the index of the first occurrence of the object in the array is equal to the current index, it means it's the first time it appears.
    return index === firstIndex
  })
}

function resetSearchesWith(searches) {
  if (!searches || !searches.length) return

  let lastSearches = removeDatesFromExpiredSearches(searches)
  lastSearches = removeRepeatedSearches(lastSearches)
  lastSearches = removeOldSearches(lastSearches)

  localStorage.setItem(LOCALSTORAGE_SEARCHES_ID, JSON.stringify(lastSearches))
}

export function fillSearches(searches_info, last_searches) {
  const local_last_searches = JSON.parse(last_searches)

  local_last_searches.forEach(item => {
    const current_place = JSON.parse(searches_info)[item.place_to_go.toString()]

    if (current_place !== undefined) {
      item.title = current_place.search_info_title
      item.description = current_place.search_info_description
      item.image = current_place.search_info_image
      if (current_place.search_info_image_desktop) {
        item.image_desktop = current_place.search_info_image_desktop
      }
      if (current_place.search_info_image_tablet) {
        item.image_tablet = current_place.search_info_image_tablet
      }
      if (current_place.search_info_image_mobile) {
        item.image_mobile = current_place.search_info_image_mobile
      }
      if (current_place.search_info_hotel_category) {
        item.category = current_place.search_info_hotel_category
      }
      if (current_place.search_info_megabanner_description) {
        item.summary = current_place.search_info_megabanner_description
      }
      item.image = item.image === '' ? PLACEHOLDER_IMAGE : item.image
      item.image_alt = current_place.search_info_image_alt
      item.place_title = current_place.search_info_place_title
      item.next_opening = current_place.search_info_next_opening
      item.path = current_place.search_info_path
      item.segmentations = current_place.search_info_hotel_segmentations
      item.locale = document.documentElement.getAttribute('data-js-lang')
    }
  })
  resetSearchesWith(local_last_searches)
}

export function getLastSearches({ stringify = false } = {}) {
  let lastSearches
  try {
    lastSearches = localStorage.getItem(LOCALSTORAGE_SEARCHES_ID)
  } catch (e) {}

  if (lastSearches === null) {
    return stringify ? '' : []
  }

  return stringify ? lastSearches : JSON.parse(lastSearches)
}

export function updateLastSearches(callback) {
  const lang = document.documentElement.getAttribute('data-js-lang')

  const last_searches = getLastSearches()

  /**
   * Find last searches to update wheter they have missing data
   * or they need to update their locale
   */
  const last_searches_ids = []
  if (last_searches.length > 0) {
    last_searches.forEach(search => {
      if (search.title === '' || search.description === '' || search.image === '' || search.locale !== lang) {
        last_searches_ids.push(search.place_to_go)
      }
    })
  }

  if (last_searches_ids.length > 0) {
    fetch(`/${lang}/fetch_last_search/?ids=${last_searches_ids.toString()}&current_locale=${lang}`)
      .then(response => response.json())
      .then(searches_info => {
        fillSearches(searches_info, JSON.stringify(last_searches))
        if (callback) {
          callback()
        }
      })
      .catch(error => {
        console.error(`Error fetching last searches: ${error}`)
      })
  } else if (callback) {
    callback()
  }
}

export function addSearch(search) {
  if (!search) return

  let lastSearches

  try {
    lastSearches = localStorage.getItem(LOCALSTORAGE_SEARCHES_ID)
  } catch (e) {}
  if (lastSearches === null) {
    lastSearches = []
  } else {
    lastSearches = JSON.parse(lastSearches)
  }

  lastSearches = removeDatesFromExpiredSearches(lastSearches)
  lastSearches.push(search)
  lastSearches = removeRepeatedSearches(lastSearches)
  try {
    localStorage.setItem(LOCALSTORAGE_SEARCHES_ID, JSON.stringify(lastSearches))
  } catch (e) {}
}

function save_last_search(target) {
  const search = get_current_search(target)
  addSearch(search)
}

export function get_last_search() {
  let last_search = {}
  let last_searches
  try {
    last_searches = localStorage.getItem(LOCALSTORAGE_SEARCHES_ID)
  } catch (e) {}
  if (last_searches === null) {
    last_searches = []
  } else {
    last_searches = JSON.parse(last_searches)
    last_search = last_searches.pop()
  }
  return last_search
}

function save_last_search_shared_by_cookie() {
  const last_searches_shared = readCookie('shared_last_searches')
  if (last_searches_shared !== undefined) {
    const last_search_keys = last_searches_shared.split(',')
    const locale_html = document.documentElement.getAttribute('data-js-lang')

    $.ajax({
      dataType: 'JSON',
      type: 'GET',
      url: `/fetch_last_search_shared/?search_keys=${last_search_keys.toString()}`,
      cache: true,
      success(data) {
        const share_searches = JSON.parse(data.share_searches)
        const places_to_go = data.places_to_go
        if (share_searches !== null) {
          $.each(share_searches, function (i, share_search) {
            const search = share_search.info
            let last_searches

            if (search !== null) {
              try {
                last_searches = localStorage.getItem(LOCALSTORAGE_SEARCHES_ID)
              } catch (e) {}
              if (last_searches === null) {
                last_searches = []
              } else {
                last_searches = JSON.parse(last_searches)
              }

              last_searches = removeRepeatedSearches(last_searches)
              last_searches.push(search)
              last_searches = removeOldSearches(last_searches)
              try {
                localStorage.setItem(LOCALSTORAGE_SEARCHES_ID, JSON.stringify(last_searches))
                last_searches = localStorage.getItem(LOCALSTORAGE_SEARCHES_ID)
              } catch (e) {}
            }
          })
        }
        IB.cookieManager.deleteCookies(['shared_last_searches'])
        const local_last_searches = localStorage.getItem(LOCALSTORAGE_SEARCHES_ID)
        fetchLastSearch(places_to_go, local_last_searches)
      },
    })
  }
}

function cleanLastSearches() {
  let lastSearches = getLastSearches()

  if (!lastSearches.length) return

  lastSearches = removeDatesFromExpiredSearches(lastSearches)
  lastSearches = removeRepeatedSearches(lastSearches)

  try {
    localStorage.setItem(LOCALSTORAGE_SEARCHES_ID, JSON.stringify(lastSearches))
  } catch (e) {}
}

cleanLastSearches()

$(function () {
  save_last_search_shared_by_cookie()

  $('.fastbooking, .mobile-fastbooking-form').on('submit', function (event) {
    save_last_search(event.target)
  })
  if (IB.currentPageType.isDestination() || IB.currentPageType.isHotel() || IB.currentPageType.isSpecialty()) {
    save_last_search(document)
  }
})

initializeUtagEvents()
