const isFhiPage = document.querySelector('html').dataset.fhiTest !== undefined;
import Modal from '../modal/modal'

const MODAL_MYIB_SELECTOR = 'data-myib-promo-micromodal-trigger'

export function initDiscountTooltips() {
  if (IB.currentDevice !== 'mobile') {
    initDesktop();
  } else {
    $('[data-discounttooltip]').each(function () {
      let $el = $(this);
      let href = $el.data('desc-html');
      $el.attr('href', href);
      $el.fancybox({
        'width':          'auto',
        'height':         'auto',
        'autoSize':       true,
        'scrolling':      'hidden',
        'autoDimensions': true,
        'helpers':        {
          'overlay': {
            'locked': true
          }
        },
        'padding': 0,
        'margin':  [10, 10, 10, 10]
      });
    });
  }

  // Initialize promo information modals
  Modal.init({
    openTrigger: MODAL_MYIB_SELECTOR,
    onShow: () => {
      if (isFhiPage) return
      ScrollLock.on()
    },
    onClose: () => {
      if (isFhiPage) return
      ScrollLock.off()
    },
    awaitCloseAnimation: true,
  })
}

function initDesktop() {
  let $tooltip = $('[data-discounttooltip]');
  if (!$tooltip.length) return false;
  $tooltip.each(function (index, el) {
    let $this = $(this);

    $this.focus(function () {
      $this.trigger('mouseenter');
    });
    $this.blur(function () {
      $this.trigger('mouseleave');
    });

    $this.hover(function () {
      let type = $this.data('discounttooltip');
      let desc_html = $this.data('desc-html');
      let position = $this.data('position');
      let $template = $('<div class="basic-tooltip ' + type + ' ' + position + '"></div>');
      $template.append('<div class="tooltip-desc">' + $(desc_html).html() + '</div>');
      $this.append($template);
    }, function () {
      $this.find('.basic-tooltip').remove();
    });
  });
}

$(document).on('click', '.fastbooking .discount-checks .discount .info-link, .mobile-fastbooking-cnt .discount-checks .discount .info-link', function (e) {
  e.stopPropagation();
  e.preventDefault();
  if (e.handled !== true) {
    if ($('#mi-iberostar').is(':checked') || $('.discount label.fk-check').hasClass('checked')) {
      $('#mi-iberostar-modal .check-discount').hide();
    } else {
      $('#mi-iberostar-modal .check-discount').show();
    }
    e.handled = true;
  }
});

$(document).on('click', '#mi-iberostar-modal .check-discount', function (e) {
  e.preventDefault();
  let is_mobile = IB.currentDevice === 'mobile';
  let $mi_ibe = $('#mi-iberostar');

  if (!$mi_ibe.is(':checked')) {
    if (is_mobile) {
      let $discount_row = $('.mobile-fastbooking-cnt [data-discount="my-IB"]');
      $discount_row.addClass('active');
      $discount_row.find('.fk-check').addClass('checked');
    } else {
      $mi_ibe.closest('.fk-check').addClass('checked');
    }

    $mi_ibe.trigger('click');
    $('.fastbooking .discount-checks .discount .info.cro-my-ibe').removeClass('animation');
  }
  Modal.close()
});
