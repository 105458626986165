import dayjs from 'dayjs';
import { setPlaceToGo}  from './place_to_go_box';
import minMax from 'dayjs/plugin/minMax';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(minMax);
dayjs.extend(customParseFormat);

function get_sessionStorage() {
  // Load data in fastbooking only if we are in hotel page coming from booking page (or the booking page itself)
  if (!($('.hotel-page').length && document.referrer.match(/(\/reservas|\/bookings)/)) ||
    document.location.href.match(/(\/reservas|\/bookings)/)) {return false;}

  if (localStorage['fastbooking_state'] != undefined && localStorage['fastbooking_state'].length > 0) {
    let fastbooking_state_json = JSON.parse(localStorage['fastbooking_state']);
    if (dayjs() <= dayjs(fastbooking_state_json.dates.check_in, 'DD/MM/YYYY')) {
      set_date(fastbooking_state_json.dates.check_in, '.check-in');
      set_date(fastbooking_state_json.dates.check_out, '.check-out');
    }
  } else if ($('.hotels-to-filter-data').length > 0) {
    let data = JSON.parse($('.hotels-to-filter-data').first().html());
    if (data.offer_days != undefined && data.offer_days.stay_from != null) {
      let stay_from_date = dayjs.max(dayjs(data.offer_days.stay_from), dayjs().add(3, 'days'));
      let stay_to_date = dayjs.min(dayjs(data.offer_days.stay_to), dayjs(stay_from_date).add(7, 'days'));
      if (stay_to_date.isBefore(stay_from_date)) {
        stay_to_date = dayjs(stay_from_date).add(7, 'days');
      }
      set_date(stay_from_date.format('DDMMYY'), '.check-in');
      set_date(stay_to_date.format('DDMMYY'), '.check-out');
    }
    if (data.selected_hotel != undefined && data.selected_hotel != null) {
      let $fastbooking = (IB.currentDevice === 'mobile') ? $fastbooking = $('#fastbooking-mobile') : $fastbooking = $('.fastbooking');
      setPlaceToGo($fastbooking.find('.chosen-select'), data.selected_hotel, true);
    }
  }
}

function set_date(session_date, elem_class) {
  let date_format = $('html').data('date-format');
  let date_value = dayjs(session_date, date_format).format(date_format);
  if (date_value != undefined && date_value.length > 0) {
    $(elem_class).each(function (i, e) {
      $(this).val(date_value);
    });
  }
}

$(document).ready(function () {
  if (!IB.fastbooking_state.isSharedSearch()) {get_sessionStorage();}
});
