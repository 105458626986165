let is_init = false;
let isPartner = false;
let check_selector = '[data-discount=my-IB]';
let $check;

function init() {
  let $fastbooking = IB.currentDevice === 'mobile' ? $('#fastbooking-mobile') : $('.fastbooking');

  isPartner = IB.partner.isPartner();

  if ($fastbooking.find('.discount-checks').length) {
    $check = $fastbooking.find(check_selector);

    is_init = true;
    return true;
  }
  return false;
}

function checkDiscount(selectedPlace) {
  if (!is_init) {
    if (!init()) return;
  }

  if (typeof selectedPlace !== 'undefined' && selectedPlace != null) {
    if (selectedPlace.data.show_discount) {
      $check.removeClass('hidden');
      if (IB.currentDevice === 'tablet') $('.fastbooking .container').addClass('with-discount');
    } else {
      $check.addClass('hidden');
    }
  } else {
    $check.addClass('hidden');
  }
}

const FastbookingDiscountMyIB = {
  checkDiscount: checkDiscount
};

$(function () {
  init();
});

if (typeof IB === 'undefined') {
  window.IB = {
    fastbooking: {}
  };
} else if (IB.fastbooking === undefined) {
  window.IB.fastbooking = {};
}

window.IB.fastbooking.discount_my_IB = FastbookingDiscountMyIB;

export default FastbookingDiscountMyIB;
