import { readCookie, setCookie } from '../../utils/cookie_manager';
import FastbookingDiscountMyIberostar from './fastbooking.discount_myiberostar';

let is_init = false;
let user_resident = readCookie('resident');
let currency = readCookie('currency');
let resident_checks_selector = 'input.resident';
let tooltip_selector = '.price-wrapper .s-alert-info';
let prices_selector = 'p.price-cnt, .call-to-action .highlight, .call-to-action .prev-highlight, .info-price .highlight, .price-info .highlight, .discount .highlight';
let alreadyChecked = false;

if (typeof currency === 'undefined' || currency === null) {
  currency = 'DEF';
}

function init() {
  initChecks();

  updatePricesInfo();
  updateTooltips();

  $(resident_checks_selector).on('change', function (e) {
    residentChecksCallback($(this));
  });
}

function initChecks() {
  let $fastbooking;
  if (IB.currentDevice === 'mobile') {
    $fastbooking = $('#fastbooking-mobile');
  } else {
    $fastbooking = $('.fastbooking');
  }
  if ($fastbooking.find('.discount-checks').length) {
    // update checkboxes
    if (typeof user_resident !== 'undefined' && user_resident != null) {
      $fastbooking.find('input#resident-' + user_resident).prop('checked', true).closest('.discount')
        .addClass('active');
      alreadyChecked = true;
      IB.fastbooking.updateDiscountCounter();

    }
    is_init = true;
    return true;
  }
  return false;
}

function residentChecksCallback($el) {
  let value = $el.val();
  let checked = $el.is(':checked');

  if (checked) {
    setCookie('resident', value);
    let $other_check = $(resident_checks_selector).not("[name='resident-" + value + "']");
    $other_check.prop('checked', false);
    $other_check.formikation();
    $el.closest('.discount').addClass('active');
    if (!alreadyChecked) IB.fastbooking.updateDiscountCounter();
    alreadyChecked = true;
  } else {
    setCookie('resident', value, "-1");
    $el.closest('.discount').removeClass('active');
    IB.fastbooking.updateDiscountCounter();
    alreadyChecked = false;
  }
}

function checkDiscount(destination) {
  if (!is_init) {
    if (!initChecks()) return;
  }

  hideChecks();

  if (typeof destination !== 'undefined' && destination != null) {
    $.each($(resident_checks_selector), function (index, element) {
      if (destination.data.resident === element.name.replace('resident-', '')) {
        showCheck(destination.data.resident);
      }
    });
  }
}

function showCheck(key) {
  hideChecks();
  $("[data-discount='resident-" + key + "']").removeClass('hidden');
  $("[data-discount='resident-" + key + "'] input").attr("disabled", false);
  if (IB.currentDevice === 'tablet') $('.fastbooking .container').addClass('with-discount');
}

function hideChecks() {
  $.each($("[data-discount^='resident-']"), function (index, element) {
    $(element).addClass('hidden');
    $(element).find("input").attr("disabled", true);
  });
}

function updateTooltips() {
  $('.b-discounts-tooltip .res-discount').addClass('hidden');
  if (user_resident !== undefined) {
    $('.b-discounts-tooltip .' + user_resident).removeClass('hidden');
  }
}

function updatePricesInfo() {
  $.each($('.price-info'), function (index, element) {
    let $this = $(this);
    let base_price = parseInt($this.data('base-price'));
    let resident_price = parseInt($this.data('resident-price'));
    let resident_type = $this.data('resident-type');
    let final_price = parseInt($this.data('price'));

    if (isResident(resident_type) &&
          resident_price !== null &&
          typeof resident_price !== 'undefined' &&
          !isNaN(resident_price)) {
      final_price = resident_price;
    }
    if (final_price < base_price) {
      let $text = $($this.find('[data-now-text]')[0]);
      let text = $text.data('now-text');
      $text.text(text);
      // Cambiamos el data para la ordenación
      $this.closest('div.card').attr('data-price', final_price);

      // Texto 'desde' en ofertas
      $this.find('p.text-before-price').hide();
    } else {
      $this.find('.price-before, [data-price-before]').hide();
    }
    removePricesSkeleton($this);
  });

  if (!FastbookingDiscountMyIberostar.isDiscountActive()) {
    FastbookingDiscountMyIberostar.hideTooltips();
  }
}

/**
 * Checks if hotel has discount
 *
 * @param {obj} $hotelWrapper - Wrapper which contains price-info
 *
 * @returns {boolean} - True if has discount, false if not
 */
function hasDiscount($hotelWrapper) {
  let $this = $hotelWrapper.find('.price-info');
  let base_price = parseInt($this.data('base-price'));
  let resident_price = parseInt($this.data('resident-price'));
  let resident_type = $this.data('resident-type');
  let final_price = parseInt($this.data('price'));

  if (isResident(resident_type) &&
      resident_price !== null &&
      typeof resident_price !== 'undefined' &&
      !isNaN(resident_price)) {
    final_price = resident_price;
  }
  if (final_price < base_price) {
    return true;
  } else {
    return false;
  }
}

function removePricesSkeleton($price) {
  $price.closest('.call-to-action').find('.price-info-fake').remove();
}

function isResident(resident_type) {
  return user_resident !== undefined && resident_type !== undefined && user_resident == resident_type;
}

const FastbookingDiscountResident = {
  checkDiscount: checkDiscount,
  hasDiscount:   hasDiscount,
  updatePricesInfo: updatePricesInfo,
  init:          init
};

$(function () {
  FastbookingDiscountResident.init();
});


if (typeof IB === 'undefined') {
  window.IB = {
    fastbooking: {}
  };
} else if (IB.fastbooking === undefined) {
  window.IB.fastbooking = {};
}

window.IB.fastbooking.discount_resident = FastbookingDiscountResident;

export default FastbookingDiscountResident;
