import dayjs from 'dayjs';
import ChildrenControl from './fastbooking.childrenControl';
import FastbookingSelect from './fastbooking.select';
import _some from 'lodash/some';
import isBetween from 'dayjs/plugin/isBetween';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(isBetween);
dayjs.extend(customParseFormat);

let isMobile = IB.currentDevice === 'mobile';
let isTablet = IB.currentDevice === 'tablet';
let $fastbooking = isMobile ? $('#fastbooking-mobile') : $('.fastbooking.home');
let $destinationSelect = $fastbooking.find('.chosen-select');
let $datePickerWrapper = $('.date-picker-wrapper');
let $packageOccupancyInfoAlert = $fastbooking.parent().find('.package-occupancy-info');
let $packageOccupancyLimitWarning = $fastbooking.parent().find('.package-occupancy-limit-warning');
let $packageOccupancyModifiedWarning = $fastbooking.parent().find('.package-occupancy-modified-warning');
let $packageDestinationHotelNotIncludedWarning = $fastbooking.parent().find('.package-destination-hotel-not-included-warning');
let $packageDatesNotIncludedWarning = $datePickerWrapper.find('.package-dates-not-included-warning');
let packageMandatoryKeys = ['stay_dates', 'hotels'];
let packageData = {};
let isPackageLoaded = false;
let areRestrictionsLoaded = false;
let occupancyValid = false;
let fastbookingSelectionIsValid = false;
let hotelDefault = {};
let CONSTANTS = {
  DATE_INPUT_FORMAT:  'DD/MM/YYYY',
  DATE_OUTPUT_FORMAT: 'YYYY-MM-DD'
};

/**
 * Loads a package into fastbooking
 * @param {Object} packageDataJson - package data json
 */
function loadPackage(packageDataJson) {
  packageData = {};
  $datePickerWrapper = $('.date-picker-wrapper');
  $fastbooking.addClass('has-package-loaded');

  // Return if packageData is not given or it's empty
  if (!packageDataJson || !Object.keys(packageDataJson).length) {
    throw new Error('Unable to load an empty package in fastbooking');
  }

  // Check if package has all mandatory keys with value
  let packageKeys = Object.keys(packageDataJson);
  let packageMissingKeys = packageMandatoryKeys.reduce(function (total, value) {
    if (packageKeys.indexOf(value) === -1 || !packageDataJson[value] || (packageDataJson[value] && !packageDataJson[value].length)) {
      total.push(value);
    }
    return total;
  }, []);

  // If there are missing keys: verbose error listing them
  if (packageMissingKeys.length) {
    throw new Error('Unable to load package, some mandatory values are missing: ' + packageMissingKeys.join(', '));
  }

  // Parse all stay_dates to format "1970-01-01"
  let parsedStayDates = packageDataJson.stay_dates.map(function (dates) {
    return {
      stay_from: dayjs(dates.stay_from, CONSTANTS.DATE_INPUT_FORMAT).format(CONSTANTS.DATE_OUTPUT_FORMAT),
      stay_to:   dayjs(dates.stay_to, CONSTANTS.DATE_INPUT_FORMAT).format(CONSTANTS.DATE_OUTPUT_FORMAT)
    };
  });

  // Parse all exception dates to format "1970-01-01"
  let parsedExceptions = [];
  if (packageDataJson.stay_exceptions != null && packageDataJson.stay_exceptions.length) {
    parsedExceptions = packageDataJson.stay_exceptions.split(',').map(function (date) {
      return dayjs(date, CONSTANTS.DATE_INPUT_FORMAT).format(CONSTANTS.DATE_OUTPUT_FORMAT);
    });
  }

  // Prefix "h" to all hotel ids if needed
  let prefixedHotels = packageDataJson.hotels.map(function (hotel) {
    // ''+ stringifies a number
    hotel.id = ('' + hotel.id)[0].toLowerCase() === 'h' ? hotel.id : 'h' + hotel.id;
    return hotel;
  });

  // Build of internal data model
  packageData = {
    dates: {
      'stay_dates':      parsedStayDates,
      'stay_exceptions': parsedExceptions
    },
    occupancy: {
      // Plus "+" sign converts string into number
      'total':        packageDataJson.total_occupation ? +packageDataJson.total_occupation : undefined,
      'max_adults':   packageDataJson.max_adult ? +packageDataJson.max_adult : undefined,
      'min_adults':   packageDataJson.min_adult ? +packageDataJson.min_adult : undefined,
      'max_children': packageDataJson.max_child ? +packageDataJson.max_child : undefined,
      'min_children': packageDataJson.min_child ? +packageDataJson.min_child : undefined
    },
    hotels: prefixedHotels
  };

  isPackageLoaded = true;

  // DESTINATION SELECT
  // ------------------
  // Insert hotels in fastbooking destination select
  let hotelIds = packageData.hotels.map(function (hotel) {return hotel.id;});
  FastbookingSelect.addHotelsInOfferArea($destinationSelect, hotelIds, $destinationSelect.data('hotelsInPackageText'), 'package-offer');

  // Init childrencontrol
  ChildrenControl.initVars($fastbooking);

  // If there's only one hotel in package, it will be selected by default
  if (packageData.hotels.length === 1) {
    IB.fastbooking.setDestination(packageData.hotels[0].id);
    // Set max and min values for each adults/children selector based on destination selected
    ChildrenControl.setMaxAdultsAndChildren($destinationSelect);
    // Set children inputs and ages enabled/disabled
    ChildrenControl.setAllowChildren($fastbooking);
    // Updates age select with min and max valid values
    ChildrenControl.setMaxChildrenAge($fastbooking);
  }

  // DATERANGEPICKER
  // ------------------
  // Update offer days label
  let offerLegend = IB.i18n.packageDates || $fastbooking.data('offer-footer-text');
  $datePickerWrapper.find('.offer-legend').html(offerLegend);

  // Load package restrictions
  // In desktop resolutions fastbooking is already init, so restrictions are load before open
  if (!isMobile) loadRestrictions();

  // FASTBOOKING
  // ------------------
  // Open fastbooking
  IB.fastbooking.sticky.open({
    openDatepickerOnInit:        packageData.hotels.length === 1, // Open datepicker if there is only one hotel in package and is selected by default
    openDestinationSelectOnInit: packageData.hotels.length > 1 // Open destination select if there is more than one hotel, so no destination is selected
  });

  // Load package restrictions
  // In mobile resolutions fastbooking is not init yet, so restrictions are load after open
  if (isMobile) loadRestrictions();
}

/**
 * Loads package restrictions
 */

function loadRestrictions() {
  occupancyValid = false;

  if (!hasPackageLoaded()) {
    throw new Error('There\'s no package loaded');
  }

  areRestrictionsLoaded = true;

  // DATERANGEPICKER
  // ------------------
  // Hide "see calendar with prices" link if present
  $datePickerWrapper.find('.fb-calendar-information').addClass('hidden');

  // ROOMS SELECTOR
  // ------------------
  // Set initial max rooms (max rooms = max adults)
  if (packageData.occupancy.max_adults) {
    IB.fastbooking.setRoomsSelectorLimit(packageData.occupancy.max_adults);

    // Hide add room button if max_adults is 1
    if (isTablet) {
      $fastbooking.find('.rooms-actions .add-room').toggle(packageData.occupancy.max_adults !== 1);
    }
  }

  // OCCUPANCY SELECTOR
  // ------------------
  // Set package occupancy info alert values
  $packageOccupancyInfoAlert.find('.package-max-adults').html(packageData.occupancy.max_adults);
  $packageOccupancyInfoAlert.find('.package-max-children').html(packageData.occupancy.max_children);
  $packageOccupancyLimitWarning.find('.package-min-adults').html(packageData.occupancy.min_adults);
  $packageOccupancyLimitWarning.find('.package-min-children').html(packageData.occupancy.min_children);

  // Show package occupancy info alert
  manageInfoAlertVisibility();
  // Update occupancy if needed
  return manageOccupancy();
}

/**
 * Unloads current package
 */
function unLoadPackage() {
  $fastbooking.removeClass('has-package-loaded');

  // DESTINATION SELECT
  // Deselect any destination and remove offer area
  IB.fastbooking.setDestination('');
  FastbookingSelect.removeOfferArea();

  // DATERANGEPICKER
  // Update offer days label
  $datePickerWrapper = $('.date-picker-wrapper');
  $datePickerWrapper.find('.offer-legend').html($fastbooking.data('offer-footer-text'));

  // Unload package restrictions
  unloadRestrictions();

  // Update package state
  packageData = {};
  isPackageLoaded = false;

  // remove warning messages
  $('.has-package-destination-warning').removeClass('has-package-destination-warning');
  togglePackageOccupancyModifiedWarning(false);
  $packageOccupancyLimitWarning.addClass('hidden');
  toggleDestinationNotIncludedWarning(false);
  toggleDatesNotIncludedWarning(false);
}

/**
 * Unloads package restrictions
 */
function unloadRestrictions() {
  if (!hasPackageLoaded()) {
    throw new Error('There\'s no package loaded');
  }

  areRestrictionsLoaded = false;

  // DATERANGEPICKER
  // Restore "see calendar with prices" link visualization if present
  $datePickerWrapper.find('.fb-calendar-information').removeClass('hidden');

  // ROOMS SELECTOR
  // Restore rooms selector to default
  IB.fastbooking.setRoomsSelectorLimit('default');
  // Restore add room button visibility
  if (isTablet) {
    $fastbooking.find('.rooms-actions .add-room').show();
  }

  // OCCUPANCY SELECTOR
  // Hide package occupancy info alert
  manageInfoAlertVisibility();

  // remove warning messages
  togglePackageOccupancyModifiedWarning(false);
  $packageOccupancyLimitWarning.addClass('hidden');
}

/**
 * Check if package is loaded
 *
 * @returns {boolean} - true if there is a package loaded
 */
function hasPackageLoaded() {
  return isPackageLoaded;
}

/**
 * Check if restrictions are loaded
 */
function hasRestrictionsLoaded() {
  return areRestrictionsLoaded;
}

/**
 * Loads/unloads restrictions based on user selection
 *
 * @param {string} destination
 * @param {string} checkInDate
 * @param {string} checkOutDate
 */
function manageRestrictionsLoad(destination, checkInDate, checkOutDate) {
  if (!FastbookingPackages.hasPackageLoaded()) return;

  fastbookingSelectionIsValid = false;

  let occupancyChanged = false;
  let hotelInPackage = false;
  let rangeValidInPackage = false;

  if (destination) {
    // If package loaded check if selected dates are valid
    hotelInPackage = isHotelInPackage(destination);
  }

  if (checkInDate && checkOutDate) {
    // If package loaded check if selected dates are valid
    let startDate = dayjs(dayjs(checkInDate).format('YYYY-MM-DD')).format();
    let endDate = dayjs(dayjs(checkOutDate).format('YYYY-MM-DD')).format();
    rangeValidInPackage = FastbookingPackages.isDateRangeValid(startDate, endDate);
  }

  // full fastbooking selection is valid only if hotel and dates are in package
  if (hotelInPackage && rangeValidInPackage) {
    fastbookingSelectionIsValid = true;
  }

  // Selection is valid if selected hotel is in package or empty and selected dates are in package or empty
  if ((hotelInPackage || '' + destination.length == 0) && (rangeValidInPackage || ('' + checkInDate.length == 0 || '' + checkOutDate.length == 0))) {
    if (!FastbookingPackages.hasRestrictionsLoaded()) occupancyChanged = FastbookingPackages.loadRestrictions();

    if (occupancyChanged) {
      // Show warning telling user that occupancy has been changed
      FastbookingPackages.togglePackageOccupancyModifiedWarning(true);
      // If occupancy has changed by package restrictions, shows occupancy panel with alert
      IB.fastbooking.openOccupancyPanel();
    }
  } else if (FastbookingPackages.hasRestrictionsLoaded()) FastbookingPackages.unloadRestrictions();

  // Set to true if values are empty
  if (!destination) hotelInPackage = true;
  if (!checkInDate || !checkOutDate) rangeValidInPackage = true;

  // warnings
  FastbookingPackages.toggleDestinationNotIncludedWarning(!hotelInPackage);
  FastbookingPackages.toggleDatesNotIncludedWarning(!rangeValidInPackage);
}

/**
 * Returns package data
 *
 * @returns {object} - package data
 */
function getPackage() {
  return packageData;
}

/**
 * Returns fastbooking selection validation
 *
 * @returns {boolean} - true if fastbooking selection is valid (destination + dates)
 */
function isFastbookingSelectionValid() {
  return fastbookingSelectionIsValid;
}

/**
 *
 * @param {string} hotelId
 * @returns {boolean} - true if hotel is in package
 */
function isHotelInPackage(hotelId) {
  if (!isPackageLoaded || !hotelId) return false;

  hotelId = ('' + hotelId)[0].toLowerCase() === 'h' ? hotelId : 'h' + hotelId;
  let hotelsInPackage = packageData.hotels.map(function (hotel) {return hotel.id;});

  return hotelsInPackage.indexOf(hotelId) !== -1;
}

/**
 *
 * @param {string} hotelId
 * @returns {boolean} - true if hotel is in package
 */
function getHotelCrsUrl(hotelId) {
  if (!isPackageLoaded || !hotelId) return '';

  hotelId = ('' + hotelId)[0].toLowerCase() === 'h' ? hotelId : 'h' + hotelId;
  let hotelData = packageData.hotels.filter(function (hotel) {return hotel.id === hotelId;});

  return hotelData.length ? hotelData[0].crs_url : '';
}

/**
 * Checks if a date is in package
 *
 * @param {string} date - Date to check in format "1970-01-01"
 *
 * @returns {boolean} - true if date is valid in package
 */
function isDateValid(date) {
  if (packageData === {}) {
    throw new Error('There\'s no package loaded');
  }

  if (!date) return false;

  let checkDate = dayjs(date);
  let isValid = false;

  $.each(packageData.dates.stay_dates, function (index, dates) {
    if (checkDate.isBetween(dayjs(dates.stay_from), dayjs(dates.stay_from), 'day', '[]')) {
      // Date is valid if it's in range and it's not in exceptions array
      if (!isException(checkDate)) {
        isValid = true;
      }
      return false; // range found, so break the each loop
    }
  });

  return isValid;
}

/**
 * Checks if a date range is in package
 *
 * @param {string} dateFrom - Date when range starts
 * @param {string} dateTo - Date when range ends
 *
 * @returns {boolean} - true if date range is valid in package
 */
function isDateRangeValid(dateFrom, dateTo) {
  if (packageData === {}) {
    throw new Error('There\'s no package loaded');
  }

  if (!dateFrom || !dateTo) return false;

  let isValid = false;

  $.each(packageData.dates.stay_dates, function (index, dates) {
    // Date range is valid if range valid contains range selected and each date in range is not in exceptions array
    let dateFromContains = dayjs(dateFrom).isBetween(dates.stay_from, dates.stay_to, 'day', '[]');
    let dateToContains = dayjs(dateTo).isBetween(dates.stay_from, dates.stay_to, 'day', '[]');
    if (dateFromContains && dateToContains) {
      let rangeHasExceptions = _some(packageData.dates.stay_exceptions, function (exceptionDate) {
        return dayjs(exceptionDate).isBetween(dateFrom, dateTo, 'day', '[]');
      });

      // If range has not exceptions then it's valid
      isValid = !rangeHasExceptions;

      return false; // range found, so break the each loop
    }
  });

  return isValid;
}

/**
 * Checks if a date is an exception
 *
 * @param {string} date - Date to check in format "1970-01-01"
 *
 * @returns {boolean} - true if date is an exception
 */
function isException(date) {
  if (packageData === {}) {
    throw new Error('There\'s no package loaded');
  }

  if (!date) return false;

  let checkDate = dayjs(date);

  // It's an exception only if it's in exceptions array
  return packageData.dates.stay_exceptions.length && packageData.dates.stay_exceptions.indexOf(checkDate.format(CONSTANTS.DATE_OUTPUT_FORMAT)) !== -1;
}

/**
 * Manages occupancy based on package limits and current user selection
 *
 * @returns {boolean} - true if occupancy has been modified
 */
function manageOccupancy() {
  let roomRows = $fastbooking.find('.rooms-container .room-row');
  let currentOccupancy = [];
  let totalAdults = 0;
  let totalChildren = 0;
  let occupancyConfig = [];
  let allowChildren = ChildrenControl.allowChildren();
  let occupancyModified = false;

  // Sometimes in mobile room-rows are in other container
  if (isMobile) {
    let $roomWrapper = $('#people-mobile-panel').hasClass('opened') ? $('#people-mobile-panel') : $fastbooking;
    roomRows = $roomWrapper.find('.rooms-container .room-row');
  }

  if (!roomRows.length) return;

  $.each(roomRows, function (index, roomRow) {
    let $roomRow = $(roomRow);
    let $input_adults = $roomRow.find('.input.adults').find('input');
    let $input_children = $roomRow.find('.input.children').find('input');

    totalAdults += +$input_adults.val();
    totalChildren += +$input_children.val();

    currentOccupancy.push({
      adults:   +$input_adults.val(),
      children: +$input_children.val()
    });
  });

  if ((totalAdults >= packageData.occupancy.min_adults || !packageData.occupancy.min_adults) && (totalAdults <= packageData.occupancy.max_adults || !packageData.occupancy.max_adults)) {
    if (!allowChildren || (allowChildren && (totalChildren >= packageData.occupancy.min_children || !packageData.occupancy.min_children) && (totalChildren <= packageData.occupancy.max_children || !packageData.occupancy.max_children))) {
      // Update occupancy limits
      manageOccupancyLimits();
      // Current selection is right, so occupancy management is no needed
      return;
    }
  }

  if (roomRows.length === 1) {
    occupancyConfig[0] = {};
    if (packageData.occupancy.max_adults && currentOccupancy[0].adults > packageData.occupancy.max_adults) {
      occupancyConfig[0].adults = packageData.occupancy.max_adults;
      occupancyModified = true;
    }
    if (packageData.occupancy.max_children && currentOccupancy[0].children > packageData.occupancy.max_children) {
      occupancyConfig[0].children = packageData.occupancy.max_children;
      occupancyModified = true;
    }
  } else {
    // Deep clone currentOccupancy into occupancyConfig
    occupancyConfig = currentOccupancy.slice();

    // Delete rooms if there are more rooms than adults available
    if (packageData.occupancy.max_adults && roomRows.length > packageData.occupancy.max_adults) {
      IB.fastbooking.setRoomsSelectorValue(packageData.occupancy.max_adults);
      occupancyModified = true;
    }

    // In case salected occupancy is higher than package occupancy limit, makes a reverse iteration through all rooms, starting in the last one,
    // decreasing occupancy (min 1 adult) until package occupancy limit is reached
    for (let i = roomRows.length; i-- > 0;) {
      let $roomRow = $(roomRows[i]);
      let $input_adults = $roomRow.find('.input.adults').find('input');
      let input_adults_value = +$input_adults.val();
      let $input_children = $roomRow.find('.input.children').find('input');
      let input_children_value = +$input_children.val();

      // Redistributing adults in existing rooms
      if (packageData.occupancy.max_adults && totalAdults > packageData.occupancy.max_adults) {
        while (totalAdults > packageData.occupancy.max_adults && input_adults_value > 1) {
          input_adults_value -= 1;
          totalAdults -= 1;
        }
        occupancyModified = true;
        occupancyConfig[i].adults = input_adults_value;
      }

      if (allowChildren && packageData.occupancy.max_children && totalChildren > packageData.occupancy.max_children) {
        while (totalChildren > packageData.occupancy.max_children && input_children_value >= 0) {
          input_children_value -= 1;
          totalChildren -= 1;
        }
        occupancyModified = true;
        occupancyConfig[i].children = input_children_value;
      }

      // Break the loop if occupancy is ok
      if (totalAdults <= packageData.occupancy.max_adults || !packageData.occupancy.max_adults) {
        // Current selection is right, so occupancy management is no needed
        break;
      }
    }
  }

  // Set new occupancy
  ChildrenControl.setOccupancy(occupancyConfig);
  // Update occupancy limits
  manageOccupancyLimits();

  return occupancyModified;
}

/**
 * Manages occupancy limits based on package limits and current user selection
 */
function manageOccupancyLimits() {
  // Return if there are no limits
  if (!packageData.occupancy.max_adults && !packageData.occupancy.max_children) return;

  hotelDefault = {
    max_adults:   ChildrenControl.data.max_adults,
    max_children: ChildrenControl.data.max_children
  };

  let roomRows = $fastbooking.find('.rooms-container .room-row');
  let totalAdults = 0;
  let totalChildren = 0;
  let occupancyConfig = [];
  let allowChildren = ChildrenControl.allowChildren();

  if (isMobile) {
    let $roomWrapper = $('#people-mobile-panel').hasClass('opened') ? $('#people-mobile-panel') : $fastbooking;
    roomRows = $roomWrapper.find('.rooms-container .room-row');
  }

  if (!roomRows.length) return;

  // Restore default limits
  ChildrenControl.setMaxAdultsAndChildren($destinationSelect);

  // Get total adults/children selected
  $.each(roomRows, function (index, roomRow) {
    let $roomRow = $(roomRow);
    let $input_adults = $roomRow.find('.input.adults').find('input');
    let $input_children = $roomRow.find('.input.children').find('input');

    totalAdults += +$input_adults.val();
    totalChildren += +$input_children.val();
  });

  // Set max values in each row based on package max values
  if (roomRows.length === 1) {
    occupancyConfig.push({
      max_adults:   hotelDefault.max_adults <= packageData.occupancy.max_adults ? hotelDefault.max_adults : packageData.occupancy.max_adults,
      max_children: hotelDefault.max_children <= packageData.occupancy.max_children ? hotelDefault.max_children : packageData.occupancy.max_children
    });
  } else {
    $.each(roomRows, function (index, roomRow) {
      let $roomRow = $(roomRow);
      let $input_adults = $roomRow.find('.input.adults').find('input');
      let $input_children = $roomRow.find('.input.children').find('input');
      let roomOccupancyConfig = {};

      if (packageData.occupancy.max_adults && totalAdults >= packageData.occupancy.max_adults) {
        // Max adults limit is reached, so all "+" adults selectors will be disabled
        roomOccupancyConfig.max_adults = +$input_adults.val();
      }

      if (allowChildren) {
        if (packageData.occupancy.max_children && totalChildren >= packageData.occupancy.max_children) {
          // Max children limit is reached, so all "+" children selectors will be disabled
          roomOccupancyConfig.max_children = +$input_children.val();
        }
      }

      occupancyConfig.push(roomOccupancyConfig);
    });
  }

  // If selection is lower than min occupancy limit, occupancy limit warning is shown
  if ((packageData.occupancy.min_adults && totalAdults < packageData.occupancy.min_adults) || (packageData.occupancy.min_children && totalChildren < packageData.occupancy.min_children)) {
    occupancyValid = false;
    $packageOccupancyLimitWarning.removeClass('hidden');
  } else {
    occupancyValid = true;
    $packageOccupancyLimitWarning.addClass('hidden');
  }

  ChildrenControl.setOccupancyLimits(occupancyConfig);
}

/**
 * Check if occupancy is valid
 *
 * @returns {boolean} - true if occupancy is valid
 */
function isOccupancyValid() {
  return occupancyValid;
}

/**
 * Show/hide info alerts
 */
function manageInfoAlertVisibility() {
  // If package is loaded, package-occupancy-info will be shown
  $packageOccupancyInfoAlert.toggleClass('hidden', !areRestrictionsLoaded);
}

/**
 * Show/hide package occupancy modified warning
 *
 * @param {boolean} view - if true shows warning, if false hides warning
 */
function togglePackageOccupancyModifiedWarning(view) {
  $packageOccupancyModifiedWarning.toggleClass('hidden', !view);
}

/**
 * Show/hide destination not included warning
 *
 * @param {boolean} view - if true shows warning, if false hides warning
 */
function toggleDestinationNotIncludedWarning(view) {
  $packageDestinationHotelNotIncludedWarning = $fastbooking.find('.package-destination-hotel-not-included-warning');
  if (view) {
    // Set hotel not included message
    $packageDestinationHotelNotIncludedWarning.html(IB.i18n.selectedHotelNotIncluded);
  }
  $packageDestinationHotelNotIncludedWarning.toggleClass('hidden', !view);
}

/**
 * Show/hide destination not included warning
 *
 * @param {boolean} view - if true shows warning, if false hides warning
 */
function toggleDatesNotIncludedWarning(view) {
  $packageDatesNotIncludedWarning = $('.date-picker-wrapper').find('.package-dates-not-included-warning');
  if (view) {
    // Set hotel not included message
    $packageDatesNotIncludedWarning.html(IB.i18n.selectedDatesNotIncluded);
  }
  $packageDatesNotIncludedWarning.toggleClass('hidden', !view);
}

// - EVENTS -
// ---------------------------
$(document).on('click', '.js-input-number-inc , .js-input-number-dec, .room-row .remove-room', function () {
  if (!hasPackageLoaded()) return;

  if (hasRestrictionsLoaded()) {
    manageOccupancyLimits();
  } else {
    $packageOccupancyLimitWarning.addClass('hidden');
  }
});


const FastbookingPackages = {
  // Data management
  loadPackage:                           loadPackage,
  unLoadPackage:                         unLoadPackage,
  hasPackageLoaded:                      hasPackageLoaded,
  loadRestrictions:                      loadRestrictions,
  unloadRestrictions:                    unloadRestrictions,
  manageRestrictionsLoad:                manageRestrictionsLoad,
  hasRestrictionsLoaded:                 hasRestrictionsLoaded,
  getPackage:                            getPackage,
  isFastbookingSelectionValid:           isFastbookingSelectionValid,
  // Destination tools
  isHotelInPackage:                      isHotelInPackage,
  getHotelCrsUrl:                        getHotelCrsUrl,
  // Dates tools
  isDateValid:                           isDateValid,
  isDateRangeValid:                      isDateRangeValid,
  isException:                           isException,
  // Occupancy tools
  manageOccupancy:                       manageOccupancy,
  manageOccupancyLimits:                 manageOccupancyLimits,
  isOccupancyValid:                      isOccupancyValid,
  // Messages tools
  togglePackageOccupancyModifiedWarning: togglePackageOccupancyModifiedWarning,
  toggleDestinationNotIncludedWarning:   toggleDestinationNotIncludedWarning,
  toggleDatesNotIncludedWarning:         toggleDatesNotIncludedWarning
};


$(function () {
  $(document).on('click', '.js-book-btn[data-package]', function (event) {
  // $('.js-book-btn[data-package]').on('click', function (event) {
    event.preventDefault();

    let $this = $(this);
    let packageDataSerialized = $this.attr('data-package');
    let packageDataJson;

    if (!packageDataSerialized) return;

    packageDataJson = JSON.parse(packageDataSerialized);

    FastbookingPackages.loadPackage(packageDataJson);

    // If button is inside fancybox, closes it
    if ($this.closest('.fancybox-container').length) {
      $.fancybox.close();
    }
  });
});

if (typeof IB === 'undefined') {
  window.IB = {
    fastbooking: {}
  };
} else if (IB.fastbooking === undefined) {
  window.IB.fastbooking = {};
}

window.IB.fastbooking.packages = FastbookingPackages;

export default FastbookingPackages;
