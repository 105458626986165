import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

import { sendUtagEvent } from '../../core/analytics/utag_events'
import { getUserLevel } from '../../core/iberapi/browsing_session/browsing_session'
import { readCookie } from '../../utils/cookie_manager'
import { fromObjectToParams } from '../../utils/from_object_to_params'
import { getLazyData } from '../../utils/get_lazy_data'
import { getCrsHotelCode, getCrsManagementId } from '../../utils/hotels_data_bridge'

dayjs.extend(customParseFormat)

const HTML_TAG = document.documentElement
const DATE_CURRENT_FORMAT = HTML_TAG.dataset.dateFormat
const DATE_FORMAT_TO_USE = 'YYYY-MM-DD'

// English texts for Utag validation event
const EN_CRS_MSGS = {
  1: 'No error', //  Do NOT change. En caso de que no haya error el kind_of_error será “No error”.
  2: 'The voucher code cannot be found in the system',
  3: 'The promo has not started yet',
  4: 'The promotion is not active',
  5: 'The promo booking date has passed',
  6: 'No more vouchers can be redeemed for this promotion',
  8: 'The promotion does not include offers at this hotel',
  99: 'Unknown error',
  100: 'Unknown error',
  101: 'The occupancy entered does not comply with the occupancy requirements of the promotion',
  103: 'The promotion does not apply for the user’s level',
  104: 'The promotion cannot be used by agencies',
}

function triggerUtagAndUpdateHiddenField(code, crs_response) {
  const eventAct = crs_response.isValid ? 'success' : 'error'
  const eventLbl = `${code}-${EN_CRS_MSGS[crs_response.code]}`
  const hiddenField = document.querySelector('#ok-promo')

  // TODO PY05032 No encontrados
  sendUtagEvent({
    data: {
      event_name: '',
      event_cat: 'promo code validation',
      event_act: eventAct,
      event_lbl: eventLbl,
      force_cg: false,
      event_purpose: '',
      event_structure: '',
    },
  })

  if (hiddenField) {
    document.querySelector('#ok-promo').value = crs_response.isValid ? 1 : 0
  }
}

function validationEvent(crs_data) {
  const validationEvent = new Event('promocode:validation')
  const code = IB.fastbooking_state.getField('promocode').code

  validationEvent.crs_response = crs_data
  validationEvent.code = code
  document.dispatchEvent(validationEvent)

  triggerUtagAndUpdateHiddenField(code, crs_data)
}

function formatDateForService(date) {
  return dayjs(date, DATE_CURRENT_FORMAT).format(DATE_FORMAT_TO_USE)
}

function fetchFieldsForValidation(fb_state, user_level) {
  let fields = {
    promoCodi: fb_state.promocode.code, // codigo promocional
    centCodi: getCrsHotelCode(fb_state.place_to_go.id), // código del hotel
    sogecodi: getCrsManagementId(fb_state.place_to_go.id), // código de la gestora
    occupation: fb_state.people.total, // número total de adults y children
    horizonLevel: user_level, // nivel de usuario (numérico)
    fechaEntrada: formatDateForService(fb_state.dates.check_in), // debe tener formato YYYY-MM-DD
    fechaSalida: formatDateForService(fb_state.dates.check_out), // debe tener formato YYYY-MM-DD
  }

  if (IB.is_in_microsite) {
    // código de agencia en caso de navegar por ibepro.
    fields = { ...fields, ...{ agencodi: readCookie('agency_code') } }
  }

  return fromObjectToParams(fields)
}

export function validatePromocode(input) {
  // Subtract 1 to match with crs user level (in crs levels starts with 0, in admin starts with 1)
  const userLevel = getUserLevel() - 1
  const params = fetchFieldsForValidation(IB.fastbooking_state.getState(), userLevel)

  // Test URLs either local or pre are always valid for all CORS problem calls.
  // const url = `https://icrs.iberostar.com/WebApis/cocktailrest/promotions?${params}`
  const url = `${input.dataset.validationService}?${params}`

  const response = getLazyData(
    url,
    function (crs_data, is_response_valid, response) {
      if (response.status !== 404 || crs_data === null || crs_data === undefined) {
        crs_data.isValid = true
        crs_data.code = 1
      }
      validationEvent(crs_data)
    },
    'json'
  )

  response.catch(function () {
    const crs_data = {
      isValid: false,
      code: 99,
    }
    validationEvent(crs_data)
  })
}
