import { PROVIDER } from './Provider'

export class Airport {
  /**
   *	Constructor for Airport
   *	@function constructor
   */
  constructor(properties) {
    this.iata = properties.iata
    this.title = properties.title
  }

  /**
   *	Creates a Airport object from the provider Destinia
   *	@function fromDestinia
   */
  static fromDestinia(data) {
    var properties = {
      iata: data.iata,
      title: data.title,
    }

    return new Airport(properties)
  }

  /**
   *	Creates a Airport object from the provider HLX
   *	@function fromHLX
   */
  static fromHLX(data) {
    var properties = {
      iata: data.abrev,
      title: data.name,
    }

    return new Airport(properties)
  }

  /**
   * This function maps an array of airports based on the provided provider.
   * @param data
   * @param {string} provider
   * @returns array of mapped airports
   */
  static mapAirportsBasedOnProvider(data, provider) {
    switch (provider) {
      case PROVIDER.destinia:
        return data.map(airport => {
          return this.fromDestinia(airport)
        })
      case PROVIDER.hlx:
        return data.map(airport => {
          return this.fromHLX(airport)
        })
      default:
        throw new Error('Provider not supported')
    }
  }
}

export default Airport
