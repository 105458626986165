/**
 * From Object to Params
 *
 * Receives an object and returns a string in URL params format
 *
 * @params {object} Object
 * @return {string} paramsString
 */
export function fromObjectToParams(obj) {
  const arr = Object.entries(obj)
  let paramsString = arr.reduce((acc, curr) => (acc += `${curr[0]}=${curr[1] || curr[1] === 0 ? curr[1] : ''}&`), '')
  paramsString = paramsString.slice(0, -1) // removes trailing &

  return paramsString
}
