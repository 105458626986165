import FastbookingPackages from '../../shared/fastbooking/fastbooking.packages';

// Input number with controls and mask
//
// Two modes of use:
//  - Single input: will only take care of its own min and max values.
//  - Grouped inputs: will coordinate the max combined value within a group of inputs,
//    each one with its own min and max.
//
// @attrs
//    - data-min: minimum selectable number
//    - data-max: maximum selectable number
//    - data-combined-max: maximun total in selectable group (OPTIONAL)
//
// Example of use:
//
// Single input:
//  HTML:
//  <input class="js-input-number input-number" type="text" value="1" data-min="0" data-max="10">
//
//  JS:
//  import CustomInputNumber from '../../elements/custom_input_number/custom_input_number';
//  CustomInputNumber.init($('.js-input-number'));
//
//  Generates:
//  <div class="custom-input-number">
//    <button class="js-input-number-dec input-number-dec" aria-label="'+el_remove+ " " + el_input_label + '">-</button>
//      <input class="js-input-number input-number" type="text" value="1" data-min="0" data-max="10" data-combined-max="6">
//    <button class="js-input-number-inc input-number-inc" aria-label="'+el_add+ " " + el_input_label + '"></button>
//  </div>
//
// ===
//
// Grouped inputs:
//  HTML:
//  <input class="js-input-number input-number" type="text" value="1" data-min="0" data-max="10" data-combined-max="6" data-group-name="name">
//
//  JS:
//  import CustomInputNumber from '../../elements/custom_input_number/custom_input_number';
//  CustomInputNumber.init($('.js-input-number'));
//
//  Generates:
//  <div class="custom-input-number">
//    <button class="js-input-number-dec input-number-dec" aria-label="'+el_remove+ " " + el_input_label + '">-</button>
//      <input class="js-input-number input-number" type="text" value="1" data-min="0" data-max="10" data-combined-max="6" data-group-name="name">
//    <button class="js-input-number-inc input-number-inc" aria-label="'+el_add+ " " + el_input_label + '"></button>
//  </div>

function init(els, option) {
  option = option || false;

  // refactor
  const groupedEls = els.filter((idx, el) => el.dataset?.groupName);
  const singleEls = els.filter((idx, el) => !el.dataset?.groupName);

  els.each(function () {
    let el = $(this);

    if (option && option === 'refresh') CustomInputNumber.destroy(el); // If we want to refresh it, destoys it first
    if (el.parent('.custom-input-number').length) return; // Already initialized

    el.wrap('<div class="custom-input-number t-link"></div>');

    let elements = {};
    let wrapper = el.parent('.custom-input-number');
    let combined_max = el.attr('data-combined-max') || false;
    combined_max = combined_max && parseInt(combined_max);
    let groupName = el.attr('data-group-name') || false;
    let min = el.attr('data-min') || false;
    let max = el.attr('data-max') || false;
    let prompt = el.attr('data-prompt') || false;
    let el_add = el.closest('.inputs-wrapper').data('add');
    let el_remove = el.closest('.inputs-wrapper').data('remove');
    let el_input_label = el.closest('.input').find('label').text();

    wrapper
      .prepend('<button class="js-input-number-dec input-number-dec" aria-label="' + el_remove + ' ' + el_input_label + '"></button>')
      .append('<button class="js-input-number-inc input-number-inc" aria-label="' + el_add + ' ' + el_input_label + '"></button>');

    if (el.hasClass('is-disabled')) wrapper.addClass('is-disabled');

    elements.dec = wrapper.find('.js-input-number-dec');
    elements.inc = wrapper.find('.js-input-number-inc');

    elements.dec.on('click', decrement);
    elements.inc.on('click', increment);
    setTimeout(function () {
      setValue(el.val());
    }, 0);
    setTimeout(function () {
      disableEnableButtons(el.val(), el);
    }, 0);

    function decrement(e) {
      e.preventDefault();
      let value = el.val();
      value--;
      if (min === false || value >= min) {
        setValue(value);
      }
      disableEnableButtons(value, el);

      // Si quito niños de la lista compruebo si se ha comprobado (haciendo clic fuera del div, por ejemplo) previamente que hay niños sin edad introducida
      $('.inputs-wrapper .ages').find('.error').hide();
      $('.inputs-wrapper .ages')
        .children('.input.age')
        .each(function () {
          if ($(this).hasClass('parsley-error')) {
            $('.inputs-wrapper .ages').find('.error').show();
          }
        });
    }

    function increment(e) {
      e.preventDefault();
      let value = el.val();
      min = +el.attr('data-min');
      if (value !== prompt && min > value) value = min - 1;
      if (prompt && value === prompt) value = min ? min - 1 : -1;
      value++;
      if (max === false || value <= max) {
        setValue(value);
      }
      disableEnableButtons(value, el);
    }

    function disableEnableButtons(value, el) {
      let outOfBounds = sumOutOfBounds(el);
      if (outOfBounds) {
        disableGroup(el);
      } else {
        +value <= +min || +value === +prompt ? elements.dec.addClass('is-disabled') : elements.dec.removeClass('is-disabled');
        +value >= +max ? elements.inc.addClass('is-disabled') : elements.inc.removeClass('is-disabled');
        const lang = document.documentElement.getAttribute('data-js-lang');
        if (lang === 'de') changeCopy(lang, value, el);
        enableGroup(el);
      }
    }

    function changeCopy(lang, value) {
      let $input = el.closest('.input');

      if ($input.hasClass('adults')) value > 1 ? $input.find('label.t-fb-label').text($input.data('adults')) : $input.find('label.t-fb-label').text($input.data('adult'));
      if ($input.hasClass('children')) value > 1 ? $input.find('label.t-fb-label').text($input.data('children')) : $input.find('label.t-fb-label').text($input.data('child'));
    }

    function setValue(value) {
      if (el.val() !== value) {
        el.val(value);
        el.trigger('change');
      }
    }

    function sumOutOfBounds(el) {
      return combined_max && getSumOfInputValues(el) >= combined_max;
    }

    function getSumOfInputValues(el) {
      let group = el.attr('data-group-name');
      let grouped_els = document.querySelectorAll(`[data-group-name="${group}"]`);
      grouped_els = [...grouped_els];
      const sum = grouped_els?.reduce((acc, el) => acc + parseInt(el.value), 0);
      return sum;
    }

    function disableGroup(el) {
      let group = el.attr('data-group-name');
      let grouped_els = document.querySelectorAll(`[data-group-name="${group}"]`);
      let outOfBounds = sumOutOfBounds(el);
      grouped_els = [...grouped_els];
      grouped_els.forEach(el => {
        const inputNumberInc = el.parentElement?.querySelector('.js-input-number-inc');
        const inputNumberDec = el.parentElement?.querySelector('.js-input-number-dec');
        if (el.value >= el.dataset.max || outOfBounds) inputNumberInc?.classList.add('is-disabled');
        if (outOfBounds) inputNumberDec?.classList.remove('is-disabled');
        if (el.value <= el.dataset.min) inputNumberDec?.classList.add('is-disabled');
      });
    }

    function enableGroup(el) {
      let group = el.attr('data-group-name');
      let grouped_els = document.querySelectorAll(`[data-group-name="${group}"]`);
      grouped_els = [...grouped_els];
      if (+max === 0) return;
      grouped_els.forEach(el => {
        const inputNumberInc = el.parentElement?.querySelector('.js-input-number-inc');
        const inputNumberDec = el.parentElement?.querySelector('.js-input-number-dec');
        if (el.value < el.dataset.max) inputNumberInc?.classList.remove('is-disabled');
        if (el.value > el.dataset.min) inputNumberDec?.classList.remove('is-disabled');
      });
    }

    // Prevent focus on input text
    el.on('focus', function (e) {
      $(this).blur();
    });
  });
}

function refresh(els) {
  init(els, 'refresh');
}

function destroy(els) {
  els.each(function () {
    let el = $(this);

    if (!FastbookingPackages.hasRestrictionsLoaded() && Number(el.val()) > Number(el.attr('data-max')) && !el.closest('.ages').length) el.val(el.attr('data-max'));

    if (!el.parent('.custom-input-number').length) return;
    el.parent('.custom-input-number').find('.js-input-number-dec, .js-input-number-inc').remove();
    el.unwrap();
  });

  if (IB.currentDevice !== 'mobile') {
    let $people_popover = $('#people-popover');
    let total = 0;
    $people_popover.find('.input.adults').each(function (index, el) {
      total = Number($(this).find('.js-input-number').val()) + total;
    });

    $people_popover.find('.input.children').each(function (index, el) {
      total = Number($(this).find('.js-input-number').val()) + total;
    });

    $('.result-number-of-guest').text(total);
  }
}

const CustomInputNumber = {
  init: init,
  refresh: refresh,
  destroy: destroy,
};

export default CustomInputNumber;
