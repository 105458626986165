/* Function that get parameters from url */
function getParameterByName(name) {
  const url = window.location.href
  name = name.replace(/[\[\]]/g, '\\$&')
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`)
  const results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

function get_promo_code() {
  const promo_code = getParameterByName('promo_code')
  const elementPromoCode = document.querySelectorAll('.promo-code')
  if (promo_code) {
    _activatePromocodeStyles()
    elementPromoCode?.forEach(elem => {
      elem.value = promo_code
      elem.dispatchEvent(new KeyboardEvent('keyup', { key: 'Enter' }))
    })
  }
}

function _activatePromocodeStyles() {
  // If there is a promocode received by parameter, we must show the full FB display on both mobile and desktop.
  let fastbooking = null
  if (IB.currentDevice === 'mobile') {
    fastbooking = document.querySelector('#fastbooking-mobile')
    const linkPromo = fastbooking?.querySelector('.link-promo')
    linkPromo.dispatchEvent(new Event('click'))

    const promo = fastbooking?.querySelector('.promo')
    promo.classList.add('promo-active')

    const fastbookingBar = document.querySelector('.mobile-fastbooking-bar')
    const fastbookingBarContainer = fastbookingBar?.querySelector('.mobile-fastbooking-bar--container')
    fastbookingBarContainer?.classList.add('with-code')

    const fastbookingCodeActivated = fastbookingBar?.querySelector('.code-activated')
    fastbookingCodeActivated?.classList.remove('hidden')
  } else {
    fastbooking = document.querySelector('form.fastbooking')
    fastbooking?.classList.add('has-promocode')
    const codeActivated = fastbooking?.querySelector('.code-activated')
    codeActivated?.classList.remove('hidden')
  }
}

document.addEventListener('DOMContentLoaded', () => {
  get_promo_code()
})

export function setPromocodeInFastbooking(promocode) {
  const FASTBOOKING = IB.currentDevice === 'mobile' ? document.querySelector('#fastbooking-mobile') : document.querySelector('.fastbooking')
  FASTBOOKING.querySelector('input.promo-code').value = promocode
  const EVENT = new Event('keyup')
  FASTBOOKING.dispatchEvent(EVENT)
  activatePromocodeStyles()
}

export function activatePromocodeStyles() {
  _activatePromocodeStyles()
}
