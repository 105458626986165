import { PROVIDER } from './Provider'

export class Destination {
  /**
   *	Constructor for Destination
   *	@function constructor
   */
  constructor(properties) {
    this.id = properties.id
    this.title = properties.title
    this.utag_title = properties.utag_title
  }

  /**
   *	Creates a Destination object from the provider Destinia
   *	@function fromDestinia
   */
  static fromDestinia(data) {
    var properties = {
      id: data.id,
      title: data.title,
      utag_title: data.utag_title ? data.utag_title : data.title,
    }

    return new Destination(properties)
  }

  /**
   *	Creates a Destination object from the provider HLX
   *	@function fromHLX
   */
  static fromHLX(data) {
    var properties = {
      id: data.id,
      title: data.name,
      utag_title: data.utag_title ? data.utag_title : data.title,
    }

    return new Destination(properties)
  }

  /**
   * This function maps an array of destinations based on the provided provider.
   * @param data
   * @param {string} provider
   * @returns array of mapped destinations
   */
  static mapDestinationsBasedOnProvider(data, provider) {
    switch (provider) {
      case PROVIDER.destinia:
        return data.map(destination => {
          return this.fromDestinia(destination)
        })
      case PROVIDER.hlx:
        return data.map(destination => {
          return this.fromHLX(destination)
        })
      default:
        throw new Error('Provider not supported')
    }
  }
}

export default Destination
