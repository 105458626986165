import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isBetween from 'dayjs/plugin/isBetween'
import _filter from 'lodash/filter'
import _includes from 'lodash/includes'

import { currentDevice } from '../../core/utils/currentDevice'

import { initializeFastbookingDatesUtagEvent } from './fastbooking.analytics'
import ChildrenControl from './fastbooking.childrenControl'
import FastbookingPackages from './fastbooking.packages'
import FastbookingPromocode from './fastbooking.promocode'
import FastbookingState from './fastbooking.state'

dayjs.extend(isBetween)
dayjs.extend(customParseFormat)

dayjs.extend(isBetween)
dayjs.extend(customParseFormat)
/* eslint-disable */
// Documentation: https://longbill.github.io/jquery-date-range-picker/
let activeDatepickers = 0
let language
let date_format
let start_of_week
let start_date
let offer_days = []
let fastbookingSelector
let $fastbooking
let $place_to_go
let $check_in
let $check_out
let defaultOfferDatesAlreadySet
const offer_format = 'YYYY-MM-DD'
let place_to_go_val = ''
let fastbooking_data
let $filter_data
let preventDatepickerOpen = false
let $close_calendar_button
const data = {
  day_from: null,
  day_to: null,
  current_offer: null,
}
const AUTO_OFFER_LIST_PRESENT = document.querySelector('.automatic-offer-list')


function init($el) {
  fastbookingSelector = currentDevice.isMobile ? '.mobile-fastbooking-cnt' : '.fastbooking.home'
  $fastbooking = $(fastbookingSelector)
  $close_calendar_button = $fastbooking.find('.js-exit-without-dates')
  $place_to_go = $fastbooking.find('.chosen-select')
  language = document.documentElement.getAttribute('data-js-lang')
  date_format = $('html').attr('data-date-format')
  start_of_week = $('html').attr('data-start-of-week')
  $filter_data = $('.hotels-to-filter-data').first()
  offer_days = $filter_data.length ? offer_days.concat(JSON.parse($filter_data.html()).offer_days) : []
  start_date = new dayjs().format(date_format)
  $check_in = $el.find('.check-in')
  $check_out = $el.find('.check-out')
  defaultOfferDatesAlreadySet = false

  // Set dates & nights if they are already set in HTML.
  // This is a quick fix in IHG's project. We should really check why this is necessary
  if ($check_in.val() != '' && $check_out.val() != '') {
    updateDateLabels($fastbooking)
    updateNumNights($fastbooking)
    $el.find('.remove-dates').removeClass('hidden')
  }

  $(document).on('click', '.fake-datepicker', function (e) {
    e.preventDefault()
    const $this = $(this)
    const $parent = $this.closest('.dates')
    $(this).remove()
    activate($el, $parent, true)
  })

  $(document).on('focus', '.js-dates', function (e) {
    e.preventDefault()
    const $this = $(this)

    // Ignore this focus event if it was internally triggered
    if ($this.data('internal-focus-trigger')) {
      $this.removeData('internal-focus-trigger')
      return
    }

    if ($this.find('.fake-datepicker').length > 0) {
      $this.find('.fake-datepicker').trigger('click')
    }
  })

  $(document).on('click', '.btn-floating-fastbooking', function (e) {
    if (!$(this).hasClass('offer-code')) {
      // e.preventDefault();
      if ($('.modal-gastronomy').length) {
        $.fancybox.close()
      }
    }
  })

  $(document).on('click', '.remove-dates', function (event) {
    event.preventDefault()
    event.stopPropagation()
    removeDates($el)
    if ($(this).closest(fastbookingSelector).length) {
      const $datepicker = $fastbooking.find('.js-dates')
      if ($datepicker.length) {
        if (!$datepicker.data().dateRangePicker) {
          activate($fastbooking, $datepicker, true)
        }
        $datepicker.data().dateRangePicker.open()
      }
    }
  })

  $(document).on('keydown', '.date-picker-wrapper.fb-datepicker', function (event) {
    const $focused = $(':focus')
    if (event.keyCode === 13) {
      event.preventDefault()
      $focused.trigger('click')
    } else if (event.keyCode === 37) {
      event.preventDefault()
      $('.prev').trigger('click')
      $('.fb-datepicker .month-wrapper .caption .prev').attr('tabindex', '-1').trigger('focus')
    } else if (event.keyCode === 39) {
      event.preventDefault()
      $('.next').trigger('click')
      $('.fb-datepicker .month-wrapper .caption .next').attr('tabindex', '-1').trigger('focus')
    }
  })

}

function activate($el, $parent, open) {
  const $body = $('body')
  let $check_in = $el.find('.check-in')
  let $check_out = $el.find('.check-out')
  const is_inline = typeof $el.data('inline') !== 'undefined'
  const auto_close = IB.currentDevice !== 'mobile'
  const offerLegend = FastbookingPackages.hasPackageLoaded() ? IB.i18n.packageDates : $el.data('offer-footer-text')
  updateNumNights($el)

  $el
    .find('.dates')
    .each(function () {
      const $dates = $(this)
      const datepickerClass = ['fb-datepicker','not-theme', `js-datepicker-${activeDatepickers}`]
      activeDatepickers += 1

      $dates.attr('data-datepicker-wrapper-selector', `.${datepickerClass.join('.')}`)
      $(this).dateRangePicker({
        autoClose: auto_close,
        // This function allows us to prevent datepicker close if function returns true
        preventCloseFn(start, end) {
          // PY05032 It is necessary so that it is not duplicated on desktop
          initializeFastbookingDatesUtagEvent()

          if (FastbookingPackages.hasPackageLoaded()) {
            const startDate = dayjs(dayjs(start).format('YYYY-MM-DD')).format()
            const endDate = dayjs(dayjs(end).format('YYYY-MM-DD')).format()
            // If dates valid, returns false to allow datepicker autoclose
            return !FastbookingPackages.isDateRangeValid(startDate, endDate)
          }

          // By default returs false to allow datepicker autoclose when selecting end date
          return false
        },
        beforeShowDay(t) {
          // If package is loaded, get package dates
          setDefaultOfferDates()

          const style = t.getDay() === 0 ? 'weekend' : ''

          // [0]: true/false indicating whether or not this date is selectable
          // [1]: a CSS class name to add to the date's cell or "" for the default presentation
          // [2]: an optional popup tooltip for this date
          // [3]: true/false indicating if all days in this datepicker are selectable (in other words, if first param is a constant
          //      and its not a result of a validation, then this value should be true)
          return [true, style, '', true]
        },
        showDateFilter(time, date) {
          let has_offer = false
          let found = false
          for (let index = 0; index < offer_days.length; index++) {
            const offer = offer_days[index]
            if (offer.highlighted_in_calendar) {
              const stay_from_date = dayjs.max(dayjs(offer.stay_from), dayjs()).format(offer_format)
              const stay_to_date = dayjs(offer.stay_to).format(offer_format)
              has_offer = dayjs(dayjs(time).format(offer_format)).isBetween(stay_from_date, stay_to_date, 'day', '[]')

              found = has_offer

              // If package is loaded check for exceptions
              if (has_offer && FastbookingPackages.hasPackageLoaded()) {
                // If is an exception date has_offer becomes false
                const packageException = FastbookingPackages.isException(dayjs(time).format(offer_format))
                const hotelException = !FastbookingPackages.isHotelInPackage(FastbookingState.getState().place_to_go.id)

                has_offer = !packageException && !hotelException
              }

              if (found) break
            }
          }
          const className = has_offer ? 'has-offer' : ''
          return `<div class="number ${className}">${date}</div>`
        },
        format: date_format,
        getValue() {
          if ($check_in.val() && $check_out.val()) {
            return `${$check_in.val()} ${$check_out.val()}`
          }
          return ''
        },
        hoveringTooltip(days) {
          if (IB.currentDevice !== 'mobile') {
            const nights = days - 1
            const labels = $dates.data('i18n-nights')
            return nights + (labels ? ` ${nights > 1 || nights == 0 ? labels.other : labels.one}` : '')
          }
        },
        extraClass: datepickerClass.join(' '),
        tooltipPosition: 'bottom',
        inline_mobile_panel: is_inline,
        inline: is_inline,
        language,
        minDays: 2,
        separator: ' ',
        showTopbar: false,
        setValue(s, s1, s2) {
          $check_in.val(s1)
          $check_out.val(s2)
          updateNumNights($el, dayjs(s2, date_format).diff(dayjs(s1, date_format), 'days'))
        },
        startDate: start_date,
        startOfWeek: start_of_week,
        stickyMonths: true,
        offerLegend: true,
        offerLegendText: offerLegend || $el.data('offer-footer-text'),
      })
      if (is_inline) {
        $check_in = $check_in.add($('.inline-wrapper').find('.start-day'))
        $check_out = $check_out.add($('.inline-wrapper').find('.end-day'))
      }
    })
    .on('datepicker-open', function (event) {
      checkAvailabilityCalendarPush($el)
      if (IB.currentDevice === 'mobile') {
        ScrollLock.on()
      } else {
        addCloseCalendarButtonToDatepickerFooter()
      }

      if (IB.currentDevice === 'mobile' && !$('.mobile-fastbooking-bg').length) {
        $body.addClass('mobile-fastbooking-opened')
        $body.append('<div class="mobile-fastbooking-bg"></div>')
      }

      if (offer_days.length) {
        const from = offer_days[0].stay_from
        $('.offer-dates-legend').addClass('hidden')
        $('.date-picker-wrapper .dates-info').removeClass('hidden')

        if (dayjs().diff(from) <= 0) {
          const $picker = $el.find('.dates').data('dateRangePicker')
          $picker.showMonth(from, 'month1', $picker.opt)
          $picker.showMonth($picker.nextMonth(from), 'month2', $picker.opt)
          // One more month added to the mobile datepicker
          if (IB.currentDevice === 'mobile') {
            $picker.showMonth($picker.nextMonth($picker.nextMonth(from)), 'month3', $picker.opt)
          }
        }
      } else {
        $('.offer-dates-legend').removeClass('hidden')
      }

      const $container = $fastbooking.find('.container')
      const body_witdth = $('body').width()
      const left = $container.offset().left
      const diff = body_witdth - left

      if (diff < 623) {
        $('.date-picker-wrapper').css({ right: 0, left: 'auto' })
      } else {
        $('.date-picker-wrapper').css({ left: $container.offset().left })
      }

      //$('.month-wrapper').css({ width: '301px' });

      // If package loaded check if selected dates are valid
      if (FastbookingPackages.hasPackageLoaded()) {
        const startDate = $check_in.val().length ? dayjs(dayjs($check_in.val(), date_format).format('YYYY-MM-DD')).format() : ''
        const endDate = $check_out.val().length ? dayjs(dayjs($check_out.val(), date_format).format('YYYY-MM-DD')).format() : ''

        // Manage restrictions and show warnings
        FastbookingPackages.manageRestrictionsLoad($place_to_go.val(), startDate, endDate)
      }
    })
    .on('datepicker-opened', function () {
      const first_day = $el.find('.dates').data('first-day')

      if (IB.currentDevice === 'mobile') $('.date-picker-wrapper .dates-info').removeClass('hidden')

      $(`.day[data-id=${first_day}]`).addClass('first-date-selected')
      if (!$check_in.val().length) {
        $('.real-today').trigger('focus')
      } else {
        $('.first-date-selected').trigger('focus')
      }
      updateSelectedDatesLegend()

      IB.fastbooking.ifElementOnScreen($el)

      // Disabling pull to refresh option mobile
      if (IB.currentDevice === 'mobile') {
        $('body').css({
          overscrollBehaviorY: 'contain',
        })
        $('.fastbooking-mobile-apply-dates').addClass('active')
      }

      // Set to false to be reinitialized in next datepicker opening
      defaultOfferDatesAlreadySet = false

      if (AUTO_OFFER_LIST_PRESENT) {
        const picker = $fastbooking.find('.js-dates').data('dateRangePicker')
        const offer_hotel = document.querySelector(`.special-offer-data-info-link[data-hotel_id="${$place_to_go.val().substring(1)}"]`)

        if (offer_hotel) {
          const stayFrom = offer_hotel.dataset.stay_from
          picker.showMonth(stayFrom, 'month1', {})
          picker.showMonth(picker.nextMonth(stayFrom), 'month2', {})
        }
      }
    })
    .on('datepicker-first-date-selected', function (event, obj) {
      // Set checkin input value
      $el.find('.date.check-out').removeAttr('disabled')
      $check_in.removeClass('active').val(dayjs(obj.date1).format(date_format))
      if (IB.currentDevice === 'mobile') {
        $check_in.closest('.date').find('.date-span').text($check_in.val())
        $('.mobile-fastbooking-cnt').find('.nights-label').removeClass('active').text('')
      }
      $check_out.addClass('active').val('')

      const selected_day = dayjs(obj.date1).format('DDMMYYYY')
      $el.find('.dates').data('first-day', selected_day)

      $el.find('.opt-dates').addClass('hidden')
      $el.find('.remove-dates').removeClass('hidden')
      $el.find('.check-in-container').removeClass('focused')
      $el.find('.check-out-container').addClass('focused')
      updateSelectedDatesLegend()
    })
    .on('datepicker-close', function () {
      if (IB.currentDevice === 'mobile') {
        ScrollLock.off()
        $('.fastbooking-mobile-apply-dates').removeClass('active')
        if ($body.hasClass('home-page')) $('.mobile-fastbooking-bg').remove(), $body.removeClass('mobile-fastbooking-opened')
      }

      if (AUTO_OFFER_LIST_PRESENT) {
        const rangeValidInPackage = false
        const offer_hotel = document.querySelector(`.special-offer-data-info-link[data-hotel_id="${$place_to_go.val().substring(1)}"]`)
        const picker = $('div.dates.utag-dates-event').data('dateRangePicker')

        if (offer_hotel) {
          const offer_start = dayjs(offer_hotel.dataset.stay_from)
          const offer_end = dayjs(offer_hotel.dataset.stay_to)
          const user_start = dayjs(document.querySelector('.dates .check-in').value.split('/').reverse().toString().replaceAll(',', '/'))
          const user_end = dayjs(document.querySelector('.dates .check-out').value.split('/').reverse().toString().replaceAll(',', '/'))

          if (user_start != '' && user_end != '') {
            if (user_start >= offer_start && user_start < offer_end && user_end <= offer_end && user_end > offer_start) {
              FastbookingPackages.toggleDatesNotIncludedWarning(rangeValidInPackage)
              IB.fastbooking.setauto_offer_search_ready(true)
            } else {
              FastbookingPackages.toggleDatesNotIncludedWarning(!rangeValidInPackage)
              IB.fastbooking.setauto_offer_search_ready(false)
            }
          }
        }
      }
    })
    .on('datepicker-closed', function () {
      $('.date-picker-wrapper').css({
        right: 'auto',
      })
      $('.date').find('input').removeClass('active')
      // Active pull to refresh option mobile
      if (IB.currentDevice === 'mobile') {
        $body.css({
          overscrollBehaviorY: 'auto',
        })
      }

      const lang_is_ru = document.documentElement.getAttribute('data-js-lang') === 'ru'
      const is_tablet = IB.currentDevice === 'tablet'
      if ($check_in.val() && $check_out.val()) {
        if (!(lang_is_ru && is_tablet)) {
          IB.fastbooking.hideShowButton('book-now')
          $fastbooking.find('.input.place-to-go').addClass('book-now-show')
        }
        $el.find('.check-out-container, .check-in-container').removeClass('focused')
        if (!$el.find('.focused').length > 0) {
          $el.find('.dates').addClass('focused')
        }
        if (IB.currentDevice === 'mobile')
          $check_in.closest('.date').find('.date-span').text($check_in.val()), $check_out.closest('.date').find('.date-span').text($check_out.val())
      }
      IB.fastbooking.showDatesAlert()
      const selected_day = $el.find('.day.first-date-selected').data('id')
      $el.find('.dates').data('first-day', selected_day)
      $el.find('.day').removeClass('hovering-permanent')

      const $input = $fastbooking.find('.input.age')
      if ($input.length && $place_to_go.val().length) {
        ChildrenControl.checkOnlyAdults($place_to_go.find('option:selected'))
      }

      IB.fastbooking_state.setState()

    })
    .on('datepicker-change', function (event, obj) {
      const isMobile = IB.currentDevice === 'mobile'
      if ($el.hasClass('fastbooking') || $el.hasClass('mobile-fastbooking-cnt')) {
        // Updating number of nights in mobile fastbooking
        updateSelectedDatesLegend()
        updateNumNights($el, dayjs(obj.date2).diff(dayjs(obj.date1), 'days'))
        if (isMobile) {
          const numNights = dayjs($check_out.val(), date_format).diff(dayjs($check_in.val(), date_format), 'days')
          $('.mobile-fastbooking-cnt').find('.nights-label').addClass('active').text(numNights)
        }
      }


      // If package loaded check if selected dates are valid
      if (FastbookingPackages.hasPackageLoaded()) {
        const startDate = obj.date1 ? dayjs(dayjs(obj.date1).format('YYYY-MM-DD')).format() : ''
        const endDate = obj.date2 ? dayjs(dayjs(obj.date2).format('YYYY-MM-DD')).format() : ''

        FastbookingPackages.manageRestrictionsLoad($place_to_go.val(), startDate, endDate)
      }

      // trigger selected-dates event
      $fastbooking.trigger('fastbooking:dates-selected')

      // Validaes promocode if needed
      FastbookingPromocode.validationControl()

      $(this).data('internal-focus-trigger', true)
      $check_out.trigger('focus')
      if (!currentDevice.isDesktop){
        initializeFastbookingDatesUtagEvent()
      }

    })

  $el.find('.dates').on('focus', function (e) {
    $el.find('.date').trigger('click')
  })

  $el.find('.date').on('click focus', function (e) {
    IB.fastbooking.hideDatesAlert()
    let first_day
    let last_day
    const $date = $(this)
    const is_checkin = !!($date.find('.check-in').length || $date.hasClass('.check-in'))
    const is_checkout = !!($date.find('.check-out').length || $date.hasClass('.check-out'))
    const in_val = $check_in.val()
    const out_val = $check_out.val()
    $el.find('.date').removeClass('active').find('input').removeClass('active')
    $date.addClass('active').find('input').addClass('active')

    if (is_checkin) {
      $el.find('.dates').data('is-date', 'check_in')
    } else if (is_checkout) {
      $el.find('.dates').data('is-date', 'check_out')
    }

    if (is_checkin && !out_val && in_val) {
      $el.find('.date').find('input').removeClass('active')
      $date.find('input').addClass('active')
      $date.closest('.dates').data('dateRangePicker').updateFirstDate()
      $('.date-picker-wrapper').css({
        left: $date.closest('.dates').find('.check-in').offset().left - 5,
      })
    } else if (is_checkout && !in_val) {
      $el.find('.date').removeClass('active').find('input').removeClass('active')
      $('.date-picker-wrapper').css({
        left: $date.closest('.dates').find('.check-out').offset().left - 5,
      })
    } else if (is_checkout && in_val) {
      first_day = $el.find('.dates').data('first-day')
      last_day = $date.find('.check-out').val()
      $el.find('.check-out').val(last_day)
      $('.date-picker-wrapper').css({
        left: $date.closest('.dates').find('.check-out').offset().left - 5,
      })
    } else if (is_checkin && out_val) {
      last_day = $('.day.last-date-selected').attr('time')
      $date.closest('.dates').data('dateRangePicker').setLastDate(last_day)
      $el.find('.day').removeClass('hovering-permanent')
      $el.find('.date-picker-wrapper').css({
        left: $date.closest('.dates').find('.check-in').offset().left - 5,
      })
    }

    updateSelectedDatesLegend()
  })

  $(document).on('day-hovering', function (event, obj) {
    const timestamp = parseInt($(obj).attr('time'))
    const dateFormatted = dayjs(timestamp).format(date_format)
    updateSelectedDatesLegend('', dateFormatted)
  })

  if (open) {
    //Metemos este timeout para solucionar un bug que existe actualmente que nos cierra en algunas ocasiones
    //el datepicker (viene de la función checkAutoOpen donde hay otro settimeout)
    setTimeout(function () {
      const datepickerIsVisible = $('.date-picker-wrapper.fb-datepicker').is(':visible')
      if (!datepickerIsVisible) {
        $parent.find('.check-in').closest('.date').trigger('click')
      }
    }, 10)
  }
}

function addCloseCalendarButtonToDatepickerFooter() {
  const $dates = $fastbooking.find('.dates')
  const datepickerSelector = $dates.attr('data-datepicker-wrapper-selector')
  const $footer_datepicker = $(`${datepickerSelector} .footer`)
  const exitWithoutDatesBtn = document.querySelectorAll('.js-exit-without-dates')

  exitWithoutDatesBtn.forEach(exitButton => {
    if (exitWithoutDatesBtn.length <= 1) {
      $footer_datepicker.append($close_calendar_button)
    }
    exitButton.addEventListener('click', function () {
      removeDates()
      IB.fastbooking.closeDatepicker()
      $dates.data('internal-focus-trigger', true)
      $check_out.trigger('focus')
    })
  })
}

function setDefaultOfferDates($el) {
  defaultOfferDatesAlreadySet = place_to_go_val == $place_to_go.val()
  if (defaultOfferDatesAlreadySet) return

  place_to_go_val = $place_to_go.val()

  // Setting offer days
  if (FastbookingPackages.hasPackageLoaded()) {
    // - from package if package is loaded
    offer_days = []
    const packageData = FastbookingPackages.getPackage()

    // Set package offer days
    $.each(packageData.dates.stay_dates, function (index, dates) {
      offer_days.push({
        stay_from: dates.stay_from,
        stay_to: dates.stay_to,
        highlighted_in_calendar: true,
      })
    })
    // }
  } else {
    // - If package not loaded, takes offers from view

    let all_offer_days = []
    if ($filter_data.length) {
      // Hotel Pages, Offer Pages => get offer data from hotels-to-filter-data
      const parsedFilterData = JSON.parse($filter_data.html())
      if (_includes(parsedFilterData.hotel_ids, place_to_go_val)) {
        all_offer_days = parsedFilterData.offer_days
      }
    } else {
      // Get offer data from fastbooking
      const fastbooking_data = IB.hotels_data.getData()
      const data_el = _filter(fastbooking_data.hotels[1], function (p) {
        return place_to_go_val === p.id
      })
      all_offer_days = data_el.length > 0 ? data_el[0].data.offer_days : []
    }

    if (FastbookingDateRangePicker.current_offer != null) {
      offer_days = _filter(all_offer_days, function (o) {
        return o.id === FastbookingDateRangePicker.current_offer
      })
    } else if (AUTO_OFFER_LIST_PRESENT) {
      const offer_hotel = document.querySelector(`.special-offer-data-info-link[data-hotel_id="${$place_to_go.val().substring(1)}"]`)

      if(offer_hotel) {
        const offer = JSON.parse(
          `{"stay_from": "${dayjs(offer_hotel.dataset.stay_from).format('YYYY/MM/DD')}", "stay_to": "${dayjs(offer_hotel.dataset.stay_to).format(
            'YYYY/MM/DD'
          )}", "highlighted_in_calendar": true}`
        )
        offer_days = [offer]
      }
    } else {
      offer_days = all_offer_days
    }
  }
}

function removeDates($el = $fastbooking) {
  if ($el.find('.dates').data('dateRangePicker')) {
    $el.find('.dates').data('dateRangePicker').clear()
    if (IB.currentDevice === 'mobile') {
      const $check_in_span = $check_in.closest('.date').find('.date-span')
      const $check_out_span = $check_out.closest('.date').find('.date-span')
      $check_in_span.text($check_in_span.data('placeholder'))
      $check_out_span.text($check_out_span.data('placeholder'))
      $('.mobile-fastbooking-cnt').find('.nights-label').removeClass('active').text('')
    }
  }

  $el.find('.dates').removeData('first-day', 'last-day')

  $check_in.val('')
  $check_out.val('')

  $el.find('.remove-dates').addClass('hidden')
  $el.find('.opt-dates').removeClass('hidden')

  if ($('#book-now .next-opening').length) {
    if ($('#book-now .next-opening')[0].classList.contains('hidden')) {
      IB.fastbooking.hideShowButton('search')
    } else {
      IB.fastbooking.hideShowButton('next-opening')
    }
  }
  $fastbooking.find('.input.place-to-go').removeClass('book-now-show')

  updateSelectedDatesLegend()
  updateNumNights($el)

  // If package loaded manage restrictions and show warnings
  if (FastbookingPackages.hasPackageLoaded()) {
    const startDate = $check_in.val().length ? dayjs(dayjs($check_in.val(), date_format).format('YYYY-MM-DD')).format() : ''
    const endDate = $check_out.val().length ? dayjs(dayjs($check_out.val(), date_format).format('YYYY-MM-DD')).format() : ''

    FastbookingPackages.manageRestrictionsLoad($place_to_go.val(), startDate, endDate)
  }

  IB.fastbooking.showDatesAlert()
  // trigger selected-dates event
  $fastbooking.trigger('fastbooking:dates-removed')
  $('.input:not(.submit), .dates').removeClass('focused').find('.check-in-container, .check-out-container').removeClass('focused')
  IB.fastbooking_state.setState()

  FastbookingPromocode.validationControl()
}

function updateSelectedDatesLegend(dateIn, dateOut) {

  dateIn = dateIn ? dateIn : $check_in.val()
  dateOut = dateOut || dateOut === '' ? dateOut : $check_out.val()
  const $dates_info = $('.date-picker-wrapper .dates-info')
  const $dates_selected = $('.date-picker-wrapper .dates-selected')
  const $checkInLegend = $dates_info.find('.check-in-legend')
  const $checkOutLegend = $dates_info.find('.check-out-legend')

  if ($dates_info.length) {
    if (!dateIn && !dateOut && !offer_days.length) {
      $dates_info.addClass('hidden')

      // Reset label legends
      $checkInLegend.text($checkInLegend.data('legend'))
      $checkOutLegend.text($checkOutLegend.data('legend'))
    } else if (!dateIn && !dateOut) {
      if (IB.currentDevice !== 'mobile') {
        $dates_selected.addClass('hidden')
      }
    } else {
      if (offer_days.length) {
        $dates_info.removeClass('hidden')
        $dates_selected.removeClass('hidden')
      }
      if (dateIn) {
        $checkInLegend.text(dayjs(dateIn, date_format).format(date_format))
      }

      if (dateOut && dateIn !== dateOut) {
        dateOut = dayjs(dateOut, date_format).format(date_format)
        $checkOutLegend.text(dateOut)
      } else {
        $checkOutLegend.text($checkOutLegend.data('legend'))
      }

      $check_out.val()
        ? $checkOutLegend.addClass('is-active').siblings('.separator').addClass('is-active')
        : $checkOutLegend.removeClass('is-active').siblings('.separator').removeClass('is-active')
    }
  }
}

// Updates number of nights in mobile fastbooking
function updateDateLabels($el, numNights) {
  if (IB.currentDevice !== 'mobile') return

  const $check_in = $el.find('.check-in')
  const $check_out = $el.find('.check-out')
  const $check_in_span = $check_in.closest('.date').find('.date-span')
  const $check_out_span = $check_out.closest('.date').find('.date-span')
  $check_in_span.text($check_in.val())
  $check_out_span.text($check_out.val())
}

// Updates number of nights in mobile fastbooking
function updateNumNights($el, numNights) {
  if (IB.currentDevice !== 'mobile') return

  const $check_in = $el.find('.check-in')
  const $check_out = $el.find('.check-out')
  const $nightsLabel = $(`${$el.find('.nights-label').selector}, .date-picker-wrapper .nights-label`)
  const labels = $el.find('.dates').data('i18n-nights')

  if (!numNights && $check_in.val() && $check_out.val()) {
    numNights = dayjs($check_out.val(), date_format).diff(dayjs($check_in.val(), date_format), 'days')
  }

  if (numNights) {
    $nightsLabel.removeClass('hidden').addClass('active')
    $nightsLabel.each(function (index, element) {
      const $element = $(element)
      if ($element.hasClass('in-date-wrapper')) {
        $element.text(numNights + (labels ? ` ${numNights > 1 || numNights == 0 ? labels.other : labels.one}` : ''))
      } else {
        $element.text(numNights)
      }
    })
  } else {
    $nightsLabel.addClass('hidden')
  }
}

function checkAutoOpen($fastbooking, $selected) {
  if (preventDatepickerOpen) return
  if (
    IB.currentDevice !== 'mobile' &&
    !$fastbooking.find('.check-in').val() &&
    !$fastbooking.find('.check-out').val() &&
    !$selected.hasClass('last-search-opt') &&
    !$selected.data('nextOpening') &&
    $selected.val() !== '' &&
    $selected.val() !== null &&
    $selected.val() !== undefined &&
    $fastbooking.is(':visible')
  ) {
    $('.fake-datepicker').remove()
    setTimeout(function () {
      activate($fastbooking, $fastbooking.find('.dates'), true)
    }, 40)
  }
}

function checkAvailabilityCalendarPush($fastbooking) {
  const $check_in = $fastbooking.find('.check-in')
  const $check_out = $fastbooking.find('.check-out')
  let hodel_code
  let hotel_id
  const fastbooking_data = IB.hotels_data.getData()
  const is_mobile = IB.currentDevice === 'mobile'
  const $place_to_go_selected = is_mobile ? $('.label-mobile-place-to-go').find('.chosen-select option:selected') : $('.place-to-go option:selected')
  const val = $place_to_go_selected.val()
  const datepickerSelector = $fastbooking.find('.dates').attr('data-datepicker-wrapper-selector')

  is_mobile
    ? $('.mobile-fastbooking-cnt.mobile-panel.ready').find('.fb-calendar-information').remove()
    : $('.date-picker-wrapper .footer').find('.fb-calendar-information').remove()
  if (val !== undefined && val !== null) {
    if (val.charAt(0) !== 'h' || fastbooking_data === null) return false

    const array = fastbooking_data.hotels[1].filter(function (el) {
      return el.id === val
    })
    if (array.length === 0) return false

    hodel_code = array[0].data.hotel_code
    hotel_id = val.slice(1)

    const number_rooms = is_mobile ? $('#mobile-rooms-selector').val() : $fastbooking.find('.rooms .rooms-selector').val()
    let number_people = $fastbooking.find('.result-number-of-guest').text()
    const $base_template = $('.fastbooking .fb-calendar-information').clone()

    if (IB.currentDevice === 'mobile') {
      let total = 0
      $fastbooking.find('.input.adults').each(function (index, el) {
        total = +$(this).find('input').val() + total
      })

      $fastbooking.find('.input.children').each(function (index, el) {
        total = +$(this).find('input').val() + total
      })

      number_people = total
    }

    is_mobile
      ? $base_template.prependTo('.mobile-fastbooking-cnt.mobile-panel.ready .dates-info').removeClass('hidden')
      : $base_template.appendTo(`${datepickerSelector} .footer`).removeClass('hidden')
  }
}

/**
 * Set prevetDAtepicerOpen to prevent opening if needed
 *
 * @param {boolean} value - If true, datepicker won't be opened until set to false
 */
function setPreventDatepickerOpen(value) {
  preventDatepickerOpen = value
}

const FastbookingDateRangePicker = {
  init,
  activate,
  data,
  updateNumNights,
  checkAutoOpen,
  setPreventDatepickerOpen,
  removeDates,
}

if (typeof IB === 'undefined') {
  window.IB = {
    fastbooking: {},
  }
} else if (IB.fastbooking === undefined) {
  window.IB.fastbooking = {}
}

window.IB.fastbooking.rangedatepicker = FastbookingDateRangePicker

export default FastbookingDateRangePicker
