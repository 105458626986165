import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localizedFormat from 'dayjs/plugin/localizedFormat'

import { setAsyncDayjsLocale } from '../../core/utils/locale'

import Fastbooking from './fastbooking'
/*eslint-disable*/
setAsyncDayjsLocale()

dayjs.extend(localizedFormat)
dayjs.extend(customParseFormat)

let fbIsPanel
let $fbMobile
let $fbMobileBottom
let headerHeight
let fbMobileHeight
let $fbBar
let $body
let preselectedDestination
let windowHeight
let $ctaBtn

/**
 * Set cta text
 *
 * @param {string} textType - text type (viewPrices / viewHotels / search)
 */
function setCtaText(textType) {
  const ctaTexts = IB.i18n.ctaFb
  const ctaText = ctaTexts[textType]

  if (!ctaText || !preselectedDestination) return

  $ctaBtn.text(ctaText)
}

if (IB.currentDevice === 'mobile') {
  const SELECTORS = {
    FB_BAR: '.js-mobile-fastbooking-bar',
    FB_MOBILE: '.js-fastbooking-mobile',
    FB_MOBILE_BOTTOM: '.js-fastbooking-mobile--bottom',
    MOBILE_HEADER: '.mobile-header',
    CTA_BTN: '.js-cta-btn',
  }

  $fbMobile = $(SELECTORS.FB_MOBILE)
  $fbMobileBottom = $(SELECTORS.FB_MOBILE_BOTTOM)
  $fbBar = $(SELECTORS.FB_BAR)
  $ctaBtn = $fbBar.find(SELECTORS.CTA_BTN)
  const $document = $(document)
  $body = $('body')
  const pageType = $body.attr('data-page-type')
  preselectedDestination = $fbBar.attr('data-preselected-destination')
  fbIsPanel = $fbMobile.hasClass('mobile-panel')
  headerHeight = $(SELECTORS.MOBILE_HEADER).outerHeight()
  fbMobileHeight = $fbMobile.outerHeight()
  let scrollTimer = 0
  windowHeight = $(window).height()
  let last_scroll_top = 0
  const delta = 5
  const isMobileDevice = /Android.+Mobile|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

  if (pageType === 'bookings') {
    // Show summary bar if booking page
    // TODO: PY05650-289 --> This line is commented because this ticket PY05650-322. Review fastbooking.mockup file.
    // FastbookingMockup.mock()

    $fbBar.addClass('summary-bar js-summary-bar')
  } else if (pageType === 'hotel') {
    // Hide "where would you like to go" label in hotel pages
    $fbBar.find('.js-summary-destination').addClass('label-hidden')
  }

  // Bar visualization on scroll
  calcShowHideFbBar()

  $(window).on('resize', function () {
    windowHeight = window.innerHeight
    calcShowHideFbBar()
  })

  $(window).on('scroll', function () {
    let scroll = $(window).scrollTop()
    const bodyCssTop = $body.css('top').replace('px', '')

    if (scrollTimer) {
      clearTimeout(scrollTimer)
    }
    // Update scroll value in case is modal open and scrollTop is 0
    if (scroll === 0 && !isNaN(bodyCssTop)) {
      scroll = Math.abs(parseInt(bodyCssTop))
    }

    // Make sure they scroll more than delta
    if (Math.abs(last_scroll_top - scroll) <= delta) return

    // Using a buffer so callback it's not called too often.
    scrollTimer = setTimeout(function () {
      const isVisible = calcShowHideFbBar()
      if (headerHeight && isVisible) {
        const $bar = $fbBar //recovered this menu on destionation (page mobile version)from PY04001-348
        const $menu = $fbBar
        if (scroll < last_scroll_top || scroll === 0) {
          $bar.css('transform', `translateY(${headerHeight}px)`)
        } else {
          // Scroll down
          $bar.css('transform', 'translateY(0)')
        }
      }

      last_scroll_top = scroll
    }, 25)
  })

  // Events
  $document.on('click', '.js-summary-item', function (e) {
    $ctaBtn.trigger('click')
  })

  $document.on('click', '.js-open-fb-btn', function (e) {
    e.preventDefault()
    window.scrollTo(0, document.querySelector('#fastbooking-mobile').offsetTop - 65)
  })

  // Cta button text changes based on user interaction with sticky fb
  $fbMobile
    .on('sticky-fastbooking:open', function () {
      if (pageType === 'destination') {
        setCtaText('viewHotels')
        Fastbooking.setCtaText('search')
      }
    })
    .on('fastbooking:dates-selected', function () {
      if (pageType === 'destination') {
        FastbookingMobileBar.setCtaText('viewPrices')
      }
    })
    .on('fastbooking:dates-removed', function () {
      if (pageType === 'destination') {
        setCtaText('viewHotels')
      }
    })

  // Full height when chosen select has focus
  $document
    .on('focus', `${SELECTORS.FB_MOBILE} .chosen-search input`, function (e) {
      if (isMobileDevice) {
        $fbMobile.addClass('full-height')
      }
    })
    .on('blur', `${SELECTORS.FB_MOBILE} .chosen-search input`, function (e) {
      if (isMobileDevice) {
        $fbMobile.removeClass('full-height')
      }
    })
}

function calcShowHideFbBar() {
  const scrollTop = $(window).scrollTop()
  const offsetTop = $fbMobile.offset() !== undefined ? $fbMobile.offset().top : 0
  const offsetBottom = fbIsPanel ? headerHeight : offsetTop + fbMobileHeight

  if ($('body').attr('data-page-type') !== 'hotel') {
    // If fastbooking has been left behind
    if (scrollTop > offsetBottom) {
      showFbBar()
      return true
    }
    hideFbBar()
    return false
  }
  // Check if the user scrolled past the header CTA that opens the FB.
  const headerWrapper = '.hotel-header .hotel-abouts'
  let $header = $(headerWrapper)

  if (!$header.length) {
    $header = $('.js-hotel-reb-header')
  }

  if ($header.length > 0) {
    if (scrollTop > $header.offset().top + $header.outerHeight()) {
      showFbBar()
      return true
    }
    hideFbBar()
    return false
  }
}

function showFbBar() {
  $fbBar.css('transform', 'translateY(0)')
  $fbBar.addClass('sticky')
  $body.removeClass('hide-fastbooking-mobile')
}

function hideFbBar() {
  $fbBar.css('transform', '')
  $fbBar.removeClass('sticky')
  $body.addClass('hide-fastbooking-mobile')
}

const FastbookingMobileBar = {
  setCtaText,
}

if (typeof IB === 'undefined') {
  window.IB = {}
}

window.IB.mobileFastbookingBar = FastbookingMobileBar

export default FastbookingMobileBar
