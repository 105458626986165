/* eslint-disable no-jquery/no-other-utils */
/* eslint-disable no-jquery/no-jquery-constructor */

import { validatePromocode } from '../../services/crs/promocode_validator'
import Modal from '../modal/modal'

import { UPDATE_FASTBOOKING_STATE_EVENT } from './fastbooking.state'

let is_mobile
let fastbooking
let promoBox
let spinner
let promoInput
let promoInputContainer
const timeUntilValidation = 2000 // time for user to finish typing the code
let typeTiming // setTimeout variable
const validClass = 'promo-active'
let i18nBox
let tooltip
let msg
let validCode = false
let promocodeText
let placeholder
let closeTooltipBtn

function hideCrsCode() {
  tooltip?.classList.add('hidden')
  promoInputContainer?.classList.remove('error')
}

function showCrsMessage(code) {
  msg.innerHTML = ''
  code === 1 ? tooltip?.classList.add('hidden') : tooltip?.classList.remove('hidden')
  msg.innerHTML = i18nBox.querySelector(`.code-${code}`).innerHTML
  code !== 1 && promoInputContainer?.classList.add('error')
}

function getPromoBox(is_mobile, fastbooking) {
  let promoBox
  if (fastbooking) {
    promoBox = is_mobile
      ? fastbooking.querySelector('.input.promo')
      : fastbooking.querySelector('.js-discounts-popover')
  }

  return promoBox
}

function shouldValidateCode(input) {
  return input && input.value && IB.fastbooking_state.isDestinationHotel() && IB.fastbooking_state.getField('dates')
}

/*
 * Functions' definition
 */
function validationControl(input = promoInput) {
  // restart timing until validation call
  // and stops function if input is empty
  if (typeTiming !== undefined) {
    clearInterval(typeTiming)
  }
  if (!input.value.length) {
    spinner.classList.add('hidden')
    hideCrsCode()

    const promoCode = document.querySelector('.js-promo-code')
    const promoActive = document.querySelector('.promo-active')
    promoActive?.classList.remove('promo-active')
    promoCode.value = ''

    const promocodeText = document.querySelector('.js-promocode-text')

    if (promocodeText) {
      promocodeText.innerHTML = ''
      promocodeText.classList.add('hidden')
    }

    const promoError = document.querySelector('.input.promo.error')
    const promoErrorMsg = document.querySelector('.promo-error')

    promoError && promoError.classList.remove('error')
    promoErrorMsg && promoErrorMsg.classList.add('hidden')

    IB.fastbooking.updateDiscountCounter()
    IB.fastbooking_state.setState()
    return
  }

  if (shouldValidateCode(input)) {
    spinner.classList.remove('hidden')
  } else if (input?.value) {
    const crsMessage = !IB.fastbooking_state.isDestinationHotel() ? 106 : 105
    showCrsMessage(crsMessage)
    tooltip?.classList.remove('hidden')
    const dates = $('div.dates')
    if (crsMessage === 105) IB.fastbooking.rangedatepicker.activate(dates, dates, false)
  }

  promoBox.querySelector(`.${validClass}`)?.classList.remove(validClass)
  if (validCode) {
    IB.fastbooking.updateDiscountCounter()
    validCode = false
  }
  // starts validation process
  // if no interaction with user within timeUntilValidation seconds
  typeTiming = setTimeout(function () {
    // saves promocode in state
    // this will trigger fastbooking:state:update
    IB.fastbooking_state.setState()
  }, timeUntilValidation)
}

function tryValidate(input = promoInput) {
  !$.cookie('remove_browsing') && shouldValidateCode(input)
    ? validatePromocode(input)
    : Boolean(spinner) && spinner.classList.add('hidden')
}

function loadPromocodeFromStateIfInputEmpty() {
  // there is already a promocode rendered from the backend (like in offer pages with promocodes)
  // so updates the fastbooking state and ends the function
  if (promoInput.value.length) {
    IB.fastbooking_state.setState() // will trigger fastbooking:state:udpate
    return
  }

  let codeFromState = IB.fastbooking_state.getField('promocode')
  codeFromState = codeFromState && codeFromState.code
  if (codeFromState) {
    promoInput.value = codeFromState
    if (codeFromState.isValid) {
      promoBox.classList.add(validClass)
      IB.fastbooking.updateDiscountCounter()
      validCode = true
    }
  }
}

function init() {
  is_mobile = IB.currentDevice === 'mobile'
  fastbooking = is_mobile ? document.querySelector('.mobile-fastbooking-form') : document.querySelector('.fastbooking')
  promoBox = getPromoBox(is_mobile, fastbooking)

  // finish if fastbooking does not have a promo field
  if (promoBox === null) return

  spinner = promoBox.querySelector('.b-loader')
  promoInputContainer = is_mobile ? promoBox : promoBox.querySelector('.promo')
  promoInput = document.getElementsByName('vo_booking[promo_code]')[0]
  i18nBox = promoBox.querySelector('.code-msgs')
  tooltip = is_mobile ? promoBox.querySelector('.validation-tooltip') : promoBox.querySelector('.promo-error')

  // Mobile
  closeTooltipBtn = promoBox.querySelector('.js-close-validation-tooltip')
  if (closeTooltipBtn) {
    closeTooltipBtn.addEventListener('click', function (event) {
      event.preventDefault()
      hideCrsCode()
    })
  }

  msg = promoBox.querySelector('.js-crs-response-text')
  promocodeText = fastbooking.querySelector('.js-promocode-text')
  placeholder = fastbooking.querySelector('.js-no-discount')

  // If a promocode is saved in fastbooking state,
  // load it into the fastbooking if the input is empty
  // (might not be, as in some cases the code is server rendered)
  if (!IB.fastbooking_state.isSharedSearch()) loadPromocodeFromStateIfInputEmpty()

  // Initialize promo information modals
  Modal.init({
    openTrigger: 'data-info-promocode-micromodal-trigger',
    onShow: () => {
      ScrollLock.on()
    },
    onClose: () => {
      ScrollLock.off()
    },
    awaitCloseAnimation: true,
  })

  // Initialize promo disclaimer modal

  Modal.init({
    openTrigger: 'data-info-text-modal-trigger',
    onShow: () => {
      ScrollLock.on()
    },
    onClose: () => {
      ScrollLock.off()
    },
    awaitCloseAnimation: true,
  })
  /*
   * Listeners
   */
  promoInput.addEventListener('keyup', validationControl.bind(this, promoInput))
  document.addEventListener(UPDATE_FASTBOOKING_STATE_EVENT, tryValidate.bind(this, promoInput))
  document.addEventListener('promocode:validation', event => {
    spinner.classList.add('hidden')

    const crs = event.crs_response
    if (crs.isValid) {
      promoInputContainer.classList.add(validClass)
      validCode = true
      if (promocodeText) {
        promocodeText.innerHTML = event.code
        promocodeText.classList.remove('hidden')
        placeholder.classList.add('hidden')
      }
      const promoError = document.querySelector('.input.promo.error')
      promoError && promoError.classList.remove('error')
      IB.fastbooking.updateDiscountCounter()
    } else {
      promoInputContainer.classList.remove(validClass)
      if (validCode) {
        IB.fastbooking.updateDiscountCounter()
        validCode = false
      }
    }
    showCrsMessage(crs.code)

    IB.fastbooking_state.overridePromocodeInState(event.code, crs.isValid, crs.code)
  })
}

const FastbookingPromocode = {
  init,
  tryValidate,
  validationControl,
}

export default FastbookingPromocode
