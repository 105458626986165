import Modal from '../../shared/modal/modal'

import { FASTBOOKING_FLIGHT_TAB_CREATED_EVENT } from './constants'

const MODAL_SELECTOR = 'warning-flight-and-hotel-modal'

export function showWarningModal(accept) {
  const modalElement = document.getElementById(MODAL_SELECTOR)
  Modal.show(MODAL_SELECTOR, {
    onShow: () => {
      ScrollLock.on()

      const acceptButton = modalElement.querySelector('.js-accept-button')
      acceptButton.addEventListener('click', () => {
        Modal.close()
        accept()
      })
      const rejectButton = modalElement.querySelector('.js-reject-button')

      rejectButton.addEventListener('click', () => {
        Modal.close()
      })
    },
    onClose: () => {
      ScrollLock.off()
    },
    awaitCloseAnimation: true,
  })
}

export function initWarningModal() {
  document.addEventListener(FASTBOOKING_FLIGHT_TAB_CREATED_EVENT, () => {
    const flightAndHotelsTabs = document.querySelectorAll('.js-show-warning-modal')

    flightAndHotelsTabs.forEach(tab => {
      tab.addEventListener('click', e => {
        e.preventDefault()
        showWarningModal(() => {
          window.location.href = tab.getAttribute('href')
        })
      })
    })
  })
}
